import PiFolderNotchIcon from "@icon/PiFolderNotchIcon.tsx";

const EmptyFilter = () => {
    return (
        <div className="pt-14 flex flex-col items-center justify-center">
            <div className="flex items-center justify-center h-44 w-44 rounded-full bg-[#EFF0F6] mb-8">
                <PiFolderNotchIcon className="text-[#D3D9DD] h-32 w-32" />
            </div>

            <h4 className="text-2xl font-light mb-3 text-gray-600">
                Aucun résultat trouvé
            </h4>
            <p className="text-sm text-gray-500">
                Essayer d{"'"}ajuster votre recherche ou votre filtre pour
                trouver ce que vous cherchez.
            </p>
        </div>
    );
};

export default EmptyFilter;
