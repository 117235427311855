import FaCheckCircle from "@icon/FaCheckCircleIcon.tsx";
import GsFormCloseIcon from "@icon/GsFormCloseIcon.tsx";
import HeSearchIcon from "@icon/HeSearchIcon.tsx";
import Input from "@ui/Input.tsx";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { AppLayout } from "../../components/layouts";
import { ENDPOINTS } from "../../constants";
import { updateAxiosBaseUrl } from "../../helpers";
import useFetchPaymentMethod from "../../hooks/useFetchPaymentMethod.ts";
import useManageWalletChannels from "../../hooks/useManageWalletChannels.ts";
import { cn } from "../../libs/style.ts";
import useToast from "../../libs/useToast.tsx";
import { ApiService } from "../../types";

import ConfirmEnableOrDisableModal from "./components/ConfirmEnableOrDisableModal.tsx";

interface ActionType {
    isActive: boolean;
    onClick: () => void;
}

const ActionTemplate = (props: ActionType) => {
    const { isActive, onClick } = props;

    return (
        <div className="flex items-center space-x-5">
            <div className="space-y-5 flex flex-col">
                <button
                    className={cn({
                        "text-left underline": true,
                        "text-blue-600": !isActive
                    })}
                    onClick={onClick}
                    disabled={isActive}
                >
                    Activer
                </button>

                <button
                    className={cn({
                        "text-left underline": true,
                        "text-blue-600": isActive
                    })}
                    onClick={onClick}
                    disabled={!isActive}
                >
                    Désactiver
                </button>
            </div>

            {isActive ? (
                <FaCheckCircle size="lg" color="green" />
            ) : (
                <GsFormCloseIcon size="xl" color="red" />
            )}
        </div>
    );
};

interface ActiveOrDisableOptionType {
    channelId: number;
    status: "enable" | "disable";
    pay_channel_type: "in" | "out";
}

const Wallets = () => {
    const { t } = useTranslation();
    const [search, setSearch] = useState("");
    const [currentActionOptions, setCurrentActionOptions] =
        useState<ActiveOrDisableOptionType | null>(null);
    const [enableOrDisableLoading, setEnableOrDisableLoading] = useState(false);

    const { walletByCountry, setWalletByCountry } = useManageWalletChannels();
    const { customSuccess: customSuccessToast } = useToast();
    const updatePaymentMethod = useFetchPaymentMethod();

    const filterWalletByCountry = useMemo(() => {
        return walletByCountry.filter(item =>
            item.name.toLowerCase().includes(search.toLowerCase())
        );
    }, [search, walletByCountry]);

    const handleActivateOrDisable = useCallback(
        (options: ActiveOrDisableOptionType) => {
            const endpoint = ENDPOINTS.ENABLE_DISABLE_CHANNELS.replace(
                ":channelId",
                options.channelId.toString()
            ).replace(":status", options.status);

            updateAxiosBaseUrl(ApiService.moc);
            setEnableOrDisableLoading(true);
            window.axios
                .put(endpoint, { pay_channel_type: options.pay_channel_type })
                .then(response => {
                    updatePaymentMethod(true);
                    const data = response.data.data;
                    setWalletByCountry(
                        walletByCountry.map(item => {
                            const checkPayIn =
                                item?.payIn?.id === data.id &&
                                options.pay_channel_type === "in";
                            const checkPayOut =
                                item?.payOut?.id === data.id &&
                                options.pay_channel_type === "out";

                            if (checkPayIn || checkPayOut) {
                                if (checkPayIn) {
                                    return {
                                        ...item,
                                        payIn: { ...item.payIn, ...data }
                                    };
                                }

                                if (checkPayOut) {
                                    return {
                                        ...item,
                                        payOut: { ...item.payOut, ...data }
                                    };
                                }
                            }
                            return item;
                        })
                    );
                })
                .finally(() => {
                    setEnableOrDisableLoading(false);
                    customSuccessToast({ message: t("Succès de l'opération") });
                    setCurrentActionOptions(null);
                });
        },
        [
            customSuccessToast,
            setWalletByCountry,
            t,
            updatePaymentMethod,
            walletByCountry
        ]
    );

    const descriptionMessage = useMemo(() => {
        const status =
            currentActionOptions?.status === "disable"
                ? "désactiver"
                : "activer";

        const findItem = filterWalletByCountry.find(item => {
            if (currentActionOptions?.pay_channel_type === "in")
                return item.payIn?.id === currentActionOptions.channelId;

            if (currentActionOptions?.pay_channel_type === "out")
                return item.payOut?.id === currentActionOptions.channelId;

            return false;
        });

        const paymentMethod = findItem?.name || "";

        const payChannel =
            currentActionOptions?.pay_channel_type === "in"
                ? "émission"
                : "réception";

        return `Vous voulez ${status} ${paymentMethod} en ${payChannel}`;
    }, [
        currentActionOptions?.channelId,
        currentActionOptions?.pay_channel_type,
        currentActionOptions?.status,
        filterWalletByCountry
    ]);

    return (
        <AppLayout>
            <div className="bg-white border rounded-md px-4 py-5">
                <h4 className="font-medium text-lg text-gray-700 flex items-center space-x-8">
                    Activer/Désactiver un moyen de paiement
                </h4>

                <div className="relative">
                    <Input
                        className="w-1/3"
                        placeholder="Rechercher"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        icon={<HeSearchIcon />}
                    />

                    <table className="w-full z-0 table-auto border-collapse text-sm">
                        <thead className="text-gray-500">
                            <tr>
                                <th className="px-4 py-5 font-medium text-left">
                                    MOYEN DE PAIEMENT
                                </th>

                                <th className="px-4 py-5 font-medium text-left">
                                    EMISSION
                                </th>

                                <th className="px-4 py-5 font-medium text-left">
                                    RÉCEPTION
                                </th>
                            </tr>
                        </thead>

                        <tbody className="text-gray-500">
                            {filterWalletByCountry.map((item, index) => (
                                <tr
                                    key={index}
                                    className={cn({
                                        "bg-gray-100 rounded-lg":
                                            index % 2 === 0
                                    })}
                                >
                                    <td className="px-4 py-1.5 font-medium">
                                        {item.name}
                                    </td>

                                    <td className="px-4 py-1.5">
                                        {item.payIn && (
                                            <ActionTemplate
                                                isActive={
                                                    !!item.payIn.available_at
                                                }
                                                onClick={() =>
                                                    setCurrentActionOptions({
                                                        status: item.payIn
                                                            ?.available_at
                                                            ? "disable"
                                                            : "enable",
                                                        pay_channel_type: "in",
                                                        channelId: item.payIn
                                                            ?.id as number
                                                    })
                                                }
                                            />
                                        )}
                                    </td>

                                    <td className="px-4 py-1.5">
                                        {item.payOut && (
                                            <ActionTemplate
                                                isActive={
                                                    !!item.payOut.available_at
                                                }
                                                onClick={() =>
                                                    setCurrentActionOptions({
                                                        status: item.payOut
                                                            ?.available_at
                                                            ? "disable"
                                                            : "enable",
                                                        pay_channel_type: "out",
                                                        channelId: item.payOut
                                                            ?.id as number
                                                    })
                                                }
                                            />
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <ConfirmEnableOrDisableModal
                description={descriptionMessage}
                loading={enableOrDisableLoading}
                onConfirm={() => {
                    currentActionOptions &&
                        handleActivateOrDisable(currentActionOptions);
                }}
                isOpen={!!currentActionOptions}
                closeModal={() => setCurrentActionOptions(null)}
            />
        </AppLayout>
    );
};

export default Wallets;
