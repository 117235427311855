import {
    Dropdown,
    DropdownButton,
    DropdownItem,
    DropdownItems
} from "@ui/Dropdown.tsx";
import { Fragment, useState } from "react";

import { COUNTRY } from "../constants";
import { cn } from "../libs/style.ts";

import MatchCountryIcon from "./MatchCountryIcon.tsx";
import SecondSearchInput from "./SecondSearchInput.tsx";

interface Props {
    value: string;
    onChange?: (value: string) => void;
    disabled?: boolean;
}

const InputIndicativeDropdown = (props: Props) => {
    const [searchInput, setSearchInput] = useState("");

    const filterBySearchInput = () => {
        if (!searchInput.length) {
            return COUNTRY;
        }
        return COUNTRY.filter(item => {
            const name = item.name.toLowerCase();
            const search = searchInput.toLowerCase();
            return (
                item.indicative.includes(searchInput) || name.includes(search)
            );
        });
    };

    return (
        <>
            <Dropdown as={Fragment}>
                <DropdownButton
                    as="button"
                    type="button"
                    className={cn({
                        "text-sm text-gray-400 absolute": true,
                        "top-0 left-0 px-2": true,
                        "h-[calc(100%-10px)] mt-[9px] ml-[1px]": true,
                        "flex items-center space-x-1.5": true,
                        "rounded-l-[5px]": true,
                        "bg-gray-100": !props.disabled
                    })}
                    disabled={props.disabled}
                    data-testid="dropdown-button"
                >
                    {!props.disabled && (
                        <MatchCountryIcon
                            indicative={props.value || ""}
                            className="h-5 w-5"
                        />
                    )}

                    <span>{props.value}</span>
                </DropdownButton>

                <DropdownItems className="absolute z-[9] lg:text-base text-sm w-full bg-white border border-gray-200 shadow-sm rounded-md mt-2 p-3 focus:outline-none">
                    <SecondSearchInput
                        value={searchInput}
                        onChange={e => setSearchInput(e.target.value)}
                    />

                    <ul
                        role="options"
                        className="max-h-[14rem] overflow-y-auto"
                    >
                        {filterBySearchInput().map((item, index) => (
                            <DropdownItem key={index}>
                                <li
                                    data-testid="dropdown-item"
                                    className={cn(
                                        "block px-2 py-2 rounded text-gray-600",
                                        "hover:bg-gray-200",
                                        "cursor-pointer select-none truncate",
                                        "transition duration-200",
                                        "flex items-center space-x-2 lg:space-x-4"
                                    )}
                                    onClick={() => {
                                        props.onChange &&
                                            props.onChange(item.indicative);
                                    }}
                                >
                                    <MatchCountryIcon
                                        indicative={item.indicative}
                                        className="h-5 w-5"
                                    />
                                    <span>
                                        {item.name} ({item.indicative})
                                    </span>
                                </li>
                            </DropdownItem>
                        ))}
                    </ul>
                </DropdownItems>
            </Dropdown>
        </>
    );
};

export default InputIndicativeDropdown;
