import Checkbox from "@ui/Checkbox.tsx";
import { useCallback } from "react";

import useCountries from "../../../hooks/useCountries";

export interface PaymentMethodType {
    /* eslint-disable */
    [key: string]: any;
    /* eslint-enable */
    selected: boolean;
}

interface Props {
    title: string;
    list: PaymentMethodType[];
    onCheckElement: (value: boolean, element: PaymentMethodType) => void;
}

const PaymentMethods = (props: Props) => {
    const { getCountryByIndicative } = useCountries();

    const getCountryName = useCallback(
        (indicative: string) => {
            const country = getCountryByIndicative(indicative);

            return country ? country.name : "---";
        },
        [getCountryByIndicative]
    );

    return (
        <div className="w-1/2 space-y-2">
            <h6 className="text-sm text-blue-600">{props.title}</h6>

            <div className="space-y-2">
                {props.list.map((item, index) => (
                    <Checkbox
                        checked={item.selected}
                        onChange={e =>
                            props.onCheckElement(e.target.checked, item)
                        }
                        key={index}
                        data-testid={`payment-method-${index}`}
                    >
                        {item.name}{" "}
                        {item?.country_id && (
                            <>({getCountryName(item?.countryIndicative)})</>
                        )}
                    </Checkbox>
                ))}
            </div>
        </div>
    );
};

export default PaymentMethods;
