import { Transition } from "@headlessui/react";
import BsCheckCircleIcon from "@icon/BsCheckCircleIcon.tsx";
import BsExclamatoryIcon from "@icon/BsExclamatoryIcon.tsx";
import BsInfoCircleIcon from "@icon/BsInfoCircleIcon.tsx";
import FaXMarkCircleIcon from "@icon/FaXMarkCircleIcon.tsx";
import HeXMarkIcon from "@icon/HeXMarkIcon.tsx";
import { useMemo } from "react";

import { cn } from "../../libs/style.ts";
import { AlertType } from "../../types";

interface Props {
    type?: AlertType;
    message: string;
    description?: string;
    show: boolean;
    onClose: () => void;
}

const ToastAlert = (props: Props) => {
    const { type = "success", message, description, show, onClose } = props;

    const colorConfig = useMemo(() => {
        const successConfig = {
            bg: "bg-[#F0F9F8]",
            border: "border-[#CBEBE7]",
            text: "text-[#2DAA98]"
        };

        switch (type) {
            case "success":
                return successConfig;
            case "error":
                return {
                    bg: "bg-[#FFF6F6]",
                    border: "border-[#FFE6E5]",
                    text: "text-[#FF4B4C]"
                };
            case "info":
                return {
                    bg: "bg-[#EFF5FD]",
                    border: "border-[#C8DFFA]",
                    text: "text-[#3286EA]"
                };
            case "warning":
                return {
                    bg: "bg-[#FFFBF2]",
                    border: "border-[#F9ECD1]",
                    text: "text-[#F5B102]"
                };
            default:
                return successConfig;
        }
    }, [type]);

    return (
        <Transition
            as="div"
            className="relative transform max-w-[670px] pointer-events-auto"
            show={show}
            enter="transition-all duration-150"
            enterFrom="opacity-0 scale-50 -translate-y-10"
            enterTo="opacity-100 scale-100 translate-y-0"
            leave="transiation-all duration-150"
            leaveFrom="opacity-100 scale-100 translate-y-0"
            leaveTo="opacity-0 scale-75 -translate-y-10"
        >
            <button
                onClick={onClose}
                className="absolute right-3 top-3.5 focus:outline-none"
            >
                <HeXMarkIcon className="text-[#535F71]" />
            </button>

            <div
                className={cn(
                    "flex items-start rounded-md shadow-lg",
                    "border",
                    `${colorConfig.border} ${colorConfig.bg}`,
                    "space-x-5 px-5 py-3"
                )}
            >
                {type === "success" && (
                    <BsCheckCircleIcon
                        className={cn("h-6 w-6", colorConfig.text)}
                    />
                )}

                {type === "error" && (
                    <FaXMarkCircleIcon
                        className={cn("h-6 w-6", colorConfig.text)}
                    />
                )}

                {type === "info" && (
                    <BsInfoCircleIcon
                        className={cn("h-6 w-6", colorConfig.text)}
                    />
                )}

                {type === "warning" && (
                    <BsExclamatoryIcon
                        className={cn("h-6 w-6", colorConfig.text)}
                    />
                )}

                <div className="flex-1 pr-12">
                    <h5 className={`${colorConfig.text} font-medium`}>
                        {message}
                    </h5>

                    {!!description && (
                        <p className="text-gray-500 break-words text-sm">
                            {description}
                        </p>
                    )}
                </div>
            </div>
        </Transition>
    );
};

export default ToastAlert;
