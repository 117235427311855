import Button from "@ui/Button.tsx";
import Input from "@ui/Input.tsx";
import PaymentMethodSelect from "@ui/payment-method-select";
import Select, { SelectValueType } from "@ui/select";
import StatusTransferSelect, {
    StatusOptionItemType
} from "@ui/StatusTransferSelect.tsx";
import { Dispatch, SetStateAction } from "react";

import HeaderContainer from "../../../components/HeaderContainer.tsx";
import PhoneInput from "../../../components/PhoneInput.tsx";
import { INPUT_DATE_FORMAT } from "../../../constants";
import { currentDateForInput } from "../../../helpers";
import { formatDate } from "../../../libs/date.ts";

const SERVICES = [
    {
        label: "Transfert",
        value: "transfer"
    }
    /*{
        label: "Cotisation",
        value: "membership"
    }*/
];

export interface FilterValueType {
    startDate: string;
    endDate: string;
    idTransfer: string;
    paymentMethods: {
        pay_in: number[];
        pay_out: number[];
    };
    service: SelectValueType;
    idAccount: string;
    payInPartner: string;
    payOutPartner: string;
    transferStatus: {
        payIn: StatusOptionItemType[];
        payOut: StatusOptionItemType[];
    };
    payInPhone: {
        indicative: string;
        phoneNumber: string;
    };
    payOutPhone: {
        indicative: string;
        phoneNumber: string;
    };
}

interface Props {
    onFilter: () => void;
    loading?: boolean;
    filter: FilterValueType;
    setFilter: Dispatch<SetStateAction<FilterValueType>>;
}

const FilterTransfersParams = (props: Props) => {
    const { onFilter, loading, filter, setFilter } = props;

    return (
        <HeaderContainer>
            <div className="flex items-center space-x-5 mb-7">
                <h4>Filtres</h4>

                <Button withAuto size="sm" onClick={onFilter} loading={loading}>
                    Appliquer
                </Button>
            </div>

            <div className="grid grid-cols-6 gap-5">
                <div className="relative">
                    <label
                        htmlFor="start-date"
                        className="absolute text-xs -top-3"
                    >
                        Date de début
                    </label>

                    <Input
                        id="start-date"
                        type="date"
                        inputClassName="placeholder-gray-400 text-gray-600"
                        value={filter.startDate}
                        onChange={e =>
                            setFilter({
                                ...filter,
                                startDate: e.target.value
                            })
                        }
                        max={
                            filter.endDate
                                ? formatDate(filter.endDate, INPUT_DATE_FORMAT)
                                : currentDateForInput()
                        }
                    />
                </div>

                <div className="relative">
                    <label
                        htmlFor="end-date"
                        className="absolute text-xs -top-3"
                    >
                        Date de fin
                    </label>

                    <Input
                        id="end-date"
                        type="date"
                        inputClassName="placeholder-gray-400 text-gray-600"
                        value={filter.endDate}
                        onChange={e =>
                            setFilter({
                                ...filter,
                                endDate: e.target.value
                            })
                        }
                        min={
                            filter.startDate
                                ? formatDate(
                                      filter.startDate,
                                      INPUT_DATE_FORMAT
                                  )
                                : undefined
                        }
                        max={currentDateForInput()}
                    />
                </div>

                <StatusTransferSelect
                    state={filter.transferStatus}
                    handleCheckStatus={(checkItem, key) => {
                        if (key === "payIn" || key === "payOut") {
                            setFilter({
                                ...filter,
                                transferStatus: {
                                    ...filter.transferStatus,
                                    [key]: filter.transferStatus[key].map(
                                        item => {
                                            if (
                                                item.value === checkItem.value
                                            ) {
                                                item.check = !item.check;
                                            }

                                            return item;
                                        }
                                    )
                                }
                            });
                        }
                    }}
                />

                <Input
                    type="text"
                    inputClassName="placeholder-gray-400 text-gray-600"
                    placeholder="ID transaction"
                    value={filter.idTransfer}
                    onChange={e =>
                        setFilter({ ...filter, idTransfer: e.target.value })
                    }
                />

                <PaymentMethodSelect
                    menuWithAuto
                    onChange={value =>
                        setFilter({ ...filter, paymentMethods: value })
                    }
                />

                <Select
                    isSearchable={false}
                    placeholder="Service"
                    disable
                    value={filter.service}
                    onChange={value => setFilter({ ...filter, service: value })}
                    options={SERVICES}
                />

                <Input
                    value={filter.idAccount}
                    onChange={e =>
                        setFilter({ ...filter, idAccount: e.target.value })
                    }
                    type="text"
                    placeholder="ID Compte"
                    inputClassName="placeholder-gray-400 text-gray-600"
                />

                <Input
                    type="text"
                    inputClassName="placeholder-gray-400 text-gray-600"
                    placeholder="Ref Pay In partner"
                    value={filter.payInPartner}
                    onChange={e =>
                        setFilter({ ...filter, payInPartner: e.target.value })
                    }
                />

                <Input
                    type="text"
                    inputClassName="placeholder-gray-400 text-gray-600"
                    placeholder="Ref Pay Out partner"
                    value={filter.payOutPartner}
                    onChange={e =>
                        setFilter({ ...filter, payOutPartner: e.target.value })
                    }
                />

                <div className="col-span-3 gap-5 grid grid-cols-2">
                    <PhoneInput
                        placeholder="Numéro PayIn"
                        indicative={filter.payInPhone.indicative}
                        value={filter.payInPhone.phoneNumber}
                        onChange={e => {
                            setFilter({
                                ...filter,
                                payInPhone: {
                                    indicative: filter.payInPhone.indicative,
                                    phoneNumber: e.target.value
                                }
                            });
                        }}
                        onIndicativeChange={newIndicative => {
                            setFilter({
                                ...filter,
                                payInPhone: {
                                    indicative: newIndicative,
                                    phoneNumber: filter.payInPhone.phoneNumber
                                }
                            });
                        }}
                    />

                    <PhoneInput
                        placeholder="Numéro PayOut"
                        indicative={filter.payOutPhone.indicative}
                        value={filter.payOutPhone.phoneNumber}
                        onChange={e => {
                            setFilter({
                                ...filter,
                                payOutPhone: {
                                    indicative: filter.payOutPhone.indicative,
                                    phoneNumber: e.target.value
                                }
                            });
                        }}
                        onIndicativeChange={newIndicative => {
                            setFilter({
                                ...filter,
                                payOutPhone: {
                                    indicative: newIndicative,
                                    phoneNumber: filter.payOutPhone.phoneNumber
                                }
                            });
                        }}
                    />
                </div>
            </div>
        </HeaderContainer>
    );
};

export default FilterTransfersParams;
