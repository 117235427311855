import { Switch } from "@headlessui/react";

import { cn } from "../../libs/style.ts";

interface Props {
    enabled?: boolean;
    onSwitch?: (value: boolean) => void;
}

const Switcher = (props: Props) => {
    return (
        <Switch
            data-testid="switcher"
            checked={props.enabled}
            onChange={props.onSwitch}
            className={cn({
                "relative transition inline-flex items-center rounded-full":
                    true,
                "h-4 w-8": true,
                "bg-blue-600": props.enabled,
                "bg-gray-200": !props.enabled
            })}
        >
            <span className="sr-only">Enable switcher</span>
            <span
                data-testid="switcher-circle"
                className={cn({
                    "transform rounded-full bg-white transition": true,
                    "inline-block h-5 w-5 border shadow-sm": true,
                    "translate-x-3": props.enabled,
                    "translate-x-0": !props.enabled
                })}
            />
        </Switch>
    );
};

export default Switcher;
