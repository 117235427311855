import HiChevronDownIcon from "@icon/HiChevronDownIcon.tsx";
import { Table } from "@tanstack/table-core";
import { useCallback, Fragment, useMemo } from "react";

import { generatePagination, scrollToTop } from "../../../helpers";
import { cn } from "../../../libs/style.ts";

interface Props<T> {
    table: Table<T>;
    currentPage: number;
    pageCount: number;
}

function TablePagination<T>(props: Props<T>) {
    const { table, currentPage, pageCount } = props;

    const gotoAnotherPage = useCallback(
        (isNext: boolean) => {
            setTimeout(() => {
                scrollToTop();
            }, 100);

            if (isNext) {
                table.nextPage();
            } else {
                table.previousPage();
            }
        },
        [table]
    );

    const navigateTo = useCallback(
        (page: number) => {
            setTimeout(() => {
                scrollToTop();
            }, 100);

            table.setPageIndex(page);
        },
        [table]
    );

    const paginationNumbers = useMemo(() => {
        return generatePagination(currentPage + 1, pageCount);
    }, [currentPage, pageCount]);

    return (
        <div className="flex items-center space-x-5 justify-end mt-4">
            <p className="text-xs">
                Page{" "}
                <strong>{table.getState().pagination.pageIndex + 1}</strong> sur{" "}
                <strong>{table.getPageCount()}</strong>
            </p>

            <div className="flex items-center space-x-3">
                <button
                    className={cn({
                        "border text-gray-500 rounded-md h-9 w-9": true,
                        "flex items-center justify-center rotate-90": true,
                        "cursor-not-allowed bg-gray-300/20":
                            !table.getCanPreviousPage()
                    })}
                    onClick={() => gotoAnotherPage(false)}
                    disabled={!table.getCanPreviousPage()}
                >
                    <HiChevronDownIcon />
                </button>

                {paginationNumbers.map((page, index) => {
                    let position:
                        | "first"
                        | "last"
                        | "single"
                        | "middle"
                        | undefined;

                    if (index === 0) position = "first";
                    if (index === table.getPageCount() - 1) position = "last";
                    if (table.getPageCount() === 1) position = "single";
                    if (page === "...") position = "middle";

                    return (
                        <Fragment key={index}>
                            {position === "middle" ? (
                                <div className="w-9 h-9 text-gray-500 flex items-center justify-center">
                                    ...
                                </div>
                            ) : (
                                <button
                                    className={cn({
                                        "border text-gray-500 rounded-md": true,
                                        "h-9 w-9": true,
                                        "flex items-center justify-center":
                                            true,
                                        "bg-blue-600 text-white cursor-not-allowed":
                                            currentPage + 1 === page
                                    })}
                                    onClick={() => navigateTo(Number(page) - 1)}
                                    disabled={currentPage + 1 === page}
                                >
                                    {page}
                                </button>
                            )}
                        </Fragment>
                    );
                })}

                <button
                    className={cn({
                        "border text-gray-500 rounded-md h-9 w-9": true,
                        "flex items-center justify-center -rotate-90": true,
                        "cursor-not-allowed bg-gray-300/20":
                            !table.getCanNextPage()
                    })}
                    onClick={() => gotoAnotherPage(true)}
                    disabled={!table.getCanNextPage()}
                >
                    <HiChevronDownIcon />
                </button>
            </div>
        </div>
    );
}

export default TablePagination;
