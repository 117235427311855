import { useLayoutEffect, useRef } from "react";
// import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import setupAxiosInterceptors from "../helpers/setupAxiosInterceptors.ts";
// import { logout } from "../store/authSlice.ts";

const useSetupAxiosInterceptors = () => {
    const navigate = useNavigate();
    const axiosSetup = useRef(false);

    // const dispatch = useDispatch();

    useLayoutEffect(() => {
        if (!axiosSetup.current) {
            axiosSetup.current = true;
            setupAxiosInterceptors(navigate, () => {
                // dispatch(logout());
            });
        }
    }, [navigate]);
};

export default useSetupAxiosInterceptors;
