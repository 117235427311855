import Button from "@ui/Button.tsx";
import Modal from "@ui/modal";

import { ModalPropsType } from "../types";

interface Props extends ModalPropsType {}

const SessionExpireModal = (props: Props) => {
    const { isOpen, closeModal } = props;

    return (
        <Modal
            isOpen={isOpen}
            closeModal={closeModal}
            className="p-6 w-[350px]"
        >
            <h4 className="text-center text-gray-700 text-lg font-medium mb-2">
                Votre session à expirer
            </h4>

            <p className="text-center text-sm mb-5">
                Veuillez vous connecter à noueau
            </p>

            <Button className="mx-auto" withAuto onClick={closeModal}>
                OK
            </Button>
        </Modal>
    );
};

export default SessionExpireModal;
