import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ChannelType } from "../types";

export interface ChannelCountryType {
    code_country: string;
    id: number;
    indicatif: string;
    name: string;
    channels: ChannelType[];
    code: string;
    phone_code: string;
    pay_in_channels?: ChannelType[];
    pay_out_channels?: ChannelType[];
}

interface ChannelListType {
    payIn: ChannelCountryType[] | null;
    payOut: ChannelCountryType[] | null;
}

interface ChannelState {
    list: ChannelListType;
}

const initialState: ChannelState = {
    list: {
        payIn: null,
        payOut: null
    }
};

const channelSlice = createSlice({
    name: "counter",
    initialState,
    reducers: {
        addChannels: (state, action: PayloadAction<ChannelListType>) => {
            state.list = action.payload;
            return state;
        }
    }
});

export const { addChannels } = channelSlice.actions;

const channelReducer = channelSlice.reducer;

export default channelReducer;
