import Button from "@ui/Button.tsx";
import Input from "@ui/Input.tsx";
import { useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import * as yup from "yup";

import { ENDPOINTS, PAGES, RESPONSE_STATUS } from "../../constants";
import { updateAxiosBaseUrl } from "../../helpers";
import useManageSchemaValidationError from "../../hooks/useManageSchemaValidationError.ts";
import useToast from "../../libs/useToast.tsx";
import rules from "../../schemas";
import { ApiService } from "../../types";

import AuthLayout from "./layouts/AuthLayout.tsx";

const TOAST_DURATION = 1000 * 10;

const ForgotSchema = yup.object().shape({
    email: rules.email
});

const ForgotPassword = () => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const { customSuccess: customSuccessToast, customError: customErrorToast } =
        useToast();

    const { errors, setErrors, resetErrors, showErrors } =
        useManageSchemaValidationError();

    const handleSendResetMail = useCallback(() => {
        if (!loading) {
            ForgotSchema.validate({ email }, { abortEarly: false })
                .then(() => {
                    setLoading(true);
                    updateAxiosBaseUrl(ApiService.auth);
                    window.axios
                        .post(ENDPOINTS.FORGOT_PASSWORD, { email })
                        .then(() => {
                            resetErrors();
                            customSuccessToast(
                                {
                                    message: "Email envoyé avec succès",
                                    description:
                                        "Un lien vous a été envoyé à votre address mail pour réinitialiser votre mot de passe"
                                },
                                { duration: TOAST_DURATION }
                            );
                        })
                        .catch(error => {
                            resetErrors();
                            const errorResponse = error.response.data.errors;
                            const status = error.response.status;

                            customErrorToast({
                                message: "Echec d'envoie de l'email"
                            });

                            if (
                                status === RESPONSE_STATUS.UNPROCESSABLE_ENTITY
                            ) {
                                setErrors(errorResponse);
                            }
                        })
                        .finally(() => setLoading(false));
                })
                .catch(showErrors());
        }
    }, [
        loading,
        email,
        showErrors,
        resetErrors,
        customSuccessToast,
        customErrorToast,
        setErrors
    ]);

    return (
        <AuthLayout
            title="Récupérez votre mot de passe"
            description="Veuilez renseigner votre address email"
        >
            <Input
                placeholder="Email"
                className="mb-8"
                value={email}
                type="email"
                name="email"
                onChange={e => setEmail(e.target.value)}
                error={errors?.email}
            />

            {/*<div className="text-right mb-8">
                <a
                    className="text-sm hover:underline text-blue-600"
                    href="#resend"
                    onClick={handleSendResetMail}
                >
                    Renvoyer le mail
                </a>
            </div>*/}

            <Button onClick={handleSendResetMail} loading={loading}>
                Confirmer
            </Button>

            <div className="text-center mt-2">
                <NavLink
                    className="text-blue-600 hover:underline text-sm inline-block"
                    to={PAGES.LOGIN}
                >
                    Je me souviens
                </NavLink>
            </div>
        </AuthLayout>
    );
};

export default ForgotPassword;
