import { createContext } from "react";

export interface TabStoreType {
    index: number;
    changeIndex: (index: number) => void;
}

const TabContext = createContext<TabStoreType | null>(null);

export default TabContext;
