import { useMemo } from "react";

import { SIMPLE_DATE_FORMAT_HOUR } from "../constants";
import { formatPayStatus } from "../helpers";
import useCountries from "../hooks/useCountries.tsx";
import { formatDate } from "../libs/date.ts";
import { cn } from "../libs/style.ts";
import { TRANSACTION_STATUS, TransferType } from "../types";

import CopyClipboardButton from "./CopyClipboardButton.tsx";
import ReceiptRow from "./ReceiptRow.tsx";

interface Props {
    transfer: TransferType;
    containerClassName?: string;
}

const TransferDetail = (props: Props) => {
    const { transfer, containerClassName } = props;

    const { getCountryByCountryId } = useCountries();

    const sendAmount = useMemo(() => {
        if (!transfer.support_fees) return parseInt(transfer.amount);

        return parseInt(transfer?.amount) + parseInt(transfer?.fees);
    }, [transfer.amount, transfer?.fees, transfer.support_fees]);

    const receiveAmount = useMemo(() => {
        if (transfer.support_fees) return parseInt(transfer.amount);

        return parseInt(transfer.amount) - parseInt(transfer.fees);
    }, [transfer.amount, transfer.fees, transfer.support_fees]);

    const statusRefund = useMemo(() => {
        if (transfer.status === TRANSACTION_STATUS.REFUND) return "Rembourser";

        if (transfer.status === TRANSACTION_STATUS.TO_REFUND)
            return "A rembourser";

        return "---";
    }, [transfer.status]);

    const sendByCard = useMemo(
        () => !transfer?.pay_in_channel?.country_id,
        [transfer?.pay_in_channel?.country_id]
    );

    const wallet = useMemo(() => {
        const payInCountry =
            getCountryByCountryId(
                parseInt(
                    transfer?.pay_in_channel?.country_id
                        ? transfer.pay_in_channel.country_id.toString()
                        : ""
                )
            ) || null;

        const payOutCountry =
            getCountryByCountryId(
                parseInt(
                    transfer.pay_out_channel?.country_id
                        ? transfer.pay_out_channel.country_id.toString()
                        : ""
                )
            ) || null;

        return {
            payIn: transfer?.pay_in_channel?.name
                ? cn({
                      [`${transfer.pay_in_channel?.name} (${payInCountry?.name})`]:
                          !!transfer.pay_in_channel?.country_id,
                      [`${transfer.pay_in_channel?.name}`]:
                          !transfer.pay_in_channel?.country_id
                  })
                : "---",
            payOut: transfer?.pay_out_channel?.name
                ? `${transfer.pay_out_channel?.name} (${payOutCountry?.name})`
                : "---"
        };
    }, [
        getCountryByCountryId,
        transfer.pay_in_channel,
        transfer.pay_out_channel
    ]);

    return (
        <div
            className={cn(
                "mt-6 px-6 flex items-center justify-between",
                containerClassName
            )}
        >
            <div className="w-5/12">
                <h4 className="font-medium mb-3">Envoyer de</h4>

                <div className="text-sm text-gray-500">
                    <ReceiptRow
                        label="N° de téléphone"
                        value={
                            sendByCard
                                ? transfer?.user?.phone_with_indicative || ""
                                : transfer?.sender_phone
                        }
                    />

                    <ReceiptRow label="Envoyer par" value={wallet.payIn} />

                    <ReceiptRow
                        label="Réference partenaire"
                        value={
                            transfer.ref_pay_in_partner ? (
                                <CopyClipboardButton
                                    text={transfer.ref_pay_in_partner}
                                />
                            ) : (
                                "---"
                            )
                        }
                    />

                    <ReceiptRow
                        label="Nom émetteur"
                        value={transfer?.user?.full_name || "---"}
                    />

                    {sendByCard && (
                        <>
                            <ReceiptRow
                                label="Nom sur la carte"
                                value={
                                    transfer?.sender_transfer_card?.name ||
                                    "---"
                                }
                            />

                            <ReceiptRow
                                label="Numéro de la carte"
                                value={
                                    transfer?.sender_transfer_card
                                        ?.card_number || "---"
                                }
                            />
                        </>
                    )}

                    <ReceiptRow
                        label="Date payin"
                        value={
                            transfer?.successPayInTransaction?.created_at
                                ? formatDate(
                                      transfer?.successPayInTransaction
                                          ?.created_at,
                                      SIMPLE_DATE_FORMAT_HOUR
                                  )
                                : "---"
                        }
                    />

                    <ReceiptRow
                        label="Statut Payin"
                        value={formatPayStatus(transfer?.status_pay_in || "")}
                    />

                    <hr className="my-6" />

                    <ReceiptRow
                        label="Statut du transfert"
                        value={formatPayStatus(transfer?.status || "")}
                    />

                    <ReceiptRow
                        label="Statut remboursement"
                        value={statusRefund}
                    />

                    <ReceiptRow
                        label="Frais moyen de paiement"
                        value={
                            transfer?.successPayInTransaction?.channel_fees ||
                            "---"
                        }
                    />

                    <ReceiptRow
                        label="Marge"
                        value={
                            transfer.successPayInTransaction
                                ? parseInt(
                                      transfer.successPayInTransaction.marge
                                  ).toString()
                                : "---"
                        }
                    />

                    <ReceiptRow label="Service" value="Transfert" />
                </div>
            </div>

            <div className="w-5/12">
                <h4 className="font-medium mb-3">Envoyer à</h4>

                <div className="text-sm text-gray-500">
                    <ReceiptRow
                        label="N° de téléphone"
                        value={transfer?.receiver_phone}
                    />

                    <ReceiptRow label="Envoyer sur" value={wallet.payOut} />

                    <ReceiptRow
                        label="Réference partenaire"
                        value={
                            transfer?.ref_pay_out_partner ? (
                                <CopyClipboardButton
                                    text={transfer?.ref_pay_out_partner}
                                />
                            ) : (
                                "---"
                            )
                        }
                    />

                    <ReceiptRow
                        label="Nom destinataire"
                        value={transfer?.receiver_name || "---"}
                    />

                    <ReceiptRow
                        label="Date payout"
                        value={
                            transfer?.successPayOutTransaction?.updated_at
                                ? formatDate(
                                      transfer?.successPayOutTransaction
                                          ?.updated_at,
                                      SIMPLE_DATE_FORMAT_HOUR
                                  )
                                : "---"
                        }
                    />

                    <ReceiptRow
                        label="Statut Payout"
                        value={formatPayStatus(transfer?.status_pay_out || "")}
                    />

                    {sendByCard && (
                        <>
                            <br />
                            <br />
                        </>
                    )}

                    <hr className="my-6" />

                    <br />

                    <ReceiptRow
                        label="Montant envoyé"
                        value={`${sendAmount} Fcfa`}
                    />

                    <ReceiptRow
                        label="Montant reçu"
                        value={`${receiveAmount} Fcfa`}
                    />

                    <ReceiptRow
                        label="C.A (Frais)"
                        value={`${parseInt(transfer.fees)} Fcfa`}
                    />

                    <ReceiptRow
                        label="Référence MYD"
                        value={
                            transfer?.identifier ? (
                                <CopyClipboardButton
                                    text={transfer?.identifier}
                                />
                            ) : (
                                "---"
                            )
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default TransferDetail;
