import { ReactNode } from "react";

import { cn } from "../libs/style.ts";

interface Props {
    children: ReactNode;
    className?: string;
}

const HeaderContainer = (props: Props) => {
    const { children, className } = props;

    return (
        <div className={cn("bg-white border rounded-md px-3 py-8", className)}>
            {children}
        </div>
    );
};

export default HeaderContainer;
