import { Dropdown, DropdownItems } from "@ui/Dropdown.tsx";

import useNotification from "../../hooks/useNotification.ts";
import { cn } from "../../libs/style.ts";

import NotificationBellButton from "./NotificationBellButton.tsx";
import NotificationFooter from "./NotificationFooter.tsx";
import NotificationHeader from "./NotificationHeader.tsx";
import NotificationItem from "./NotificationItem.tsx";

const MORE_NINE_NOTIFICATION = "+9";

const NotificationDropdown = () => {
    const { toggle, setToggle, notifications, unreadNotification } =
        useNotification();

    return (
        <Dropdown as="div">
            <NotificationBellButton>
                {unreadNotification.length < 9
                    ? unreadNotification.length
                    : MORE_NINE_NOTIFICATION}
            </NotificationBellButton>

            <DropdownItems
                className={cn(
                    "text-sm w-11/12 lg:w-[450px] rounded-md focus:outline-none",
                    "bg-white text-gray-800 border shadow-sm",
                    "absolute z-10 top-16 right-4 lg:mt-2"
                )}
            >
                <NotificationHeader toggle={toggle} setToggle={setToggle} />

                <div className="divide-y h-[227px] overflow-y-auto">
                    {notifications.map((item, index) => (
                        <NotificationItem key={index} value={item} />
                    ))}
                </div>

                <NotificationFooter />
            </DropdownItems>
        </Dropdown>
    );
};

export default NotificationDropdown;
