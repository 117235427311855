import { NotificationDropdown } from "../notifications";

const Nav = () => {
    return (
        <div className="fixed h-16 border-b z-20 bg-white w-full flex justify-end">
            <NotificationDropdown />
        </div>
    );
};

export default Nav;
