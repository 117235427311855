import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../store";
import { ChannelType } from "../types";

export interface CountryWalletType {
    name: string;
    payIn: WalletType | null;
    payOut: WalletType | null;
}

interface WalletType extends ChannelType {
    id: number;
    label: string;
    value: string;
    countryCode: string;
    countryIndicative: string;
}

const useManageWalletChannels = () => {
    const { list: channelCountries } = useSelector(
        (state: RootState) => state.channels
    );
    const [walletByCountry, setWalletByCountry] = useState<CountryWalletType[]>(
        []
    );

    const getChannels = useCallback(
        (isPayInChannel: boolean) => {
            const attribute = isPayInChannel ? "payIn" : "payOut";
            return channelCountries[attribute]?.map(item => {
                const code = item.code;
                const indicatif = item.phone_code;

                return {
                    id: item.id,
                    label: item.name,
                    indicative: indicatif,
                    codeCountry: code,
                    options: item.channels.map(channel => ({
                        ...channel,
                        id: channel.id,
                        label: channel.name,
                        value: channel.slug,
                        countryCode: code,
                        countryIndicative: indicatif
                    }))
                };
            });
        },
        [channelCountries]
    );

    const channels = useMemo(() => {
        const payin = getChannels(true) || [];
        const payout = getChannels(false) || [];

        let payinWallet: ChannelType[] = [];
        payin.forEach(item => {
            payinWallet = [
                ...payinWallet,
                ...item.options.filter(item => item?.country_id)
            ];
        });

        if (payin.length > 0) {
            const foundItem = payin[0].options.filter(
                item => !item?.country_id
            );

            if (foundItem.length) {
                payinWallet = [foundItem[0], ...payinWallet];
            }
        }

        let payoutWallet: ChannelType[] = [];
        payout.forEach(item => {
            payoutWallet = [...payoutWallet, ...item.options];
        });

        return {
            payin: payin,
            payinWallet,
            payout: payout,
            payoutWallet
        };
    }, [getChannels]);

    const groupWalletByCountry = useCallback(() => {
        const payIn = getChannels(true) || [];
        const payOut = getChannels(false) || [];

        const wallets: CountryWalletType[] = [];
        let internationalChannels: {
            name: string;
            payIn: ChannelType | null;
            payOut: ChannelType | null;
        } | null = null;

        payIn.forEach(item => {
            const payOutChanel = payOut.find(
                payoutItem => item.codeCountry === payoutItem.codeCountry
            );

            if (payOutChanel) {
                item.options.forEach(payInOption => {
                    const foundPayOutOption = payOutChanel.options.find(
                        payOutOption => payOutOption.slug === payInOption.slug
                    );

                    if (foundPayOutOption) {
                        wallets.push({
                            name: `${payInOption.label} (${item.label})`,
                            payIn: payInOption,
                            payOut: foundPayOutOption
                        });
                        payOutChanel.options = payOutChanel.options.filter(
                            item => item.slug !== foundPayOutOption.slug
                        );
                    } else {
                        if (payInOption.country_id) {
                            wallets.push({
                                name: `${payInOption.label} (${item.label})`,
                                payIn: payInOption,
                                payOut: null
                            });
                        } else {
                            if (!internationalChannels) {
                                internationalChannels = {
                                    name: `${payInOption.label}`,
                                    payIn: payInOption,
                                    payOut: null
                                };
                            }
                        }
                    }
                });

                if (payOutChanel.options.length) {
                    payOutChanel.options.forEach(payOutChannelItem => {
                        wallets.push({
                            name: `${payOutChannelItem.label} (${item.label})`,
                            payIn: null,
                            payOut: payOutChannelItem
                        });
                    });
                }
            }
        });

        if (internationalChannels) {
            wallets.unshift(internationalChannels);
        }

        return wallets;
    }, [getChannels]);

    useEffect(() => {
        setWalletByCountry(groupWalletByCountry());
    }, [channels.payin, channels.payout, groupWalletByCountry]);

    return {
        payinChannels: channels.payin,
        payinWallets: channels.payinWallet,
        payoutChannels: channels.payout,
        payoutWallets: channels.payoutWallet,
        getChannelById: useCallback(
            (isPayIn: boolean, id: number | string) => {
                let channel = null;
                if (isPayIn) {
                    for (let i = 0; i < channels.payin.length; i++) {
                        for (
                            let x = 0;
                            x < channels.payin[i].options.length;
                            x++
                        ) {
                            if (channels.payin[i].options[x].id === id) {
                                channel = channels.payin[i].options[x];
                                break;
                            }
                        }
                        if (channel) break;
                    }
                } else {
                    for (let i = 0; i < channels.payout.length; i++) {
                        for (
                            let x = 0;
                            x < channels.payout[i].options.length;
                            x++
                        ) {
                            if (channels.payout[i].options[x].id === id) {
                                channel = channels.payout[i].options[x];
                                break;
                            }
                        }
                        if (channel) break;
                    }
                }
                return channel;
            },
            [channels.payin, channels.payout]
        ),
        walletByCountry,
        setWalletByCountry
    };
};

export default useManageWalletChannels;
