import React from "react";
import {
    NavLink,
    NavLinkProps,
    useMatch,
    useResolvedPath
} from "react-router-dom";

import { cn } from "../../../libs/style.ts";

interface Props extends NavLinkProps {
    icon: React.ReactNode;
    children?: React.ReactNode;
}

const AsideLink = (props: Props) => {
    const { icon, children, className, ...rest } = props;

    const resolved = useResolvedPath(rest.to);
    const match = useMatch({ path: resolved.pathname, end: true });

    return (
        <NavLink
            {...rest}
            className={cn({
                "flex items-center": true,
                "text-gray-700 hover:text-blue-600 group": true,
                "text-blue-600": match,
                [className as string]: true
            })}
        >
            {icon}
            <span className="ml-4 text-sm">{children}</span>
        </NavLink>
    );
};

export default AsideLink;
