import { SIMPLE_DATE_FORMAT_HOUR_CUSTOM } from "../../../constants";
import { formatDate } from "../../../libs/date.ts";
import { KycType } from "../../../types";

interface Props {
    kyc: KycType;
}

const RemoveList = (props: Props) => {
    const { kyc } = props;

    return (
        <div className="ml-16 bg-pin">
            <h3 className="text-lg font-semibold mb-4">
                Historique de supression
            </h3>

            <table className="w-full z-0 table-auto border-collapse text-sm">
                <thead className="text-gray-400 bg-slate-100">
                    <tr>
                        <th className="px-4 py-5 font-medium text-left">
                            Date de création
                        </th>
                        <th className="px-4 py-5 font-medium text-left">
                            Date de supression
                        </th>
                        <th className="px-4 py-5 font-medium text-left">
                            Motif de supression
                        </th>
                    </tr>
                </thead>

                <tbody className="text-gray-700">
                    {kyc.account_deleted_histories.map((item, index) => (
                        <tr key={index} className="border-b">
                            <td className="px-4 py-3">
                                {item.account_created_at
                                    ? formatDate(
                                          item.account_created_at,
                                          SIMPLE_DATE_FORMAT_HOUR_CUSTOM
                                      )
                                    : "---"}
                            </td>

                            <td className="px-4 py-3">
                                {item.account_deleted_at
                                    ? formatDate(
                                          item.account_deleted_at,
                                          SIMPLE_DATE_FORMAT_HOUR_CUSTOM
                                      )
                                    : "---"}
                            </td>

                            <td className="px-4 py-3">
                                {item.deleted_reason || "---"}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default RemoveList;
