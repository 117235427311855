import Button from "@ui/Button.tsx";
import Modal from "@ui/modal";
import { useCallback } from "react";

import { cn } from "../../libs/style.ts";
import { ModalPropsType } from "../../types";

interface Props extends ModalPropsType {
    title?: string;
    description?: string;
    loading?: boolean;
    onConfirm?: () => void;
    buttonText?: {
        cancel?: string;
        confirm?: string;
    };
}

const ConfirmModal = (props: Props) => {
    const {
        isOpen,
        closeModal,
        title,
        description,
        buttonText = { cancel: "Annuler", confirm: "Confirmer" },
        onConfirm = () => {},
        loading
    } = props;

    const handleClose = useCallback(() => {
        !loading && closeModal();
    }, [closeModal, loading]);

    return (
        <Modal
            isOpen={isOpen}
            closeModal={handleClose}
            className="max-w-[450px]"
        >
            <div className="p-6">
                <h3 className="text-center text-3xl text-gray-700 mb-3">
                    {title || "Confirmation"}
                </h3>

                <p
                    className={cn({
                        "text-center text-gray-500 mb-8": true
                    })}
                >
                    {description ||
                        "Êtes-vous sûr de vouloir effectuer cette action?"}
                </p>

                <div className="flex items-center justify-center space-x-4">
                    <Button variant="gray" withAuto onClick={handleClose}>
                        {buttonText?.cancel || "Annuler"}
                    </Button>

                    <Button
                        withAuto
                        onClick={onConfirm}
                        loading={loading}
                        disabled={loading}
                    >
                        {buttonText?.confirm || "Confirmer"}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmModal;
