import HiChevronDownIcon from "@icon/HiChevronDownIcon.tsx";
import {
    Dropdown,
    DropdownButton,
    DropdownItem,
    DropdownItems
} from "@ui/Dropdown.tsx";
import { Fragment, useMemo } from "react";

import { cn } from "../libs/style.ts";

interface DropdownItemType {
    label: string;
    action: () => void;
    show?: boolean;
}

interface Props {
    buttonText: string;
    items: DropdownItemType[];
}

const CustomDropdown = (props: Props) => {
    const { buttonText, items } = props;

    const formatItems = useMemo(() => {
        return items.map(item => {
            return {
                ...item,
                show: typeof item.show === "undefined" ? true : item.show
            };
        });
    }, [items]);

    return (
        <Dropdown as="div" className="relative">
            <DropdownButton
                className={cn(
                    "flex items-center font-medium justify-center px-6 ",
                    "w-auto py-2 space-x-3",
                    "focus:ring focus:border-blue-600 focus:ring-blue-600/20",
                    "text-base rounded-md disabled:cursor-not-allowed",
                    "border text-gray-600 focus:outline-none"
                )}
            >
                <span>{buttonText}</span>
                <HiChevronDownIcon />
            </DropdownButton>

            <DropdownItems
                className={cn({
                    "text-sm rounded-md focus:outline-none": true,
                    "bg-white text-gray-800 border shadow-sm": true,
                    "absolute z-10 right-0 w-full lg:mt-2": true
                })}
            >
                {formatItems.map((item, index) => (
                    <Fragment key={index}>
                        {item.show && (
                            <DropdownItem>
                                <li
                                    className={cn({
                                        "cursor-pointer list-none py-2 px-3":
                                            true,
                                        "hover:bg-gray-100 truncate text-base text-gray-500":
                                            true
                                    })}
                                    onClick={item.action}
                                >
                                    {item.label}
                                </li>
                            </DropdownItem>
                        )}
                    </Fragment>
                ))}
            </DropdownItems>
        </Dropdown>
    );
};

export default CustomDropdown;
