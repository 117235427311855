import Button from "@ui/Button.tsx";
import Modal from "@ui/modal";
import { useCallback } from "react";

import { ModalPropsType } from "../../../types";

interface Props extends ModalPropsType {
    title?: "";
    description: string;
    loading?: boolean;
    onConfirm: () => void;
    okText?: string;
    cancelText?: string;
}

const ConfirmEnableOrDisableModal = (props: Props) => {
    const {
        isOpen,
        closeModal,
        loading,
        onConfirm,
        description,
        title = "Être vous sur?",
        okText = "Confirmer",
        cancelText = "Quitter"
    } = props;

    const handleClose = useCallback(() => {
        !loading && closeModal();
    }, [closeModal, loading]);

    return (
        <Modal
            isOpen={isOpen}
            closeModal={handleClose}
            className="pb-5 max-w-[400px] px-5"
        >
            <h4 className="text-center text-gray-700 text-lg font-medium mb-2">
                {title}
            </h4>

            <p className="text-center text-sm mb-5">{description}</p>

            <div className="flex items-center justify-center space-x-5">
                <Button variant="gray" withAuto onClick={handleClose}>
                    {cancelText}
                </Button>

                <Button withAuto onClick={onConfirm} loading={loading}>
                    {okText}
                </Button>
            </div>
        </Modal>
    );
};

export default ConfirmEnableOrDisableModal;
