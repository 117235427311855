import { cn } from "../../libs/style";
import { IconProps } from "../../types";

const ExcelIcon = ({ size = "md", color, className = "" }: IconProps) => {
    return (
        <svg
            role="img"
            className={cn({
                "text-green-500": color === "green",
                "text-yellow-500": color === "yellow",
                "text-red-500": color === "red",
                "h-3 w-3": size === "xs",
                "h-4 w-4": size === "sm",
                "h-5 w-5": size === "md",
                "h-7 w-7": size === "lg",
                "h-8 w-8": size === "xl",
                [className]: true
            })}
            stroke="currentColor"
            fill="none"
            strokeWidth="2"
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"></path>
            <path d="M7 11l5 5l5 -5"></path>
            <path d="M12 4l0 12"></path>
        </svg>
    );
};

export default ExcelIcon;
