import Loading from "@ui/Loading.tsx";

const TableLoader = () => {
    return (
        <div className="absolute inset-0 flex items-center justify-center top-16">
            <Loading className="z-10 h-8 w-8 text-blue-600" />
        </div>
    );
};

export default TableLoader;
