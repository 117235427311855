import { Dayjs } from "dayjs";

import("dayjs/locale/fr");
import config from "../config";
import { DEFAULT_DATE_FR_FORMAT } from "../constants";
import { getConfig } from "../helpers";

export function fromNow(
    value: string | Date | Dayjs,
    locale = getConfig()?.locale || config.locale
) {
    return window.dayjs(value).locale(locale).fromNow();
}

export function formatDate(
    value: string | Date | Dayjs,
    format = DEFAULT_DATE_FR_FORMAT,
    locale = getConfig()?.locale || config.locale
) {
    return window.dayjs(value).locale(locale).format(format);
}

export function yesterday() {
    return window.dayjs().subtract(1, "day");
}
