import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { ENDPOINTS } from "../constants";
import { updateAxiosBaseUrl } from "../helpers";
import { addCountries } from "../store/countriesSlice.ts";
import { ApiService } from "../types";

const useFetchCountries = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        updateAxiosBaseUrl(ApiService.transfer);
        window.axios.get(ENDPOINTS.COUNTRIES).then(response => {
            dispatch(addCountries(response.data.data || []));
        });
    }, [dispatch]);
};

export default useFetchCountries;
