import HeSearchIcon from "@icon/HeSearchIcon.tsx";
import Input, { InputPropsType } from "@ui/Input.tsx";

const SelectSearch = (props: InputPropsType) => {
    return (
        <Input
            data-testid="searchbox"
            className="mb-2.5"
            variant="secondary"
            icon={<HeSearchIcon className="text-gray-500" />}
            iconRight={false}
            onChange={props.onChange}
            onKeyDown={e => {
                e.stopPropagation();
            }}
        />
    );
};

export default SelectSearch;
