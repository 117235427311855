import HiOutlineIcon from "@icon/HiOutlineIcon.tsx";
import { DropdownButton } from "@ui/Dropdown.tsx";
import React from "react";

import { cn } from "../../libs/style.ts";

interface Props {
    children: React.ReactNode;
}

const NotificationBellButton = (props: Props) => {
    return (
        <DropdownButton
            type="button"
            as="button"
            className={cn(
                "relative py-4 px-4 lg:px-12 lg:py-3",
                "lg:flex lg:flex-col items-center justify-center"
            )}
        >
            <HiOutlineIcon size="lg" />
            <span className="hidden lg:inline text-sm">Notifications</span>
            {props.children !== 0 && (
                <span
                    className={cn(
                        "absolute top-1 ml-3 shadow-sm bg-red-500 rounded-full h-5 w-5",
                        "text-white text-[10px] font-medium",
                        "flex items-center justify-center"
                    )}
                >
                    {props.children}
                </span>
            )}
        </DropdownButton>
    );
};

export default NotificationBellButton;
