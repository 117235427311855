import { Menu } from "@headlessui/react";
import { SelectValueType } from "@ui/select/index.tsx";

import { cn } from "../../../libs/style.ts";

interface Props {
    value: SelectValueType;
    placeholder: string;
    className?: string;
    open?: boolean;
    disable?: boolean;
    onClick?: () => void;
}

const SelectButton = (props: Props) => {
    const { value, open, className, placeholder, disable, onClick } = props;

    return (
        <Menu.Button
            className={cn({
                "w-full py-2.5 px-4 border border-gray-300": true,
                "flex items-center text-sm": true,
                "rounded-md bg-white mt-2": true,
                "border-blue-600 ring ring-blue-600/20": open,
                "bg-gray-300/20 cursor-not-allowed": disable,
                [className || ""]: true
            })}
            data-testid="select-button"
            disabled={disable}
            onClick={onClick}
        >
            <p
                className={cn({
                    truncate: true,
                    "text-gray-400": !value || (!!value && disable),
                    "text-gray-600": !!value && !disable
                })}
            >
                {value ? value.label : placeholder}
            </p>
        </Menu.Button>
    );
};

export default SelectButton;
