import BiSolidUserIcon from "@icon/BiSolidUserIcon.tsx";
import ExcelIcon from "@icon/ExcelIcon.tsx";
// import FaUserCircleIcon from "@icon/FaUserCircleIcon.tsx";
import HiMiniDevicePhoneMobileIcon from "@icon/HiMiniDevicePhoneMobileIcon.tsx";
import LoMdWalletIcon from "@icon/LoMdWalletIcon.tsx";
import SiZorinIcon from "@icon/SiZorinIcon.tsx";
import TbUserSearchIcon from "@icon/TbUserSearchIcon.tsx";
import Button from "@ui/Button.tsx";
import { useSelector } from "react-redux";

import { PAGES, PERMISSIONS, ROLES } from "../../../constants";
import { userHaveOneRoles, userHavePermissions } from "../../../helpers";
import useLogout from "../../../hooks/useLogout.ts";
import { RootState } from "../../../store";

import AsideLink from "./AsideLink";
import AsideProfile from "./AsideProfile.tsx";

const Aside = () => {
    const { logoutLoading } = useSelector((state: RootState) => state.auth);
    const { logout: logoutUser } = useLogout();

    return (
        <div className="fixed border-r w-[103px] pt-16 md:w-[250px] h-screen">
            <div className="px-6 pt-12 h-full flex justify-between flex-col">
                <div className="space-y-5">
                    {userHavePermissions([PERMISSIONS.LIST_TRANSFER_FILE]) && (
                        <AsideLink
                            to={PAGES.TRANSFERS_FILES}
                            icon={
                                <ExcelIcon className="group-hover:text-blue-500" />
                            }
                        >
                            Historique MyDunya
                        </AsideLink>
                    )}

                    {userHavePermissions([PERMISSIONS.LIST_TRANSFER]) &&
                        userHaveOneRoles([
                            ROLES.FIN_OPS,
                            ROLES.SUPER_ADMIN
                        ]) && (
                            <AsideLink
                                to={PAGES.TRANSFERS}
                                icon={<LoMdWalletIcon />}
                            >
                                Opérations
                            </AsideLink>
                        )}

                    {userHavePermissions([PERMISSIONS.LIST_ACCOUNT_FILE]) && (
                        <AsideLink
                            to={PAGES.ACCOUNTS}
                            icon={<BiSolidUserIcon />}
                        >
                            Comptes
                        </AsideLink>
                    )}

                    {userHavePermissions([PERMISSIONS.LIST_TRANSFER]) &&
                        userHaveOneRoles([
                            ROLES.SME,
                            ROLES.SUPER_ADMIN,
                            ROLES.RISK
                        ]) && (
                            <AsideLink
                                to={PAGES.TRANSFER_SEARCH}
                                icon={<LoMdWalletIcon />}
                            >
                                Transactions SME
                            </AsideLink>
                        )}

                    {userHavePermissions([PERMISSIONS.LIST_KYC]) && (
                        <AsideLink to={PAGES.RISK} icon={<SiZorinIcon />}>
                            Risque Gestion
                        </AsideLink>
                    )}

                    {userHavePermissions([
                        PERMISSIONS.ENABLE_DISABLE_CHANNELS
                    ]) && (
                        <AsideLink
                            to={PAGES.WALLETS}
                            icon={<HiMiniDevicePhoneMobileIcon />}
                        >
                            List des wallets
                        </AsideLink>
                    )}

                    {userHavePermissions([PERMISSIONS.SEARCH_USER_ACCOUNT]) && (
                        <AsideLink
                            to={PAGES.ACCOUNT_SEARCH}
                            icon={<TbUserSearchIcon />}
                        >
                            Infos utilisateurs
                        </AsideLink>
                    )}
                </div>

                <div className="pb-5">
                    {/*<AsideLink
                        to="access-moc"
                        icon={<FaUserCircleIcon />}
                        className="mb-7"
                    >
                        Gestion Accès Moc
                    </AsideLink>*/}

                    <AsideProfile />

                    <Button
                        className="mt-2"
                        loading={logoutLoading}
                        onClick={() => logoutUser("Succès de la déconnexion")}
                    >
                        Déconnexion
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Aside;
