import Tippy from "@tippyjs/react";
import { JSXElementConstructor, ReactElement, ReactNode } from "react";
import "tippy.js/dist/tippy.css";

interface Props {
    /* eslint-disable */
    children?: ReactElement<any, string | JSXElementConstructor<any>>;
    /* eslint-enable */
    content: ReactNode;
    placement?: "bottom" | "left" | "top" | "right";
    interactive?: boolean;
}

const Tooltip = (props: Props) => {
    const { children, content, placement = "top", interactive = true } = props;

    return (
        <Tippy
            interactive={interactive}
            content={<span className="text-xs truncate">{content}</span>}
            placement={placement}
            onShow={() => {
                /* eslint-disable */
                console.clear();
                /* eslint-enable */
            }}
        >
            {children}
        </Tippy>
    );
};

export default Tooltip;
