import { useEffect } from "react";

import { STORAGE } from "../constants";
import { auth, clearActivityTimer, storage } from "../helpers";

const useInactivity = (timeout = 5000, callback?: () => void) => {
    useEffect(() => {
        function handleInactivity() {
            callback && callback();
        }

        function resetInactivityTimer() {
            if (auth()?.isLogin) {
                clearActivityTimer();

                storage().setItem(
                    STORAGE.ACTIVITY,
                    `${window.setTimeout(handleInactivity, timeout)}`
                );
            }
        }

        function handleActivity() {
            resetInactivityTimer();
        }

        const events = ["mousemove", "keydown", "scroll", "click"];

        events.forEach(item => {
            document.addEventListener(item, handleActivity);
        });

        resetInactivityTimer();

        return () => {
            events.forEach(item => {
                document.removeEventListener(item, handleActivity);
            });
        };
    });
};

export default useInactivity;
