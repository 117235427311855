import ToastAlert from "@ui/ToastAlert.tsx";
import { useCallback } from "react";
import toast, { ToastOptions } from "react-hot-toast";

import config from "../config";
import { AlertType } from "../types";

interface PromiseToastMessageType {
    loadingMessage: string;
    successMessage: string;
    errorMessage: string;
}

interface CustomToastMessageType {
    message: string;
    description?: string;
}

const useToast = () => {
    const showSimpleToast = useCallback(
        (message: string, key: "success" | "error", option?: ToastOptions) => {
            const useOption = {
                duration: parseInt(config.toastTimeout.toString()),
                ...(option || {}),
                className: "text-gray-700 max-w-[700px]"
            };
            return toast[key](() => message, useOption);
        },
        []
    );

    const showCustomToast = useCallback(
        (
            message: CustomToastMessageType,
            type: AlertType,
            option?: ToastOptions
        ) => {
            const userOption = {
                duration: parseInt(config.toastTimeout.toString()),
                ...(option || {})
            };

            return toast.custom(
                t => (
                    <ToastAlert
                        type={type}
                        message={message.message}
                        description={message.description}
                        show={t.visible}
                        onClose={() => toast.dismiss(t.id)}
                    />
                ),
                userOption
            );
        },
        []
    );

    return {
        success: useCallback(
            (message: string, option?: ToastOptions) =>
                showSimpleToast(message, "success", option),
            [showSimpleToast]
        ),
        error: useCallback(
            (message: string, option?: ToastOptions) =>
                showSimpleToast(message, "error", option),
            [showSimpleToast]
        ),
        loading: toast.loading,
        promise: useCallback(
            (
                promise: Promise<unknown>,
                {
                    loadingMessage,
                    successMessage,
                    errorMessage
                }: PromiseToastMessageType
            ) => {
                toast.promise(promise, {
                    loading: loadingMessage,
                    success: successMessage,
                    error: errorMessage
                });
            },
            []
        ),
        dismiss: toast.dismiss,
        remove: toast.remove,
        customSuccess: useCallback(
            (message: CustomToastMessageType, option?: ToastOptions) =>
                showCustomToast(message, "success", option),
            [showCustomToast]
        ),
        customError: useCallback(
            (message: CustomToastMessageType, option?: ToastOptions) =>
                showCustomToast(message, "error", option),
            [showCustomToast]
        ),
        customInfo: useCallback(
            (message: CustomToastMessageType, option?: ToastOptions) =>
                showCustomToast(message, "info", option),
            [showCustomToast]
        ),
        customWarning: useCallback(
            (message: CustomToastMessageType, option?: ToastOptions) =>
                showCustomToast(message, "warning", option),
            [showCustomToast]
        )
    };
};

export default useToast;
