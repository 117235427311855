import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ENDPOINTS } from "../constants/index.ts";
import { updateAxiosBaseUrl } from "../helpers";
import { RootState } from "../store";
import { storeNotifications } from "../store/notificationSlice.ts";
import { ApiService } from "../types";

const useFetchNotifications = () => {
    const { isLogin } = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isLogin) {
            updateAxiosBaseUrl(ApiService.transfer);
            window.axios.get(ENDPOINTS.NOTIFICATIONS).then(response => {
                dispatch(storeNotifications(response.data.data || []));
            });
        }
    }, [dispatch, isLogin]);
};

export default useFetchNotifications;
