import Input, { InputPropsType } from "@ui/Input.tsx";

import { DEFAULT_COUNTRY_INDICATIVE } from "../constants";
import { cn } from "../libs/style.ts";

import InputIndicativeDropdown from "./InputIndicativeDropdown.tsx";

interface Props extends InputPropsType {
    label?: string;
    indicative?: string;
    onIndicativeChange?: (value: string) => void;
    disabledIndicative?: boolean;
}

const PhoneInput = (props: Props) => {
    const {
        required = true,
        disabledIndicative = false,
        error,
        onIndicativeChange,
        indicative,
        className,
        inputMode = "numeric",
        ...rest
    } = props;

    return (
        <div className={cn("w-full", className)}>
            <div className="relative">
                <Input
                    required={required}
                    type="number"
                    inputClassName={cn({
                        "pl-24": !disabledIndicative,
                        "pl-14": disabledIndicative
                    })}
                    error={error}
                    min={0}
                    inputMode={inputMode}
                    {...rest}
                />

                <InputIndicativeDropdown
                    value={indicative || DEFAULT_COUNTRY_INDICATIVE}
                    onChange={value => {
                        if (onIndicativeChange) {
                            onIndicativeChange(value);
                        }
                    }}
                    disabled={disabledIndicative}
                />
            </div>
        </div>
    );
};

export default PhoneInput;
