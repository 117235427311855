import FaUserCircleIcon from "@icon/FaUserCircleIcon.tsx";

import useAuth from "../../../hooks/useAuth.ts";

const AsideProfile = () => {
    const { user } = useAuth();

    return (
        <>
            <div className="h-12 w-12 bg-gray-200 rounded-full mx-auto mb-2">
                <FaUserCircleIcon className="h-12 w-12 text-gray-400" />
            </div>

            <p className="text-gray-500 text-sm text-center">
                {user?.full_name}
            </p>
        </>
    );
};

export default AsideProfile;
