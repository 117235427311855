import { Menu } from "@headlessui/react";
import HiChevronDownIcon from "@icon/HiChevronDownIcon.tsx";
import PaymentMethods, {
    PaymentMethodType
} from "@ui/payment-method-select/PaymentMethods.tsx";
import { useCallback, useEffect, useState } from "react";

import useManageWalletChannels from "../../../hooks/useManageWalletChannels.ts";
import { cn } from "../../../libs/style.ts";
import { DropdownTransition } from "../../transitions";

interface StateType {
    pay_in: PaymentMethodType[];
    pay_out: PaymentMethodType[];
}

interface ValuesType {
    pay_in: number[];
    pay_out: number[];
}

interface Props {
    onChange?: (values: ValuesType) => void;
    menuWithAuto?: boolean;
}

const PaymentMethodSelect = (props: Props) => {
    const { payinWallets, payoutWallets } = useManageWalletChannels();
    const [state, setState] = useState<StateType>({
        pay_in: [],
        pay_out: []
    });

    useEffect(() => {
        setState({
            pay_in: payinWallets.map(item => ({ ...item, selected: false })),
            pay_out: payoutWallets.map(item => ({ ...item, selected: false }))
        });
    }, [payinWallets, payoutWallets]);

    const getSelectedPaymentMethod = useCallback(
        (key: "pay_in" | "pay_out", data: StateType) => {
            return data[key].filter(item => item.selected).map(item => item.id);
        },
        []
    );

    const handleCheck = useCallback(
        (
            value: boolean,
            element: PaymentMethodType,
            key: "pay_out" | "pay_in"
        ) => {
            const newState = {
                ...state,
                [key]: state[key].map(item => ({
                    ...item,
                    selected: item.id === element.id ? value : item.selected
                }))
            };
            if (props.onChange) {
                props.onChange({
                    pay_in: getSelectedPaymentMethod("pay_in", newState),
                    pay_out: getSelectedPaymentMethod("pay_out", newState)
                });
            }
            setState(newState);
        },
        [getSelectedPaymentMethod, props, state]
    );

    return (
        <Menu as="div" className="relative">
            {({ open }) => (
                <>
                    <Menu.Button
                        className={cn({
                            "w-full py-2.5 px-4 border border-gray-300": true,
                            "flex items-center text-sm mt-2": true,
                            "rounded-md bg-white text-gray-400": true,
                            "border-blue-600 ring ring-blue-600/20": open
                        })}
                    >
                        Moyen de paiement
                    </Menu.Button>

                    <HiChevronDownIcon
                        className={cn({
                            "text-gray-400 hover:text-gray-600": true,
                            "transition duration-200 -rotate-90": true,
                            "absolute top-3 right-2 mt-2": true,
                            "rotate-0": open
                        })}
                    />

                    <DropdownTransition>
                        <Menu.Items
                            data-testid="payment-method-container"
                            as="div"
                            className={cn({
                                "bg-white shadow w-full rounded-md": true,
                                "flex items-start border": true,
                                "absolute z-10 px-2 py-1 mt-1": true,
                                "w-[450px] -left-[70%]": props.menuWithAuto
                            })}
                        >
                            <PaymentMethods
                                title="Emission"
                                list={state.pay_in}
                                onCheckElement={(value, element) =>
                                    handleCheck(value, element, "pay_in")
                                }
                            />
                            <PaymentMethods
                                title="Réception"
                                list={state.pay_out}
                                onCheckElement={(value, element) =>
                                    handleCheck(value, element, "pay_out")
                                }
                            />
                        </Menu.Items>
                    </DropdownTransition>
                </>
            )}
        </Menu>
    );
};

export default PaymentMethodSelect;
