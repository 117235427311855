import Switcher from "@ui/Switcher.tsx";

interface Props {
    toggle: boolean;
    setToggle: (value: boolean) => void;
}

const NotificationHeader = (props: Props) => {
    const { toggle, setToggle } = props;
    return (
        <div className="p-5 flex items-center justify-between">
            <h3 className="text-lg font-semibold">Notifications</h3>
            <div className="flex items-center space-x-4">
                <p className="text-xs">Notifications non lues</p>

                <Switcher enabled={toggle} onSwitch={setToggle} />
            </div>
        </div>
    );
};

export default NotificationHeader;
