import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import ENDPOINTS from "../constants/endpoints.ts";
import { updateAxiosBaseUrl } from "../helpers";
import useToast from "../libs/useToast.tsx";
import { RootState } from "../store";
import { addChannels, ChannelCountryType } from "../store/channelsSlice.ts";
import { ApiService } from "../types";

const useFetchPaymentMethod = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { list: channelCountries } = useSelector(
        (state: RootState) => state.channels
    );
    const { customSuccess: customSuccessToast, loading: loadingToast } =
        useToast();

    const fetchPaymentMethod = useCallback(
        (showToast = false) => {
            updateAxiosBaseUrl(ApiService.transfer);
            let toastId = "";
            if (showToast) {
                toastId = loadingToast(t("Mise à jours des channels..."));
            }
            window.axios.get(ENDPOINTS.CHANNELS).then(response => {
                showToast &&
                    customSuccessToast(
                        { message: t("Channels mise à jours avec succès") },
                        {
                            id: toastId
                        }
                    );
                const data = response.data.data;
                const payInData = data.pay_in.map(
                    (item: ChannelCountryType) => {
                        const formatItem = { ...item };
                        delete formatItem.pay_in_channels;

                        return {
                            ...formatItem,
                            channels: item.pay_in_channels || []
                        };
                    }
                );

                const payOutData = data.pay_out.map(
                    (item: ChannelCountryType) => {
                        const formatItem = { ...item };
                        delete formatItem.pay_out_channels;

                        return {
                            ...formatItem,
                            channels: item.pay_out_channels || []
                        };
                    }
                );

                dispatch(
                    addChannels({
                        payIn: payInData,
                        payOut: payOutData
                    })
                );
            });
        },
        [customSuccessToast, dispatch, loadingToast, t]
    );

    useEffect(() => {
        if (!(channelCountries?.payIn && channelCountries.payOut)) {
            fetchPaymentMethod();
        }
    }, [
        channelCountries?.payIn,
        channelCountries.payOut,
        dispatch,
        fetchPaymentMethod
    ]);

    return fetchPaymentMethod;
};

export default useFetchPaymentMethod;
