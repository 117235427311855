import dayjs from "dayjs";

import config, { ClientConfig } from "../config";
import {
    FILE_NAME_FORMAT,
    INPUT_DATE_FORMAT,
    STORAGE,
    STORAGE_KEYS,
    PAGES,
    ROLES,
    SIMPLE_DATE_FORMAT
} from "../constants";
import { formatDate, yesterday } from "../libs/date.ts";
import {
    ApiService,
    SessionType,
    TokenDataType,
    TRANSACTION_STATUS,
    UserType
} from "../types";

export function session(driver: SessionType) {
    if (driver === "localStorage") return localStorage;
    if (driver === "sessionStorage") return sessionStorage;
    return localStorage;
}

export function scrollToTop() {
    // Check if the user is already at the top of the page
    if (window.scrollY === 0) {
        return;
    }

    // Scroll to the top of the page
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
}

export function auth() {
    const authValue = storage().getItem(STORAGE.AUTH);
    const allData = authValue ? JSON.parse(authValue) : null;
    let sessionHaseExpire = false;

    if (allData?.tokenData) {
        const expiresAt = allData?.tokenData.expires_at;
        sessionHaseExpire =
            window.dayjs(expiresAt).isValid() &&
            window.dayjs().isAfter(expiresAt);
    }

    return allData
        ? {
              isLogin: !!allData?.isLogin && !sessionHaseExpire,
              user: allData?.user as UserType,
              tokenData: allData?.tokenData as TokenDataType
          }
        : null;
}

export function userPermissions() {
    const authData = auth();
    if (authData?.user?.permissions) {
        return authData?.user?.permissions as string[];
    }

    return [];
}

export function userRoles() {
    const authData = auth();
    const roles = authData?.user?.roles;
    if (roles) {
        return roles.map(item => item.name);
    }

    return [];
}

export function userHavePermissions(permissions: string[]) {
    if (permissions.length === 0) return true;
    let check = true;
    const allUserPermissions = userPermissions();
    for (let i = 0; i < permissions.length; i++) {
        check = check && allUserPermissions.includes(permissions[i]);
        if (!check) break;
    }

    return check;
}

export function userHaveRoles(roles: string[]) {
    if (roles.length === 0) return true;
    let check = true;
    const allUserRoles = userRoles();
    for (let i = 0; i < roles.length; i++) {
        check = check && allUserRoles.includes(roles[i]);
        if (!check) break;
    }
    return check;
}

export function userHaveOneRoles(roles: string[]) {
    if (roles.length === 0) return true;
    let check = false;
    const allUserRoles = userRoles();
    for (let i = 0; i < roles.length; i++) {
        check = check || allUserRoles.includes(roles[i]);
        if (check) break;
    }
    return check;
}

export function storage() {
    return session(config.sessionDriver);
}

export function getConfig(): ClientConfig | null {
    const storageValue = storage().getItem(STORAGE_KEYS.CLIENT_CONFIG);
    return storageValue ? JSON.parse(storageValue) : null;
}

export function getBaseUrl(api: ApiService) {
    const services = config.backServicesHost;

    if (Object.keys(ApiService).includes(api)) {
        return services[api].host + services[api].basePath + "/";
    }

    return "";
}

export function updateAxiosBaseUrl(api: ApiService) {
    const baseUrl = getBaseUrl(api);

    if (baseUrl) {
        window.axios.defaults.baseURL = baseUrl;
    }
}

export function generateArray(n: number) {
    return Array.from({ length: n }, (_, index) => index);
}

export function generateFileName(extension?: string) {
    const fileName = window.dayjs().format(FILE_NAME_FORMAT);

    if (extension) return `${fileName}.${extension}`;

    return fileName;
}

export function downloadFile(fileUrl: string, blank = false) {
    const link = document.createElement("a");
    link.href = fileUrl;
    if (blank) link.target = "_blank";
    link.click();
    return link;
}

export function capitalizeFirstCharacter(str: string) {
    if (str.length === 0) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getFileExtension(fileName: string): string {
    const dotIndex = fileName.lastIndexOf(".");
    if (dotIndex === -1) {
        return "";
    }
    return fileName.substring(dotIndex + 1);
}

export function formatYesterdayForInput() {
    return formatDate(yesterday(), INPUT_DATE_FORMAT);
}

export function currentDateForInput() {
    return formatDate(dayjs(), INPUT_DATE_FORMAT);
}

export function getUrlAfterLogin() {
    if (userHaveRoles([ROLES.SME])) {
        return PAGES.TRANSFER_SEARCH;
    } else if (userHaveRoles([ROLES.FIN_OPS])) {
        return PAGES.TRANSFERS;
    } else if (userHaveRoles([ROLES.RISK])) {
        return PAGES.RISK;
    } else if (userHaveRoles([ROLES.SUPER_ADMIN])) {
        return PAGES.ACCOUNTS;
    }

    return "/";
}

export function formatDateToView(
    date: string | null | undefined,
    format = SIMPLE_DATE_FORMAT,
    emptyValue = "---"
) {
    return date ? formatDate(date, format) : emptyValue;
}

export function imageUrlToBase64(
    url: string,
    callback: (base64String: string) => void
) {
    const img = new Image();
    img.crossOrigin = "Anonymous";

    const splitParts = url.split(".");
    const pictureExtension = splitParts[splitParts.length - 1].toLowerCase();

    img.onload = function () {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        const context = canvas.getContext("2d");
        context?.drawImage(img, 0, 0, img.width, img.height);

        const base64String = canvas
            .toDataURL(`image/${pictureExtension}`)
            .split(",")[1];

        callback(base64String);
    };

    img.src = url;
}

export function formatStatusAccount(status: string) {
    switch (status) {
        case "activated":
            return "Actif";
        case "suspended":
            return "Suspendu";
        case "deleted":
            return "Supprimé";
        default:
            return "---";
    }
}

export function formatStatusKyc(status: string) {
    switch (status) {
        case "rejected":
            return "Rejeté";
        case "validated":
            return "Validé";
        case "to_validate":
            return "A valider";
        case "to_review":
            return "A revoir";
        case "submit_review":
            return "A vérifier";
        default:
            return "---";
    }
}

export function refreshClientConfig() {
    const storeConfig = getConfig();
    const clientConfig = { ...config };

    if (storeConfig) {
        clientConfig.locale = storeConfig.locale;
        clientConfig.fakerLocale = storeConfig.fakerLocale;
    }

    storage().setItem(STORAGE.CLIENT_CONFIG, JSON.stringify(clientConfig));
}

export function formatAmount(value: string | undefined) {
    if (!value) return "---";
    return parseInt(value);
}

export function formatPayStatus(status: string) {
    switch (status) {
        case TRANSACTION_STATUS.SUCCESS:
            return "Succès";
        case TRANSACTION_STATUS.FAILED:
            return "Echec";
        case TRANSACTION_STATUS.PENDING:
            return "En attente";
        default:
            return "---";
    }
}

export const generatePagination = (currentPage: number, totalPages: number) => {
    // If the total number of pages is 7 or less,
    // display all pages without any ellipsis
    if (totalPages <= 12) {
        return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    // If the current page is among the first 3 pages,
    // Show the first 3, an ellipsis, and thet last 2 pages.
    if (currentPage <= 9) {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, "...", totalPages];
    }

    // If the current page is among the last 3 pages,
    // Show the first 2, an ellipsis, and the last 3 pages.
    if (currentPage >= totalPages - 8) {
        return [
            1,
            "...",
            totalPages - 9,
            totalPages - 8,
            totalPages - 7,
            totalPages - 6,
            totalPages - 5,
            totalPages - 4,
            totalPages - 3,
            totalPages - 2,
            totalPages - 1,
            totalPages
        ];
    }

    // If the current page is somewhere in the middle,
    // Show the first page, an ellipsis, the current page and its neighbors,
    // another ellipsis, and the last page.
    return [
        1,
        "...",
        currentPage - 4,
        currentPage - 3,
        currentPage - 2,
        currentPage - 1,
        currentPage,
        currentPage + 1,
        currentPage + 2,
        currentPage + 3,
        currentPage + 4,
        "...",
        totalPages
    ];
};

export const clearActivityTimer = (clearStorage = false) => {
    const lastActivityTimer = storage().getItem(STORAGE.ACTIVITY);

    if (lastActivityTimer) {
        clearTimeout(parseInt(lastActivityTimer));

        if (clearStorage) {
            storage().removeItem(STORAGE.ACTIVITY);
        }
    }
};
