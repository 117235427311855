import { useCallback, useEffect, useState } from "react";

import { ENDPOINTS, RESPONSE_STATUS } from "../constants";
import { updateAxiosBaseUrl } from "../helpers";
import useToast from "../libs/useToast.tsx";
import {
    ApiService,
    PAY_IN_PAY_OUT_STATUS,
    TRANSACTION_STATUS,
    TransferType
} from "../types";

const CONFLIT_KEY = {
    TRANSFER_NOT_FOUND: "transfer_not_refund",
    CHANNEL_NOT_AVAILABLE: "transfert_pay_in_channel_not_available_to_refund",
    REFUND_FAILED: "init_refund_failed",
    INCORRECT_REFUND_STATUS: "transfert_status_not_ok"
};

const useManageTransfer = (transferId?: string) => {
    const [transfer, setTransfer] = useState<TransferType | null>(null);
    const [fetchingTransfer, setFetchingTransfer] = useState<boolean>(false);
    const [refundLoading, setRefundLoading] = useState(false);

    const { customSuccess: successToast, customError: errorToast } = useToast();

    useEffect(() => {
        if (transferId) {
            setFetchingTransfer(true);
            updateAxiosBaseUrl(ApiService.moc);
            window.axios
                .get(ENDPOINTS.TRANSFER_DETAILS, {
                    params: { identifier: transferId }
                })
                .then(response => {
                    setTransfer(response.data.data);
                })
                .finally(() => setFetchingTransfer(false));
        }
    }, [transferId]);

    const handleRefund = useCallback(
        (transferId?: string, onSuccess?: () => void) => {
            const id = transferId || transfer?.id.toString();
            if (id) {
                setRefundLoading(true);
                updateAxiosBaseUrl(ApiService.moc);
                window.axios
                    .post(ENDPOINTS.REFUND_TRANSFER.replace(":id", id))
                    .then(response => {
                        successToast({
                            message:
                                "Le remboursement à été effectuer avec succès"
                        });
                        setTransfer(response.data.data);
                        onSuccess && onSuccess();
                    })
                    .catch(errorResponse => {
                        const status = errorResponse.response.status;
                        const errorKey =
                            errorResponse.response.data?.output?.key || "";
                        const failReason =
                            errorResponse.response.data?.output
                                ?.refund_failed_reason || "";
                        if (status === RESPONSE_STATUS.CONFLICT) {
                            if (errorKey === CONFLIT_KEY.TRANSFER_NOT_FOUND) {
                                errorToast({
                                    message: "Remboursement non effectué"
                                });
                            }

                            if (
                                errorKey === CONFLIT_KEY.CHANNEL_NOT_AVAILABLE
                            ) {
                                errorToast(
                                    {
                                        message:
                                            "Le moyen de paiement en émission de ce transfert n'est pas disponible en sortie."
                                    },
                                    { duration: 10000 }
                                );
                            }

                            if (
                                errorKey === CONFLIT_KEY.INCORRECT_REFUND_STATUS
                            ) {
                                errorToast(
                                    {
                                        message: "Echec du remboursement."
                                    },
                                    { duration: 10000 }
                                );
                            }

                            if (errorKey === CONFLIT_KEY.REFUND_FAILED) {
                                errorToast(
                                    {
                                        message:
                                            failReason ||
                                            "Echec du remboursement."
                                    },
                                    { duration: 10000 }
                                );
                            }
                        } else {
                            errorToast({ message: "Echec du remboursement" });
                        }
                    })
                    .finally(() => setRefundLoading(false));
            }
        },
        [errorToast, successToast, transfer]
    );

    const canRefundTransfer = useCallback((transfer: TransferType) => {
        if (!transfer) return false;

        if (transfer.status === TRANSACTION_STATUS.REFUND) return false;

        return (
            transfer.status_pay_in === PAY_IN_PAY_OUT_STATUS.SUCCESS &&
            transfer.status_pay_out === PAY_IN_PAY_OUT_STATUS.FAILED
        );
    }, []);

    return {
        canRefundTransfer,
        transfer,
        setTransfer,
        fetchingTransfer,
        refundLoading,
        handleRefund
    };
};

export default useManageTransfer;
