import Badge from "@ui/Badge.tsx";
import { useCallback, useMemo } from "react";

import { ENDPOINTS, PERMISSIONS } from "../../../constants";
import {
    formatDateToView,
    updateAxiosBaseUrl,
    userHavePermissions
} from "../../../helpers";
import useToast from "../../../libs/useToast.tsx";
import { ApiService, CardType, KycType } from "../../../types";

interface Props {
    kyc: KycType;
    onSuccess: (card: CardType) => void;
}

const CardList = (props: Props) => {
    const { kyc, onSuccess } = props;

    const toast = useToast();

    const cardData = useMemo(() => {
        return {
            active: kyc.transfer_cards.filter(item => !item.deleted_at),
            remove: kyc.transfer_cards.filter(item => item.deleted_at)
        };
    }, [kyc.transfer_cards]);

    const handleBlockCard = useCallback(
        (card: CardType) => {
            updateAxiosBaseUrl(ApiService.auth);
            const endpoint = ENDPOINTS.BLOCK_CARD.replace(
                "cardId:",
                card.id
            ).replace("status:", card.activated_at ? "disable" : "enable");

            let toastId = "";

            toastId = toast.loading(
                card.activated_at ? "Désactivation..." : "Activation..."
            );

            window.axios
                .patch(endpoint)
                .then(response => {
                    onSuccess && onSuccess(response.data.data);
                    toast.customSuccess(
                        {
                            message: `Carte ${
                                card.activated_at ? "désactivée" : "activée"
                            } avec cussès`
                        },
                        { id: toastId }
                    );
                })
                .catch(() => {
                    toast.customError(
                        {
                            message: `Echec ${
                                card.activated_at
                                    ? "de désactivation"
                                    : "d'activation"
                            } de la carte`
                        },
                        { id: toastId }
                    );
                });
        },
        [onSuccess, toast]
    );

    return (
        <div className="ml-16">
            <h3 className="text-lg font-semibold mb-4">Cartes actifs</h3>

            <div className="ml-14 mb-4">
                {cardData.active.length ? (
                    <table className="w-full z-0 table-auto border-collapse text-sm">
                        <thead className="text-gray-500">
                            <tr>
                                <th className="px-4 py-5 font-medium text-left">
                                    Cartes
                                </th>
                                <th className="px-4 py-5 font-medium text-left">
                                    Date d{"'"}ajout
                                </th>
                                <th className="px-4 py-5 font-medium text-left">
                                    Nom sur la carte
                                </th>
                                <th className="px-4 py-5 font-medium text-left">
                                    Status
                                </th>

                                {userHavePermissions([
                                    PERMISSIONS.ENABLE_DISABLE_TRANSFER_CARD
                                ]) && (
                                    <th className="px-4 py-5 font-medium text-left">
                                        Action
                                    </th>
                                )}
                            </tr>
                        </thead>

                        <tbody className="text-gray-500">
                            {cardData.active.map((item, index) => (
                                <tr key={index}>
                                    <td className="px-4 py-1.5">
                                        {item.card_number}
                                    </td>
                                    <td className="px-4 py-1.5">
                                        {item.created_at
                                            ? formatDateToView(item.created_at)
                                            : "---"}
                                    </td>
                                    <td className="px-4 py-1.5">{item.name}</td>
                                    <td className="px-4 py-1.5">
                                        <Badge
                                            color={
                                                item.activated_at
                                                    ? "green"
                                                    : "red"
                                            }
                                        >
                                            {item.activated_at
                                                ? "Actif"
                                                : "Bloquée"}
                                        </Badge>
                                    </td>

                                    {userHavePermissions([
                                        PERMISSIONS.ENABLE_DISABLE_TRANSFER_CARD
                                    ]) && (
                                        <td className="px-4 py-1.5">
                                            <button
                                                className="text-red-500 underline"
                                                onClick={() =>
                                                    handleBlockCard(item)
                                                }
                                            >
                                                {item.activated_at
                                                    ? "Désactivée"
                                                    : "Activée"}
                                            </button>
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p className="text-center py-10">Aucune donnée trouvée</p>
                )}
            </div>

            <h3 className="text-lg font-semibold mb-4">
                Historique des cartes supprimées
            </h3>

            <div className="ml-14">
                {cardData.remove.length ? (
                    <table className="w-full z-0 table-auto border-collapse text-sm">
                        <thead className="text-gray-500">
                            <tr>
                                <th className="px-4 py-5 font-medium text-left">
                                    Cartes
                                </th>
                                <th className="px-4 py-5 font-medium text-left">
                                    Date d{"'"}ajout
                                </th>
                                <th className="px-4 py-5 font-medium text-left">
                                    Date de supression
                                </th>
                                <th className="px-4 py-5 font-medium text-left">
                                    Nom sur la carte
                                </th>

                                {userHavePermissions([
                                    PERMISSIONS.ENABLE_DISABLE_TRANSFER_CARD
                                ]) && (
                                    <th className="px-4 py-5 font-medium text-left">
                                        Action
                                    </th>
                                )}
                            </tr>
                        </thead>

                        <tbody className="text-gray-500">
                            {cardData.remove.map((item, index) => (
                                <tr key={index}>
                                    <td className="px-4 py-1.5">
                                        {item.card_number}
                                    </td>
                                    <td className="px-4 py-1.5">
                                        {item.created_at
                                            ? formatDateToView(item.created_at)
                                            : "---"}
                                    </td>
                                    <td className="px-4 py-1.5">
                                        {item.deleted_at
                                            ? formatDateToView(item.deleted_at)
                                            : "---"}
                                    </td>
                                    <td className="px-4 py-1.5">{item.name}</td>

                                    {userHavePermissions([
                                        PERMISSIONS.ENABLE_DISABLE_TRANSFER_CARD
                                    ]) && (
                                        <td className="px-4 py-1.5">
                                            <button
                                                className="text-red-500 underline"
                                                onClick={() =>
                                                    handleBlockCard(item)
                                                }
                                            >
                                                {item.activated_at
                                                    ? "Désactivée"
                                                    : "Activée"}
                                            </button>
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p className="text-center py-10">Aucune donnée trouvée</p>
                )}
            </div>
        </div>
    );
};

export default CardList;
