import { ReactNode, useContext, useMemo } from "react";

import TabContext, { TabStoreType } from "../../../context/TabContext.ts";

interface Props {
    as?: string;
    children: ReactNode;
    className?: string;
    index: number;
}

const TabItem = (props: Props) => {
    const { as = "div", children, className, index } = props;

    const value = useContext(TabContext) as TabStoreType;

    const Tag = useMemo(() => as as keyof JSX.IntrinsicElements, [as]);

    if (index !== value?.index) return null;

    return <Tag className={className}>{children}</Tag>;
};

export default TabItem;
