import { Transition } from "@headlessui/react";
import HeClipboardIcon from "@icon/HeClipboardIcon.tsx";
import {
    ButtonHTMLAttributes,
    Fragment,
    MouseEvent,
    ReactNode,
    useCallback,
    useState
} from "react";
import { useTranslation } from "react-i18next";

import useCopyToClipboard from "../hooks/useCopyToClipboard.tsx";

const ALERT_TIMEOUT = 3000;

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    text: string;
    copyIcon?: ReactNode;
    showText?: boolean;
}

const CopyClipboardButton = (props: Props) => {
    const { text, copyIcon, showText = true, onClick } = props;
    const { t } = useTranslation();
    const [copied, setCopied] = useState(false);
    const { copy } = useCopyToClipboard();

    const handleClick = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            onClick && onClick(e);
            setCopied(true);
            copy(text);
            setTimeout(() => {
                setCopied(false);
            }, ALERT_TIMEOUT);
        },
        [copy, onClick, text]
    );

    return (
        <div className="space-x-2 flex items-center">
            {showText && <p>{text}</p>}

            <button
                type="button"
                onClick={handleClick}
                className="focus:outline-none inline-block relative"
            >
                <span>
                    {copyIcon ? (
                        copyIcon
                    ) : (
                        <HeClipboardIcon className="text-gray-500" />
                    )}

                    <Transition
                        as={Fragment}
                        show={copied}
                        enter="transform transition"
                        enterFrom="opacity-0 scale-50"
                        enterTo="opacity-100 scale-100"
                        leave="transform duration-200 transition ease-in-out"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-50"
                    >
                        <div className="absolute z-10 right-6 -top-1 bg-black text-white border shadow-sm p-2 rounded-md text-xs">
                            {t("Copié")}
                        </div>
                    </Transition>
                </span>
            </button>
        </div>
    );
};

export default CopyClipboardButton;
