import Input from "@ui/Input.tsx";
import Loading from "@ui/Loading.tsx";
import Select, { SelectValueType } from "@ui/select";
import { useCallback, useEffect, useState } from "react";

import HeaderContainer from "../../components/HeaderContainer.tsx";
import { AppLayout } from "../../components/layouts";
import {
    NotificationItem,
    NotificationType
} from "../../components/notifications";
import { ENDPOINTS } from "../../constants";
import { updateAxiosBaseUrl } from "../../helpers";
import { formatDate } from "../../libs/date.ts";
import { ApiService } from "../../types";

const TYPES_NOTIFICATIONS = [
    {
        label: "Validation",
        value: "validation_kyc"
    },
    {
        label: "A revoir",
        value: "correct_kyc"
    }
];

const STATUS_NOTIFICATIONS = [
    {
        label: "Notification lue",
        value: "READ"
    },
    {
        label: "Notification non lue",
        value: "UNREAD"
    }
];

const Notification = () => {
    const [createdAt, setCreatedAt] = useState("");
    const [notificationType, setNotificationType] =
        useState<SelectValueType>(null);
    const [notificationStatus, setNotificationStatus] =
        useState<SelectValueType>(null);
    const [notifications, setNotifications] = useState<NotificationType[]>([]);
    const [loading, setLoading] = useState(false);

    const refreshNotification = useCallback(() => {
        updateAxiosBaseUrl(ApiService.moc);
        setLoading(true);
        window.axios
            .get(ENDPOINTS.NOTIFICATIONS, {
                params: {
                    created_at: createdAt,
                    status: notificationStatus?.value || "",
                    type: notificationType?.value || "",
                    search: null
                }
            })
            .then(response => {
                setNotifications(response.data.data || []);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [createdAt, notificationStatus?.value, notificationType?.value]);

    useEffect(() => {
        refreshNotification();
    }, [refreshNotification]);

    return (
        <AppLayout className="space-y-5">
            <HeaderContainer>
                <h4>Filtre</h4>

                <div className="grid grid-cols-5 gap-5">
                    <Input
                        type="date"
                        max={formatDate(new Date(), "YYYY-MM-DD")}
                        value={createdAt}
                        onChange={e => setCreatedAt(e.target.value)}
                    />

                    <Select
                        isSearchable={false}
                        placeholder="Type notification"
                        value={notificationType}
                        onChange={value => setNotificationType(value)}
                        options={TYPES_NOTIFICATIONS}
                    />

                    <Select
                        isSearchable={false}
                        placeholder="Notification"
                        value={notificationStatus}
                        onChange={value => setNotificationStatus(value)}
                        options={STATUS_NOTIFICATIONS}
                    />
                </div>
            </HeaderContainer>

            <div className="bg-white p-4 border rounded-md">
                <h1 className="text-lg font-medium mb-7">Notifications</h1>

                <div className="relative">
                    {loading && (
                        <div className="absolute w-full h-32 flex items-center justify-center">
                            <Loading className="h-8 w-8 text-blue-600" />
                        </div>
                    )}

                    {notifications.length > 0 ? (
                        <div className="overflow-y-auto divide-y">
                            {notifications.map((item, index) => (
                                <NotificationItem
                                    onReadSuccess={() => refreshNotification()}
                                    key={index}
                                    value={item}
                                />
                            ))}
                        </div>
                    ) : (
                        <div className="text-center text-sm text-gray-500 py-12">
                            Aucun résultat trouvé
                        </div>
                    )}
                </div>
            </div>
        </AppLayout>
    );
};

export default Notification;
