import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./authSlice.ts";
import channelReducer from "./channelsSlice.ts";
import configReducer from "./configSlice.ts";
import counterReducer from "./counterSlice.ts";
import countriesReducer from "./countriesSlice.ts";
import kycReducer from "./kycSlice.ts";
import notificationReducer from "./notificationSlice.ts";

export const store = configureStore({
    reducer: {
        counter: counterReducer,
        auth: authReducer,
        channels: channelReducer,
        config: configReducer,
        notification: notificationReducer,
        countries: countriesReducer,
        kyc: kycReducer
    }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
