import LuFlipHorizIcon from "@icon/LuFlipHorizIcon.tsx";
import TbDownloadIcon from "@icon/TbDownloadIcon.tsx";
import Button from "@ui/Button";
import Loading from "@ui/Loading.tsx";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

import { ENDPOINTS, PAGES, RESPONSE_STATUS } from "../../constants";
import { updateAxiosBaseUrl } from "../../helpers";
import { cn } from "../../libs/style.ts";
import useToast from "../../libs/useToast.tsx";
import { ApiService, KycType, PictureType } from "../../types";

const PicturePreview = () => {
    const [kyc, setKyc] = useState<KycType | null>(null);
    const [loading, setLoading] = useState(false);
    const [pictureBase64, setPictureBase64] = useState("");
    const [flip, setFlip] = useState(false);
    const pictureRef = useRef<HTMLImageElement | null>(null);
    const [srcValue, setSrcValue] = useState("");

    const navigate = useNavigate();
    const { id: kycId, key } = useParams();

    const { error: errorToast } = useToast();

    const { customError } = useToast();

    const getSrcValue = useCallback(
        (kyc: KycType) => {
            if (key === "kyc-user-photo") return kyc?.user.photo || "";

            if (key === "kyc-user-selfie-photo")
                return kyc?.user.selfie_photo || "";

            if (!kyc?.comments) return "";

            const parseData = JSON.parse(kyc?.comments || "");

            if (Object.keys(parseData).length === 0) {
                return "";
            }

            if (
                key === "commentData-photo-value" &&
                !!parseData?.photo &&
                !!parseData?.photo?.value
            ) {
                return parseData?.photo?.value;
            }

            if (
                key === "commentData-selfie_photo-value" &&
                !!parseData?.selfie_photo &&
                !!parseData?.selfie_photo?.value
            ) {
                return parseData?.selfie_photo?.value;
            }

            return "";
        },
        [key]
    );

    useEffect(() => {
        if (kycId) {
            updateAxiosBaseUrl(ApiService.moc);
            setLoading(true);
            window.axios
                .get(ENDPOINTS.DETAILS_ACCOUNT.replace(":id", kycId))
                .then(response => {
                    const kycDetails = response.data.data as KycType;
                    setKyc(kycDetails || null);

                    const src = getSrcValue(kycDetails);

                    if (src) {
                        updateAxiosBaseUrl(ApiService.auth);
                        window.axios
                            .post(ENDPOINTS.PICTURE_BASE64, {
                                photo: src || ""
                            })
                            .then(response => {
                                const picture =
                                    response.data.data?.photo_base64 || "";

                                setPictureBase64(picture);
                                setSrcValue(src);
                            })
                            .catch(() => {
                                customError({
                                    message: "Echec du chargement de l'image"
                                });
                            })
                            .finally(() => setLoading(false));
                    } else {
                        setLoading(false);
                    }
                })
                .catch(error => {
                    if (error.response.status === RESPONSE_STATUS.NOT_FOUND) {
                        navigate(PAGES.NOT_FOUND);
                    } else {
                        errorToast("Echec de récupération du KYC");
                    }
                    setLoading(false);
                });
        }
    }, [customError, errorToast, getSrcValue, kycId, navigate]);

    const reversePicture = useCallback(() => {
        setFlip(prevState => !prevState);
    }, []);

    const pictureName = useMemo(() => {
        if (pictureBase64.includes(PictureType.jpg)) {
            return `${kyc?.user?.full_name.replace(" ", "_") || ""}.jpg`;
        }

        if (pictureBase64.includes(PictureType.png)) {
            return `${kyc?.user?.full_name.replace(" ", "_") || ""}.png`;
        }

        return `${kyc?.user?.full_name.replace(" ", "_")}.png`;
    }, [kyc?.user?.full_name, pictureBase64]);

    const downloadPicture = useCallback(() => {
        const link = document.createElement("a");
        link.target = "_blank";
        link.rel = "noreferrer";
        link.download = pictureName;

        if (!flip) {
            link.href = pictureBase64;
            link.click();
        } else {
            const img = new Image();
            img.src = pictureBase64;

            img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                // Définir la taille du canvas égale à celle de l'image
                canvas.width = img.width;
                canvas.height = img.height;

                // Inverser horizontalement
                ctx?.translate(canvas.width, 0);
                ctx?.scale(-1, 1);

                // Dessiner l'image inversée sur le canvas
                ctx?.drawImage(img, 0, 0);

                // Obtenir l'image inversée en base64
                link.href = canvas.toDataURL();
                link.download = `reverse_picture_${link.download}`;
                link.click();
            };
        }
    }, [flip, pictureBase64, pictureName]);

    return (
        <div className="h-screen flex flex-col items-center justify-center">
            {loading ? (
                <div className="flex items-center justify-center h-96 w-1/3 bg-slate-200 border rounded">
                    <Loading className="h-12 w-12" />
                </div>
            ) : (
                <>
                    {kyc && srcValue && (
                        <div className="flex items-center justify-center">
                            <img
                                className={cn({
                                    "object-cover object-center": true,
                                    "h-[550px]": true,
                                    "scale-x-[-1]": flip
                                })}
                                ref={pictureRef}
                                src={srcValue}
                                alt=""
                            />
                        </div>
                    )}
                </>
            )}

            <div className="mt-5 flex items-center justify-center space-x-4">
                <Button
                    withAuto
                    variant="outline"
                    iconPlacement="left"
                    icon={<LuFlipHorizIcon />}
                    onClick={reversePicture}
                    disabled={!pictureBase64}
                >
                    Renverser
                </Button>

                <Button
                    withAuto
                    iconPlacement="left"
                    icon={<TbDownloadIcon />}
                    onClick={downloadPicture}
                    disabled={!pictureBase64 && !srcValue}
                >
                    Télécharger
                </Button>
            </div>
        </div>
    );
};

export default PicturePreview;
