import { ChangeEvent, useCallback, useState } from "react";

const initialCheckData: { check: number[]; uncheck: number[] } = {
    check: [],
    uncheck: []
};

interface OptionType {
    totalData: number;
}

const useCheckboxDatatable = ({ totalData = 0 }: OptionType) => {
    const [checkAll, setCheckAll] = useState(false);
    const [checkData, setCheckData] = useState(initialCheckData);

    const handleCheckAll = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setCheckAll(e.target.checked);
            if (
                e.target.checked &&
                (checkData.check.length || checkData.uncheck.length)
            ) {
                setCheckData(initialCheckData);
            }
        },
        [checkData.check.length, checkData.uncheck.length]
    );

    const handleCheckItem = useCallback(
        (e: ChangeEvent<HTMLInputElement>, itemId: number) => {
            const newCheckData = { ...checkData };
            if (checkAll) {
                newCheckData.uncheck = [...newCheckData.uncheck, itemId];
                newCheckData.check = [];
                setCheckAll(false);
            } else {
                if (e.target.checked) {
                    if (newCheckData.uncheck.length) {
                        newCheckData.uncheck = newCheckData.uncheck.filter(
                            item => item !== itemId
                        );
                        newCheckData.check = [];
                        if (!newCheckData.uncheck.length) setCheckAll(true);
                    } else {
                        newCheckData.check = [...newCheckData.check, itemId];
                        newCheckData.uncheck = [];
                        if (newCheckData.check.length === totalData) {
                            newCheckData.check = [];
                            setCheckAll(true);
                        }
                    }
                } else {
                    if (newCheckData.uncheck.length) {
                        newCheckData.uncheck = [
                            ...newCheckData.uncheck,
                            itemId
                        ];
                        newCheckData.check = [];
                        if (newCheckData.uncheck.length === totalData) {
                            newCheckData.uncheck = [];
                        }
                    } else {
                        newCheckData.check = newCheckData.check.filter(
                            item => item !== itemId
                        );
                        newCheckData.uncheck = [];
                    }
                }
            }

            setCheckData(newCheckData);
        },
        [checkAll, checkData, totalData]
    );

    const printItemCheckboxStatus = useCallback(
        (itemId: number) => {
            if (checkAll) return true;

            if (checkData.check.length) {
                return checkData.check.includes(itemId);
            } else if (checkData.uncheck.length) {
                return !checkData.uncheck.includes(itemId);
            }

            return false;
        },
        [checkAll, checkData.check, checkData.uncheck]
    );

    const resetCheckData = useCallback(() => {
        setCheckData(initialCheckData);
    }, []);

    return {
        checkData,
        checkAll,
        handleCheckAll,
        handleCheckItem,
        printItemCheckboxStatus,
        resetCheckData
    };
};

export default useCheckboxDatatable;
