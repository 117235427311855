import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import config from "./config";
import { STORAGE } from "./constants";
import { storage } from "./helpers";
import en from "./lang/en.json";
import fr from "./lang/fr.json";

const storeConfig = storage().getItem(STORAGE.CLIENT_CONFIG);

const local = storeConfig ? JSON.parse(storeConfig).locale : config.locale;

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: en
        },
        fr: {
            translation: fr
        }
    },
    lng: local,
    fallbackLng: local,

    interpolation: {
        escapeValue: false
    }
});
