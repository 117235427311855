import { jsPDF } from "jspdf";
import { useCallback } from "react";

import { ENDPOINTS } from "../constants";
import {
    formatDateToView,
    formatStatusAccount,
    generateFileName,
    updateAxiosBaseUrl
} from "../helpers";
import useToast from "../libs/useToast.tsx";
import { ApiService, KycType } from "../types";

import useCountries from "./useCountries.tsx";

const useExportKyc = () => {
    const { getCountryByIndicative } = useCountries();
    const { loading: loadingToast, customSuccess } = useToast();

    const downloadKyc = useCallback(
        (kyc: KycType, kycPictureBase64?: string) => {
            const doc = new jsPDF();

            let countryInKyc = "---";
            const statusAccount = formatStatusAccount(kyc.status);
            const statusKycTransfer = formatStatusAccount(
                kyc?.transfer_kyc_status || ""
            );
            const createdAt = formatDateToView(kyc.created_at);
            const suspendedAt = formatDateToView(kyc.suspended_at);
            const deletedAt = formatDateToView(kyc.deleted_at);
            const toReviewAtTransfer = formatDateToView(
                kyc.transfer_kyc_to_review_at
            );
            const validatedAtTransfer = formatDateToView(
                kyc.transfer_kyc_validated_at
            );
            const rejectedAtTransfer = formatDateToView(
                kyc.transfer_kyc_rejected_at
            );
            const partPicture = (kyc?.user.photo || "").split(".");
            const pictureExtension =
                partPicture[partPicture.length - 1]?.toUpperCase() || "";

            const indicative = kyc.user?.phone_with_indicative.split(" ")[0];
            if (indicative) {
                countryInKyc =
                    getCountryByIndicative(indicative)?.name || countryInKyc;
            }

            doc.setTextColor(148, 163, 184);
            doc.setFontSize(10);

            if (kycPictureBase64) {
                doc.addImage(
                    kycPictureBase64,
                    pictureExtension,
                    50,
                    5,
                    120,
                    70
                );
            }

            doc.setTextColor(51, 65, 85);
            doc.setDrawColor(229, 231, 235);

            /* One row */
            doc.text("Nom", 10, 85);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            doc.text(kyc.user.full_name, 85, 85, "right");

            doc.text("Téléphone", 120, 85);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            doc.text(kyc.user.phone_with_indicative, 200, 85, "right");
            /* End One row */

            /* Two row */
            doc.text("Statut Compte", 10, 93);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            doc.text(statusAccount, 85, 93, "right");

            doc.text("ID Compte", 120, 93);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            doc.text(kyc.account_number || "", 200, 93, "right");
            /* End Two row */

            /*Horizontal line*/
            doc.line(10, 98, 85, 98);
            doc.line(120, 98, 200, 98);
            /*Horizontal line*/

            /* Three row */
            doc.text("Date de création du compte", 10, 105);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            doc.text(createdAt, 85, 105, "right");

            doc.text("Date à revoir du KYC Transfert", 120, 105);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            doc.text(toReviewAtTransfer, 200, 105, "right");
            /* End Three row */

            /* Four row */
            doc.text("Statut KYC Transfert", 10, 115);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            doc.text(statusKycTransfer, 85, 115, "right");

            doc.text("Date de suspenssion du compte", 120, 115);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            doc.text(suspendedAt, 200, 115, "right");
            /* End Four row */

            /* Four row */
            doc.text("Date de validation KYC transfert", 10, 125);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            doc.text(validatedAtTransfer, 85, 125, "right");

            doc.text("Pays", 120, 125);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            doc.text(countryInKyc, 200, 125, "right");
            /* End Four row */

            /* Four row */
            doc.text("Date de suppression", 10, 135);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            doc.text(deletedAt, 85, 135, "right");
            /* End Four row */

            /* Four row */
            doc.text("Date de rejet KYC Transfert", 10, 145);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            doc.text(rejectedAtTransfer, 85, 145, "right");
            /* End Four row */

            /*Horizontal line*/
            doc.line(10, 150, 85, 150);
            doc.line(120, 150, 200, 150);
            /*Horizontal line*/

            doc.save(`kyc-${kyc.user.full_name}-${generateFileName()}.pdf`);
        },
        [getCountryByIndicative]
    );

    const exportToPdf = useCallback(
        (kyc: KycType) => {
            updateAxiosBaseUrl(ApiService.auth);
            let toastId = "";
            toastId = loadingToast("Téléchargement en cours...");
            window.axios
                .post(ENDPOINTS.PICTURE_BASE64, {
                    photo: kyc.user?.photo || ""
                })
                .then(response => {
                    const data = response.data.data;
                    downloadKyc(kyc, data?.photo_base64 || undefined);
                    customSuccess(
                        { message: "Succès du téléchargement du KYC" },
                        { id: toastId }
                    );
                })
                .catch(() => {
                    downloadKyc(kyc);
                    customSuccess(
                        { message: "Succès du téléchargement du KYC" },
                        { id: toastId }
                    );
                });
        },
        [customSuccess, downloadKyc, loadingToast]
    );

    return {
        exportToPdf
    };
};

export default useExportKyc;
