import TbDownloadIcon from "@icon/TbDownloadIcon.tsx";
import { useParams } from "react-router";
import { NavLink } from "react-router-dom";

import { PAGES } from "../../../constants";
import useFetchPictureBase64 from "../../../hooks/useFetchPictureBase64.ts";

interface Props {
    src: string;
    type?: "old" | "new";
    label?: string;
    valueKey: string;
}

const KycPicture = (props: Props) => {
    const { src, type, label, valueKey } = props;

    const { id: kycId } = useParams();

    const { pictureBase64 } = useFetchPictureBase64(src, true);

    return (
        <div>
            {type && (
                <p className="text-center text-xs text-gray-500">
                    {type === "old" && "Ancienne valeur"}
                    {type === "new" && "Nouvelle valeur"}
                </p>
            )}

            <p className="text-center text-xs text-gray-500">
                {label ? label : <>Photo pièce d{"'"}identité</>}
            </p>

            <img
                src={src}
                className="max-w-[200px] h-[130px] mx-auto rounded-md my-4 object-cover object-center"
                alt=""
            />

            <div className="flex items-center justify-center space-x-2 text-blue-600 text-sm underline">
                <NavLink
                    to={PAGES.ACCOUNT_PICTURE.replace(
                        ":id",
                        kycId || ""
                    ).replace(":key", valueKey)}
                    target="_blank"
                    rel="noreferrer"
                >
                    image.jpg
                </NavLink>

                {pictureBase64 && (
                    <a
                        href={pictureBase64}
                        target="_blank"
                        rel="noreferrer"
                        download
                    >
                        <TbDownloadIcon />
                    </a>
                )}
            </div>
        </div>
    );
};

export default KycPicture;
