import { flexRender, HeaderGroup } from "@tanstack/react-table";

import { cn } from "../../../libs/style.ts";

interface Props<T> {
    headerGroups: HeaderGroup<T>[];
    paddingLeft?: boolean;
    paddingRight?: boolean;
    thClassName?: string;
}

function TableHead<T>(props: Props<T>) {
    const { headerGroups, thClassName } = props;

    return (
        <thead className="border-b text-gray-500">
            {headerGroups.map(headerGroup => (
                <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header, index) => (
                        <th
                            key={index}
                            className={cn({
                                "px-4 py-5 font-medium text-left": true,
                                [thClassName || ""]: true
                            })}
                        >
                            {header.isPlaceholder
                                ? null
                                : flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                  )}
                        </th>
                    ))}
                </tr>
            ))}
        </thead>
    );
}

export default TableHead;
