import Loading from "@ui/Loading.tsx";

import { fromNow } from "../../libs/date.ts";
import { cn } from "../../libs/style.ts";
import DynamicFromNow from "../DynamicFromNow.tsx";

import { NotificationType } from "./types.ts";
import useNotificationAction from "./useNotificationAction.ts";
import useNotificationMessage from "./useNotificationMessage.ts";

interface Props {
    value: NotificationType;
    onReadSuccess?: () => void;
}

const NotificationItem = (props: Props) => {
    const { value } = props;
    const message = useNotificationMessage(value.data);
    const { action, loading } = useNotificationAction(
        value,
        props.onReadSuccess
    );

    return (
        <li
            className={cn({
                "list-none cursor-pointer text-gray-600 px-4 py-2 lg:px-8 relative":
                    true,
                "bg-gray-100": value.read_at === null
            })}
            onClick={action}
        >
            {loading && (
                <div className="top-0 right-0 absolute h-full w-full flex items-center justify-center bg-black/20">
                    <Loading className="text-blue-600" />
                </div>
            )}

            <p>{message}</p>
            <p className="text-[10px]">
                {value.created_at !== null && (
                    <DynamicFromNow
                        date={value.created_at}
                        formatFunc={fromNow}
                    />
                )}
            </p>
        </li>
    );
};

export default NotificationItem;
