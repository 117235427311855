const ENDPOINTS = {
    CHANNELS: "/channel",
    TRANSFERS: "/transfer",
    TRANSFER_DETAILS: "/transfer/details",
    REQUEST_TO_REFUND_TRANSFER: "/transfer/:id/ask-to-refund",
    REFUND_TRANSFER: "/transfer/:id/refund",
    UPDATE_TRANSFER_STATUS: "/transfer/change-status/:id",
    RETRY_TRANSFER: "/transfer/:id/authorize-retry",
    RESEND_TRANSFER: "/transfer/:id/retry",
    DOWNLOAD_TRANSFER: "/transfer/download",
    FILE_TRANSACTIONS_GENERATE: "/transfer-file/generate",
    FILE_TRANSACTIONS: "/transfer-file",
    FILE_ACCOUNT: "/account-file",
    FILE_ACCOUNT_GENERATE: "/account-file/generate",
    LIST_ACCOUNT: "/account",
    DETAILS_ACCOUNT: "/account/:id",
    SUSPEND_ACCOUNT: "/account/:id/suspend",
    ACTIVE_ACCOUNT: "/account/:id/active",
    REVIEW_ACCOUNT: "/account/:id/to_review",
    REJECT_ACCOUNT: "/account/:id/reject",
    VALIDATE_ACCOUNT: "/account/:id/validate",
    NOTIFICATIONS: "/notification",
    READ_NOTIFICATION: "/notification/mark-as-read-or-as-unread/:id/READ",
    LOGIN: "/login-email",
    FORGOT_PASSWORD: "/send-temporary-token",
    RESET_PASSWORD: "/reset-password",
    LOGOUT: "/logout",
    COUNTRIES: "/country",
    ENABLE_DISABLE_CHANNELS: "/channel/:channelId/enable-disable/:status",
    PICTURE_BASE64: "/photo-base-64",
    BLOCK_CARD: "/transfer-card/cardId:/enable-disable/status:",
    SEARCH_USER_ACCOUNT: "/account/check/:tel",
    GENERATE_USER_CODE: "/account/regenerate-temporary-code/:tel",
    SME_NOTIFY_RISK: "/account/notify-risk/:tel/:kycType",
    DOWNLOAD_KYC: "/account/download"
};

export default ENDPOINTS;
