import Loading from "@ui/Loading.tsx";

const FilterLoading = () => {
    return (
        <div className="h-[328px] flex items-center justify-center">
            <Loading className="h-8 w-8" />
        </div>
    );
};

export default FilterLoading;
