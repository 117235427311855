import Badge from "@ui/Badge.tsx";
import { useMemo } from "react";

import { formatDateToView } from "../../../helpers";
import { KycType } from "../../../types";

interface Props {
    kyc: KycType;
}

const PhoneNumberList = (props: Props) => {
    const { kyc } = props;

    const secondaryNumberData = useMemo(() => {
        return {
            active: kyc.transfer_phone_numbers.filter(item => !item.deleted_at),
            remove: kyc.transfer_phone_numbers.filter(item => !!item.deleted_at)
        };
    }, [kyc.transfer_phone_numbers]);

    return (
        <div className="ml-16">
            <h3 className="text-lg font-semibold mb-4">Numéro actifs</h3>

            <div className="ml-14 mb-4">
                {secondaryNumberData.active.length ? (
                    <table className="w-full z-0 table-auto border-collapse text-sm">
                        <thead className="text-gray-500">
                            <tr>
                                <th className="px-4 py-5 font-medium text-left">
                                    Numéro
                                </th>
                                <th className="px-4 py-5 font-medium text-left">
                                    Date d{"'"}ajout
                                </th>
                                <th className="px-4 py-5 font-medium text-left">
                                    Status
                                </th>
                            </tr>
                        </thead>

                        <tbody className="text-gray-500">
                            <tr>
                                <td className="px-4 py-1.5">
                                    {kyc?.user?.phone_with_indicative || ""}
                                </td>
                                <td className="px-4 py-1.5">
                                    {kyc.created_at
                                        ? formatDateToView(kyc.created_at)
                                        : "---"}
                                </td>
                                <td className="px-4 py-1.5">
                                    <Badge color={"green"}>Actif</Badge>
                                </td>
                            </tr>

                            {secondaryNumberData.active.map((item, index) => (
                                <tr key={index}>
                                    <td className="px-4 py-1.5">
                                        {item.phone_with_indicative}
                                    </td>
                                    <td className="px-4 py-1.5">
                                        {item.created_at
                                            ? formatDateToView(item.created_at)
                                            : "---"}
                                    </td>
                                    <td className="px-4 py-1.5">
                                        <Badge
                                            color={
                                                item.validated_at
                                                    ? "green"
                                                    : "orange"
                                            }
                                        >
                                            {item.validated_at
                                                ? "Actif"
                                                : "Pending"}
                                        </Badge>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p className="text-center py-10">Aucune donnée trouvée</p>
                )}
            </div>

            <h3 className="text-lg font-semibold mb-4">
                Historique des numéros secondaires supprimés
            </h3>

            <div className="ml-14">
                {secondaryNumberData.remove.length ? (
                    <table className="w-full z-0 table-auto border-collapse text-sm">
                        <thead className="text-gray-500">
                            <tr>
                                <th className="px-4 py-5 font-medium text-left">
                                    Numéro
                                </th>
                                <th className="px-4 py-5 font-medium text-left">
                                    Date d{"'"}ajout
                                </th>
                                <th className="px-4 py-5 font-medium text-left">
                                    Date de supression
                                </th>
                            </tr>
                        </thead>

                        <tbody className="text-gray-500">
                            {secondaryNumberData.remove.map((item, index) => (
                                <tr key={index}>
                                    <td className="px-4 py-1.5">
                                        {item.phone_with_indicative}
                                    </td>
                                    <td className="px-4 py-1.5">
                                        {item.created_at
                                            ? formatDateToView(item.created_at)
                                            : "---"}
                                    </td>
                                    <td className="px-4 py-1.5">
                                        {item.deleted_at
                                            ? formatDateToView(item.deleted_at)
                                            : "---"}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p className="text-center py-10">Aucune donnée trouvée</p>
                )}
            </div>
        </div>
    );
};

export default PhoneNumberList;
