import BugsnagPerformance from "@bugsnag/browser-performance";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React from "react";

import env from "../helpers/env.ts";

Bugsnag.start({
    apiKey: env("VITE_BUGSNAG"),
    plugins: [new BugsnagPluginReact()]
});

BugsnagPerformance.start({ apiKey: env("VITE_BUGSNAG") });

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const LogErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

export default LogErrorBoundary;
