import { DropdownItem } from "@ui/Dropdown.tsx";
import { Fragment } from "react";
import { NavLink } from "react-router-dom";

import PAGES from "../../constants/pages.ts";

const NotificationFooter = () => {
    return (
        <div className="py-3 border-t text-center">
            <DropdownItem as={Fragment}>
                <NavLink
                    to={PAGES.NOTIFICATION}
                    className="text-blue-600 text-sm hover:underline"
                >
                    Voir plus
                </NavLink>
            </DropdownItem>
        </div>
    );
};

export default NotificationFooter;
