import HiSquareRefreshIcon from "@icon/HiSquareRefreshIcon.tsx";
import TbDownloadIcon from "@icon/TbDownloadIcon.tsx";
import { createColumnHelper } from "@tanstack/table-core";
import Datatable from "@ui/datatable";
import Tooltip from "@ui/Tooltip.tsx";
import { useCallback, useEffect, useMemo, useState } from "react";

import HeaderTransactionFile, {
    Period
} from "../../components/HeaderTransactionFile.tsx";
import { AppLayout } from "../../components/layouts";
import {
    DATATABLE,
    ENDPOINTS,
    PERMISSIONS,
    RESPONSE_STATUS,
    SECOND_DATE_TIME_FR_FORMAT
} from "../../constants";
import { updateAxiosBaseUrl, userHavePermissions } from "../../helpers";
import { formatDate } from "../../libs/date.ts";
import useToast from "../../libs/useToast.tsx";
import { ApiService, TransactionFileType } from "../../types";

interface TransactionFileDataStateType {
    currentPageData: TransactionFileType[];
    total: number;
}

const columnHelper = createColumnHelper<TransactionFileType>();

const TransferFiles = () => {
    const [loading, setLoading] = useState(false);
    const [transactionFilesData, setTransactionFileData] =
        useState<TransactionFileDataStateType>({
            currentPageData: [],
            total: 0
        });
    const [date, setDate] = useState({
        start: "",
        end: ""
    });
    const [{ pageIndex, pageSize }, setPaginate] = useState({
        pageIndex: DATATABLE.PAGE_INDEX,
        pageSize: DATATABLE.PAGE_SIZE
    });
    const [generation, setGeneration] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [searchValue, setSearchValue] = useState("");

    const {
        loading: loadingToast,
        customSuccess: customSuccessToast,
        customError: customErrorToast
    } = useToast();

    const refreshTransactionFiles = useCallback(() => {
        setLoading(true);
        updateAxiosBaseUrl(ApiService.moc);
        window.axios
            .get(ENDPOINTS.FILE_TRANSACTIONS, {
                params: {
                    page: pageIndex + 1,
                    limit: pageSize,
                    name: searchValue
                }
            })
            .then(response => {
                const data = response.data.data;
                setTransactionFileData({
                    currentPageData: data.files,
                    total: data.total
                });
            })
            .catch(() => {
                setTransactionFileData({
                    currentPageData: [],
                    total: 0
                });
            })
            .finally(() => setLoading(false));
    }, [pageIndex, pageSize, searchValue]);

    useEffect(() => {
        refreshTransactionFiles();
    }, [refreshTransactionFiles]);

    const handleGenerateFile = useCallback(
        (date: Period, toastLoading = false) => {
            let toastId = "";
            if (!toastLoading) {
                setGeneration(true);
            } else {
                toastId = loadingToast("Chargement...");
            }

            updateAxiosBaseUrl(ApiService.moc);
            window.axios
                .post(ENDPOINTS.FILE_TRANSACTIONS_GENERATE, {
                    start_date: date.start,
                    end_date: date.end
                })
                .then(() => {
                    Object.keys(errors).length && setErrors({});
                    refreshTransactionFiles();
                    customSuccessToast(
                        { message: "Succès de la génération du fichier" },
                        {
                            id: toastId
                        }
                    );
                })
                .catch(errorResponse => {
                    Object.keys(errors).length && setErrors({});
                    customErrorToast(
                        { message: "Echec de la génération du fichier" },
                        { id: toastId }
                    );
                    const status = errorResponse?.response?.status;
                    if (status === RESPONSE_STATUS.UNPROCESSABLE_ENTITY) {
                        setErrors(errorResponse?.response?.data.errors);
                    }
                })
                .finally(() => {
                    !toastLoading && setGeneration(false);
                });
        },
        [
            customErrorToast,
            customSuccessToast,
            errors,
            loadingToast,
            refreshTransactionFiles
        ]
    );

    const columns = useMemo(() => {
        return [
            columnHelper.accessor(row => row.name, {
                id: "name",
                header: () => <>Nom du fichier</>,
                cell: info => <>{info.getValue()}</>,
                footer: info => info.column.id
            }),
            columnHelper.accessor(row => row.period, {
                id: "period",
                header: () => <>Période transaction</>,
                cell: info => (
                    <>
                        {formatDate(
                            info.row.original.start_date,
                            SECOND_DATE_TIME_FR_FORMAT
                        )}{" "}
                        à{" "}
                        {formatDate(
                            info.row.original.end_date,
                            SECOND_DATE_TIME_FR_FORMAT
                        )}
                    </>
                ),
                footer: info => info.column.id
            }),
            columnHelper.accessor(row => row.created_at, {
                id: "created_at",
                header: () => <>Date génération fichier</>,
                cell: info => (
                    <>
                        {formatDate(
                            info.getValue(),
                            SECOND_DATE_TIME_FR_FORMAT
                        )}
                    </>
                ),
                footer: info => info.column.id
            }),
            columnHelper.accessor(row => row.id, {
                id: "path",
                header: () => <>Action</>,
                cell: info => (
                    <div className="flex items-center space-x-4">
                        <Tooltip content="Télécharger la transaction">
                            <a
                                href={info.row.original.path}
                                download
                                className="focus:outline-none text-gray-400 hover:text-gray-500"
                            >
                                <TbDownloadIcon />
                            </a>
                        </Tooltip>

                        {userHavePermissions([
                            PERMISSIONS.GENERATE_TRANSFER_FILE
                        ]) && (
                            <Tooltip content="Relancer">
                                <button
                                    onClick={() =>
                                        handleGenerateFile(
                                            {
                                                start: info.row.original
                                                    .start_date,
                                                end: info.row.original.end_date
                                            },
                                            true
                                        )
                                    }
                                    className="focus:outline-none text-gray-400 hover:text-gray-500"
                                >
                                    <HiSquareRefreshIcon />
                                </button>
                            </Tooltip>
                        )}
                    </div>
                ),
                footer: info => info.column.id
            })
        ];
    }, [handleGenerateFile]);

    return (
        <AppLayout className="space-y-5">
            {userHavePermissions([PERMISSIONS.GENERATE_TRANSFER_FILE]) && (
                <HeaderTransactionFile
                    date={date}
                    setDate={setDate}
                    errors={errors}
                    loading={generation}
                    onSubmit={() => handleGenerateFile(date)}
                />
            )}

            <div className="bg-white border rounded-md px-4 py-5">
                <Datatable
                    title="Fichier des transactions"
                    columns={columns}
                    data={transactionFilesData.currentPageData}
                    allDataTotal={transactionFilesData.total}
                    manualFetchData
                    manualPaginateState={{
                        value: { pageIndex, pageSize },
                        setValue: setPaginate
                    }}
                    loading={loading}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                />
            </div>
        </AppLayout>
    );
};

export default TransferFiles;
