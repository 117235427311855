import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import env from "../helpers/env.ts";
import { addNotification } from "../store/notificationSlice.ts";
import { EventParamType } from "../types";

import useAuth from "./useAuth.ts";

const useSubscribeChannels = () => {
    const { isLogin, user } = useAuth();
    const ref = useRef(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isLogin && !ref.current && user?.id) {
            ref.current = true;
            const pusher = new window.Pusher(env("PUSHER_KEY"), {
                cluster: "eu"
            });

            const channel = pusher.subscribe(`notif.user.${user.id}`);

            channel.bind("risk-notification-event", (data: EventParamType) => {
                if (isLogin) dispatch(addNotification(data.notification));
            });
        }
    }, [dispatch, isLogin, user?.id]);
};

export default useSubscribeChannels;
