import { Menu } from "@headlessui/react";
import HiChevronDownIcon from "@icon/HiChevronDownIcon.tsx";
import Checkbox from "@ui/Checkbox.tsx";

import { cn } from "../../libs/style.ts";
import { DropdownTransition } from "../transitions";

export interface StatusOptionItemType {
    label: string;
    value: string;
    check: boolean;
}

interface Props {
    handleCheckStatus: (element: StatusOptionItemType, key: string) => void;
    state: {
        payIn: StatusOptionItemType[];
        payOut: StatusOptionItemType[];
    };
}

interface StatusListProps {
    title: string;
    onCheckElement: (item: StatusOptionItemType) => void;
    list: StatusOptionItemType[];
}

const StatusList = (props: StatusListProps) => {
    const { title, onCheckElement, list } = props;

    return (
        <div className="space-y-2">
            <h6 className="text-sm text-blue-600">{title}</h6>

            <div className="space-y-2">
                {list.map((item, index) => (
                    <Checkbox
                        key={index}
                        checked={item.check}
                        onChange={() => onCheckElement(item)}
                    >
                        {item.label}
                    </Checkbox>
                ))}
            </div>
        </div>
    );
};

const StatusTransferSelect = (props: Props) => {
    const { state, handleCheckStatus } = props;

    return (
        <Menu as="div" className="relative">
            {({ open }) => (
                <>
                    <Menu.Button
                        className={cn({
                            "w-full py-2.5 px-4 border border-gray-300": true,
                            "flex items-center text-sm mt-2": true,
                            "rounded-md bg-white text-gray-400": true,
                            "border-blue-600 ring ring-blue-600/20": open
                        })}
                    >
                        Statut trx
                    </Menu.Button>

                    <HiChevronDownIcon
                        className={cn({
                            "text-gray-400 hover:text-gray-600": true,
                            "transition duration-200 -rotate-90": true,
                            "absolute top-3 right-2 mt-2": true,
                            "rotate-0": open
                        })}
                    />

                    <DropdownTransition>
                        <Menu.Items
                            as="div"
                            className={cn({
                                "bg-white shadow rounded-md": true,
                                "grid grid-cols-2 gap-3 border": true,
                                "absolute z-10 px-2 py-1 mt-1": true,
                                "w-[250px] -left-[20%]": true
                            })}
                        >
                            <StatusList
                                title="Emission"
                                list={state.payIn}
                                onCheckElement={element =>
                                    handleCheckStatus(element, "payIn")
                                }
                            />

                            <StatusList
                                title="Réception"
                                list={state.payOut}
                                onCheckElement={element =>
                                    handleCheckStatus(element, "payOut")
                                }
                            />
                        </Menu.Items>
                    </DropdownTransition>
                </>
            )}
        </Menu>
    );
};

export default StatusTransferSelect;
