import { useEffect, useRef, useState } from "react";

import { ENDPOINTS } from "../constants";
import { updateAxiosBaseUrl } from "../helpers";
import useToast from "../libs/useToast.tsx";
import { ApiService } from "../types";

const useFetchPictureBase64 = (src: string, showLoadingMessage = false) => {
    const [pictureBase64, setPictureBase64] = useState("");
    const ref = useRef(false);

    const { loading: loadingToast, customError, customSuccess } = useToast();

    useEffect(() => {
        if (!ref.current && !!src) {
            ref.current = true;
            updateAxiosBaseUrl(ApiService.auth);
            let toastId = "";
            if (showLoadingMessage) {
                toastId = loadingToast("Chargement d'image...");
            }

            window.axios
                .post(ENDPOINTS.PICTURE_BASE64, { photo: src })
                .then(response => {
                    const data = response.data.data;

                    setPictureBase64(data?.photo_base64 || "");

                    showLoadingMessage &&
                        customSuccess(
                            { message: "Succès du chargement de l'image" },
                            { id: toastId }
                        );
                })
                .catch(
                    () =>
                        showLoadingMessage &&
                        customError(
                            { message: "Echec du chargement de l'image" },
                            { id: toastId }
                        )
                );
        }
    }, [customError, customSuccess, loadingToast, showLoadingMessage, src]);

    return {
        pictureBase64
    };
};

export default useFetchPictureBase64;
