import { flexRender, RowModel } from "@tanstack/react-table";
import { Row } from "@tanstack/table-core";

import { cn } from "../../../libs/style.ts";

interface Props<T> {
    rowModels: RowModel<T>;
    trClassName?: string;
    tdClassName?: string;
    paddingLeft?: boolean;
    paddingRight?: boolean;
    onClickRow?: (row: Row<T>) => void;
}

function TableBody<T>(props: Props<T>) {
    const { rowModels, onClickRow, trClassName, tdClassName } = props;

    return (
        <>
            {rowModels.rows.map((row, index) => (
                <tr
                    key={index}
                    className={cn({
                        "border-b": true,
                        [trClassName || ""]: true
                    })}
                    onClick={() => {
                        onClickRow && onClickRow(row);
                    }}
                >
                    {row.getVisibleCells().map((cell, index) => (
                        <td
                            key={index}
                            className={cn({
                                "px-4 py-5": true,
                                [tdClassName || ""]: true
                            })}
                        >
                            {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                            )}
                        </td>
                    ))}
                </tr>
            ))}
        </>
    );
}

export default TableBody;
