import { HeaderGroup } from "@tanstack/react-table";

interface Props<T> {
    headerGroups: HeaderGroup<T>[];
}

function TableEmpty<T>(props: Props<T>) {
    return (
        <tr>
            <td
                colSpan={props.headerGroups[0].headers.length}
                className="py-4 text-center"
            >
                Auccune donnée à afficher
            </td>
        </tr>
    );
}

export default TableEmpty;
