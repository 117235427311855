import { cn } from "../../libs/style.ts";
import { IconProps } from "../../types";

const HeLockClosedIcon = ({
    size = "md",
    color,
    className = ""
}: IconProps) => {
    return (
        <svg
            role="img"
            className={cn({
                "text-green-500": color === "green",
                "text-yellow-500": color === "yellow",
                "text-red-500": color === "red",
                "h-3 w-3": size === "xs",
                "h-4 w-4": size === "sm",
                "h-5 w-5": size === "md",
                "h-7 w-7": size === "lg",
                "h-8 w-8": size === "xl",
                [className]: true
            })}
            fill="none"
            stroke="currentColor"
            strokeWidth={2}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
            />
        </svg>
    );
};

export default HeLockClosedIcon;
