import axios from "axios";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import Pusher from "pusher-js";

import config from "./config";
import { refreshClientConfig } from "./helpers";

const authService = config.backServicesHost.auth;

declare global {
    interface Window {
        axios: typeof axios;
        timer: ReturnType<typeof setTimeout> | undefined;
        dayjs: typeof dayjs;
        Pusher: typeof Pusher;
    }
}

/*
|--------------------------------------------------------------------------
| Axios
|--------------------------------------------------------------------------
|
| Configuration of the axios instance.
|
*/
window.axios = axios;
window.axios.defaults.baseURL = authService.host + authService.basePath + "/";

/*
|--------------------------------------------------------------------------
| Dayjs
|--------------------------------------------------------------------------
|
| Store dayjs instance globally.
|
*/
window.dayjs = dayjs;
window.dayjs.extend(relativeTime);
window.dayjs.extend(localizedFormat);

/*
|--------------------------------------------------------------------------
| Config
|--------------------------------------------------------------------------
|
| Store config object in storage.
|
*/
refreshClientConfig();

/*
|--------------------------------------------------------------------------
| Pusher
|--------------------------------------------------------------------------
|
| Store pusher-js instance globally
|
*/
window.Pusher = Pusher;
