import HeSearchIcon from "@icon/HeSearchIcon";
import {
    OnChangeFn,
    PaginationState,
    useReactTable
} from "@tanstack/react-table";
import {
    ColumnDef,
    getCoreRowModel,
    getPaginationRowModel,
    Row
} from "@tanstack/table-core";
import TableBody from "@ui/datatable/TableBody.tsx";
import TableEmpty from "@ui/datatable/TableEmpty.tsx";
import TableHead from "@ui/datatable/TableHead.tsx";
import TableLoader from "@ui/datatable/TableLoader.tsx";
import TablePagination from "@ui/datatable/TablePagination.tsx";
import Input from "@ui/Input";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { updateAxiosBaseUrl } from "../../../helpers";
import { ApiService, PaginateStateValue } from "../../../types";

interface Props<T> {
    title?: ReactNode;
    className?: string;
    theadClassName?: string;
    thClassName?: string;
    trClassName?: string;
    tdClassName?: string;
    paddingLeft?: boolean;
    paddingRight?: boolean;
    isSearchable?: boolean;
    /* eslint-disable */
    columns: ColumnDef<T, any>[];
    /* eslint-enable */
    data?: T[];
    allDataTotal?: number;
    service?: ApiService;
    loading?: boolean;
    fetchDataEndpoint?: string;
    manualFetchData?: boolean;
    manualPaginateState?: {
        value: PaginateStateValue;
        setValue: OnChangeFn<PaginationState>;
    };
    formatData?: (data: T[]) => T[];
    searchValue?: string;
    setSearchValue?: (value: string) => void;
    onClickRow?: (row: Row<T>) => void;
}

function Datatable<T>(props: Props<T>) {
    const {
        title,
        paddingLeft,
        paddingRight,
        columns,
        data = [],
        fetchDataEndpoint,
        manualFetchData = false,
        allDataTotal = 0,
        service,
        loading = false,
        isSearchable = true,
        searchValue,
        setSearchValue,
        manualPaginateState,
        onClickRow
    } = props;

    const [{ pageIndex, pageSize }, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10
    });
    const [array, setArray] = useState<T[]>(data || []);
    const [search, setSearch] = useState("");
    const [arrayLoading, setArrayLoading] = useState(false);
    const [total, setTotal] = useState(allDataTotal || 0);

    const fetchData = useCallback(
        (endpoint: string) => {
            setArrayLoading(true);
            service && updateAxiosBaseUrl(service);
            window.axios.get(endpoint).then(response => {
                const data = response.data.data;
                setTotal(9);
                setArray(props.formatData ? props.formatData(data) : data);
                setArrayLoading(false);
            });
        },
        [props, service]
    );

    useEffect(() => {
        if (!fetchDataEndpoint) {
            if (!manualFetchData) {
                setTotal(data.length);
            } else {
                setArray(data);
                setTotal(allDataTotal);
            }
        }
    }, [allDataTotal, data, fetchDataEndpoint, manualFetchData]);

    const pagination = useMemo(
        () => ({
            pageIndex: manualPaginateState?.value.pageIndex || pageIndex,
            pageSize: manualPaginateState?.value.pageSize || pageSize
        }),
        [
            manualPaginateState?.value.pageIndex,
            manualPaginateState?.value.pageSize,
            pageIndex,
            pageSize
        ]
    );

    useEffect(() => {
        if (fetchDataEndpoint) {
            const endpoint = fetchDataEndpoint
                .replace(":pageSize", `${pagination.pageSize}`)
                .replace(":pageIndex", `${pagination.pageIndex + 1}`);
            fetchData(endpoint);
        }
    }, [
        fetchData,
        fetchDataEndpoint,
        pagination.pageIndex,
        pagination.pageSize
    ]);

    const table = useReactTable({
        data: array,
        columns,
        pageCount: Math.ceil(total / pageSize) ?? -1,
        state: {
            pagination
        },
        onPaginationChange: manualPaginateState?.setValue || setPagination,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        manualPagination: !!fetchDataEndpoint || !!manualPaginateState /*,
        debugTable: true*/
    });

    return (
        <>
            {title && (
                <h4 className="font-medium text-lg text-gray-700 flex items-center space-x-8">
                    {title}
                </h4>
            )}

            <div className="relative">
                {isSearchable && (
                    <Input
                        className="w-1/3"
                        placeholder="Rechercher"
                        value={searchValue || search}
                        onChange={e => {
                            if (setSearchValue) {
                                setSearchValue(e.target.value);
                            } else {
                                setSearch(e.target.value);
                            }
                        }}
                        icon={<HeSearchIcon />}
                    />
                )}

                <table className="w-full z-0 table-auto border-collapse text-sm">
                    {columns.length > 0 && (
                        <TableHead
                            headerGroups={table.getHeaderGroups()}
                            paddingLeft={paddingLeft}
                            paddingRight={paddingRight}
                            thClassName={props.thClassName}
                        />
                    )}

                    <tbody className="text-gray-500">
                        <TableBody
                            trClassName={props.trClassName}
                            tdClassName={props.tdClassName}
                            rowModels={table.getRowModel()}
                            paddingLeft={paddingLeft}
                            paddingRight={paddingRight}
                            onClickRow={onClickRow}
                        />

                        {table.getRowModel().rows.length === 0 && (
                            <TableEmpty
                                headerGroups={table.getHeaderGroups()}
                            />
                        )}
                    </tbody>
                </table>

                {(arrayLoading || loading) && <TableLoader />}
            </div>

            <TablePagination
                table={table}
                currentPage={manualPaginateState?.value?.pageIndex || pageIndex}
                pageCount={table.getPageCount()}
            />
        </>
    );
}

export default Datatable;
