import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CountryType } from "../types";

interface CountriesState {
    list: CountryType[];
}

const initialState: CountriesState = {
    list: []
};

const countriesSlice = createSlice({
    name: "counter",
    initialState,
    reducers: {
        addCountries: (state, action: PayloadAction<CountryType[]>) => {
            state.list = action.payload;
        }
    }
});

export const { addCountries } = countriesSlice.actions;

const countriesReducer = countriesSlice.reducer;

export default countriesReducer;
