import { Menu } from "@headlessui/react";
import GsFormCloseIcon from "@icon/GsFormCloseIcon.tsx";
import HiChevronDownIcon from "@icon/HiChevronDownIcon.tsx";
import SelectButton from "@ui/select/SelectButton.tsx";
import { useMemo, useState } from "react";

import { cn } from "../../../libs/style.ts";
import { DropdownTransition } from "../../transitions";

import SelectSearch from "./SelectSearch.tsx";

interface OptionType {
    label: string;
    value: string;
    /* eslint-disable */
    [key: string]: any;
    /* eslint-enable */
}

export type SelectValueType = OptionType | null;

interface Props {
    value: SelectValueType;
    onChange: (value: SelectValueType) => void;
    options: SelectValueType[];
    placeholder?: string;
    className?: string;
    containerClassName?: string;
    isSearchable?: boolean;
    disable?: boolean;
}

const Select = (props: Props) => {
    const {
        value = null,
        onChange,
        options = [],
        isSearchable = true,
        placeholder = "Select...",
        containerClassName,
        disable = false
    } = props;

    const [searchInput, setSearchInput] = useState("");

    const filterOptions = useMemo(() => {
        return options.filter(
            item =>
                item?.label.toUpperCase().includes(searchInput.toUpperCase())
        );
    }, [options, searchInput]);

    return (
        <Menu as="div" className={cn("relative", containerClassName)}>
            {({ open }) => (
                <>
                    <SelectButton
                        open={open}
                        className={props.className}
                        placeholder={placeholder}
                        value={value}
                        disable={disable}
                        onClick={() => {
                            if (searchInput.length) setSearchInput("");
                        }}
                    />

                    <div className="flex items-center space-x-2 absolute top-3 right-2 mt-2">
                        {!!value && !disable && (
                            <button
                                data-testid="select-clear"
                                className="focus:outline-none"
                                onClick={() => onChange(null)}
                            >
                                <GsFormCloseIcon className="text-gray-400 hover:text-gray-600" />
                            </button>
                        )}

                        <span className="text-lg pb-0.5 w-[1px] h-5 border-l" />
                        <HiChevronDownIcon
                            className={cn({
                                "transition duration-200 text-gray-400 hover:text-gray-600 -rotate-90":
                                    true,
                                "rotate-0": open
                            })}
                        />
                    </div>

                    <DropdownTransition>
                        <Menu.Items
                            data-testid="select-menu"
                            as="div"
                            className="border bg-white z-10 shadow absolute w-full mt-1 rounded-md px-2 py-1"
                        >
                            {isSearchable && (
                                <SelectSearch
                                    onChange={e =>
                                        setSearchInput(e.target.value)
                                    }
                                />
                            )}

                            <div className="max-h-[240px] overflow-y-scroll">
                                {filterOptions.map((item, index) => (
                                    <Menu.Item
                                        key={index}
                                        onClick={() => onChange(item)}
                                        as="li"
                                        data-testid={`select-item-${index}`}
                                        className="py-2 px-4 text-sm text-gray-700 hover:text-blue-600 hover:bg-blue-100 rounded-md list-none cursor-pointer"
                                    >
                                        {item?.label}
                                    </Menu.Item>
                                ))}
                            </div>
                        </Menu.Items>
                    </DropdownTransition>
                </>
            )}
        </Menu>
    );
};

export default Select;
