import env from "../helpers/env";
import { SessionType } from "../types";

export interface ClientConfig {
    name: string;
    env: string;
    url: string;
    locale: "fr" | "en";
    fakerLocale: "fr" | "en";
    sessionDriver: SessionType;
    auth: {
        redirectLoginUrl: string;
        redirectLogoutUrl: string;
        inactivityTime: string;
    };
    backServicesHost: {
        auth: {
            host: string;
            basePath: string;
        };
        transfer: {
            host: string;
            basePath: string;
        };
        moc: {
            host: string;
            basePath: string;
        };
    };
    toastTimeout: number;
    walletFilesPath: string;
}

const config: ClientConfig = {
    /*
    |--------------------------------------------------------------------------
    | Application name
    |--------------------------------------------------------------------------
    |
    | This value is the name of your applications. This value is used when the
    |
    */
    name: env("APP_NAME", "React"),

    /*
    |--------------------------------------------------------------------------
    | Application Environment
    |--------------------------------------------------------------------------
    |
    | This value determines the "environment" your application is currently
    | running in. This may determine how you prefer to configure various
    | services the application utilizes. Set this in ".env" file.
    |
    */
    env: env("APP_ENV", "React"),

    /*
    |--------------------------------------------------------------------------
    | Application URL
    |--------------------------------------------------------------------------
    |
    | This value is the url of your application. This value can be used on
    | interfaces and in functions
    |
    */
    url: env("APP_URL", "React"),

    /*
    |--------------------------------------------------------------------------
    | Application Local Configuration
    |--------------------------------------------------------------------------
    |
    | The application lang determines the default lang that will be used
    | by the translation service provider. You are free to set this value
    | to any of the lang which will be supported by the application.
    |
    */
    locale: env("LOCAL", "fr"),

    /*
    |--------------------------------------------------------------------------
    | Faker Locale
    |--------------------------------------------------------------------------
    |
    | This local will be used by the Faker JS library when generating fake
    | data for your application. For example, this will be used to get
    | localized telephone numbers, street address information and more.
    |
    */
    fakerLocale: env("LOCAL_FAKER", "fr"),

    /*
    |--------------------------------------------------------------------------
    | Default Session Driver
    |--------------------------------------------------------------------------
    |
    | This value is used to indicate how the data can be persisted in session.
    |
    */
    sessionDriver: "localStorage",

    /*
    |--------------------------------------------------------------------------
    | Authentication process data
    |--------------------------------------------------------------------------
    |
    | This value is used to indicate the data used in the authentication
    | process.
    |
    */
    auth: {
        redirectLoginUrl: env("AUTH_REDIRECT_LOGIN_URL", "/"),
        redirectLogoutUrl: env("AUTH_REDIRECT_LOGOUT_URL", "/logout"),
        inactivityTime: env("AUTH_INACTIVITY_TIME", 20)
    },

    /*
    |--------------------------------------------------------------------------
    | All api services useFetchPaymentMethod.ts in app
    |--------------------------------------------------------------------------
    |
    | This value is used to indicate all service used in application
    |
    */
    backServicesHost: {
        auth: {
            host: env("AUTH_SERVICE", "http://localhost:8000"),
            basePath: env("AUTH_BASE_PATH", "/")
        },
        transfer: {
            host: env("TRANSFER_SERVICE", "http://localhost:8000"),
            basePath: env("TRANSFER_BASE_PATH", "/")
        },
        moc: {
            host: env("MOC_SERVICE", "http://localhost:8000"),
            basePath: env("MOC_BASE_PATH", "/")
        }
    },

    /*
    |--------------------------------------------------------------------------
    | TOAST TIMEOUT
    |--------------------------------------------------------------------------
    |
    | This value is used to indicate the duration of a toast
    |
    */
    toastTimeout: env("TOAST_TIMEOUT", 3000),

    /*
    |--------------------------------------------------------------------------
    | Wallet File Path
    |--------------------------------------------------------------------------
    |
    | This value is used to indicate the wallet path
    |
    */
    walletFilesPath: "/images/wallet"
};

export default config;
