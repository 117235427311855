import Button from "@ui/Button.tsx";
import Modal from "@ui/modal";
import Textarea from "@ui/Textarea.tsx";
import { useId } from "react";

import { KycType, ModalPropsType } from "../../../types";

interface CommentFieldType {
    name: string;
    photo: string;
    selfie_photo: string;
}

interface CommentDataType {
    full_name: { comment: string; value: string };
    photo: { comment: string; value: string };
    selfie_photo: { comment: string; value: string };
    haveOldFullName: boolean;
    haveOldPhoto: boolean;
}

interface Props extends ModalPropsType {
    comments: CommentFieldType;
    onCommentChange: (value: CommentFieldType) => void;
    onSubmit: () => void;
    loading?: boolean;
    errors: { [key: string]: string };
    kyc: KycType;
    commentData: CommentDataType;
}

const ToReviewModal = (props: Props) => {
    const {
        isOpen,
        closeModal,
        comments,
        onCommentChange,
        onSubmit,
        loading = false,
        errors,
        kyc,
        commentData
    } = props;

    const textareaId = useId();
    const fileId = useId();

    return (
        <Modal
            isOpen={isOpen}
            closeModal={closeModal}
            title="A revoir"
            className="min-w-[600px]"
        >
            <div className="space-y-6 p-6 text-sm">
                {!kyc?.transfer_kyc_rejected_at && (
                    <>
                        <div className="flex items-center gap-5">
                            <div className="flex items-center justify-between w-1/3">
                                <label
                                    htmlFor={textareaId}
                                    className="font-medium"
                                >
                                    Nom
                                </label>

                                <p>
                                    {commentData.full_name.value ||
                                        kyc.user.full_name}
                                </p>
                            </div>

                            <Textarea
                                id={textareaId}
                                placeholder="Veuillez entrer le commentaire"
                                className="flex-1"
                                value={comments.name}
                                onChange={e =>
                                    onCommentChange({
                                        ...comments,
                                        name: e.target.value
                                    })
                                }
                                error={errors?.full_name}
                            />
                        </div>

                        <div className="flex items-center gap-5">
                            <div className="flex items-center justify-between w-1/3">
                                <label htmlFor={fileId} className="font-medium">
                                    Pièce d{"'"}identité
                                </label>
                                <a
                                    download
                                    target="_blank"
                                    href={
                                        commentData.photo.value ||
                                        kyc.user.photo ||
                                        ""
                                    }
                                    className="text-blue-600 hover:underline truncate"
                                    rel="noreferrer"
                                >
                                    image.jpg
                                </a>
                            </div>

                            <Textarea
                                id={fileId}
                                placeholder="Veuillez entrer le commentaire"
                                className="flex-1"
                                value={comments.photo}
                                onChange={e =>
                                    onCommentChange({
                                        ...comments,
                                        photo: e.target.value
                                    })
                                }
                                error={errors?.photo}
                            />
                        </div>
                    </>
                )}

                {!kyc?.card_kyc_rejected_at && (
                    <>
                        <div className="flex items-center gap-5">
                            <div className="flex items-start justify-between w-1/3">
                                <label htmlFor={fileId} className="font-medium">
                                    Selfie avec pièce <br /> d{"'"}identité
                                </label>

                                <a
                                    download
                                    target="_blank"
                                    href={
                                        commentData?.selfie_photo?.value ||
                                        kyc.user?.selfie_photo ||
                                        ""
                                    }
                                    className="text-blue-600 hover:underline truncate"
                                    rel="noreferrer"
                                >
                                    image.jpg
                                </a>
                            </div>

                            <Textarea
                                id={fileId}
                                placeholder="Veuillez entrer le commentaire"
                                className="flex-1"
                                value={comments.selfie_photo}
                                onChange={e =>
                                    onCommentChange({
                                        ...comments,
                                        selfie_photo: e.target.value
                                    })
                                }
                                error={errors?.selfie_photo}
                            />
                        </div>
                    </>
                )}
            </div>

            <div className="mt-4 p-6 border-t flex items-center justify-end space-x-4">
                <Button withAuto variant="gray" onClick={closeModal}>
                    Fermer
                </Button>

                <Button withAuto onClick={onSubmit} loading={loading}>
                    Envoyer
                </Button>
            </div>
        </Modal>
    );
};

export default ToReviewModal;
