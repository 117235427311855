import TbDownloadIcon from "@icon/TbDownloadIcon.tsx";
import {
    Dropdown,
    DropdownButton,
    DropdownItem,
    DropdownItems
} from "@ui/Dropdown.tsx";
import Tooltip from "@ui/Tooltip.tsx";

import { downloadFile } from "../helpers";
import { cn } from "../libs/style.ts";
import { TransferType } from "../types";

interface Props {
    transfer?: TransferType;
    typeButton?: "iconButton" | "button";
}

const DownloadTransferDropdown = (props: Props) => {
    const { transfer, typeButton = "iconButton" } = props;

    return (
        (transfer?.status === "success" || transfer?.status === "failed") &&
        (transfer?.french_receipt_url || transfer?.english_receipt_url) && (
            <Dropdown as="div" className="relative">
                {typeButton === "iconButton" && (
                    <Tooltip content="Télécharger le reçu">
                        <DropdownButton className="focus:outline-none text-gray-400 hover:text-gray-500">
                            <TbDownloadIcon />
                        </DropdownButton>
                    </Tooltip>
                )}

                {typeButton === "button" && (
                    <DropdownButton
                        className={cn({
                            "px-6 text-base w-fit rounded-md space-x-3": true,
                            "focus:outline-none focus:ring focus:border-blue-600 focus:ring-blue-600/20":
                                true,
                            "flex items-center font-medium justify-center":
                                true,
                            "disabled:cursor-not-allowed bg-blue-600 text-white py-2":
                                true
                        })}
                    >
                        <TbDownloadIcon />
                        <span>Télécharger le reçu</span>
                    </DropdownButton>
                )}

                <DropdownItems
                    className={cn({
                        "text-sm rounded-md focus:outline-none": true,
                        "bg-white text-gray-800 border shadow-sm": true,
                        "absolute z-10 -right-2 lg:mt-2": true,
                        "w-full right-0": typeButton === "button"
                    })}
                >
                    {transfer?.french_receipt_url && (
                        <DropdownItem>
                            <li
                                className={cn({
                                    "cursor-pointer list-none py-2 px-3": true,
                                    "hover:bg-gray-100 truncate text-gray-500":
                                        true,
                                    "text-base": typeButton === "button"
                                })}
                                onClick={() => {
                                    transfer?.french_receipt_url &&
                                        downloadFile(
                                            transfer?.french_receipt_url,
                                            true
                                        );
                                }}
                            >
                                En français
                            </li>
                        </DropdownItem>
                    )}

                    {transfer?.english_receipt_url && (
                        <DropdownItem>
                            <li
                                className={cn({
                                    "cursor-pointer list-none py-2 px-3": true,
                                    "hover:bg-gray-100 truncate text-gray-500":
                                        true,
                                    "text-base": typeButton === "button"
                                })}
                                onClick={() => {
                                    transfer?.english_receipt_url &&
                                        downloadFile(
                                            transfer?.english_receipt_url,
                                            true
                                        );
                                }}
                            >
                                En anglais
                            </li>
                        </DropdownItem>
                    )}
                </DropdownItems>
            </Dropdown>
        )
    );
};

export default DownloadTransferDropdown;
