import { TRANSACTION_STATUS } from "../types";

const FormatStatusTransfer = (props: { status: string }) => {
    switch (props.status) {
        case TRANSACTION_STATUS.SUCCESS:
            return "Succès";
        case TRANSACTION_STATUS.FAILED:
            return "Echec";
        case TRANSACTION_STATUS.PENDING:
            return "En attente";
        case TRANSACTION_STATUS.TO_REFUND:
            return "A rembourser";
        case TRANSACTION_STATUS.REFUND:
            return "Rembourser";
        default:
            return null;
    }
};

export { FormatStatusTransfer };
