import { useCallback, useState } from "react";

const useModal = () => {
    const [isOpen, setIsOpen] = useState(false);

    return {
        isOpen,
        setIsOpen,
        closeModal: useCallback(() => {
            setIsOpen(false);
        }, []),
        openModal: useCallback(() => {
            setIsOpen(true);
        }, [])
    };
};

export default useModal;
