import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "./bootstrap.ts";

import App from "./App.tsx";
import LogErrorBoundary from "./components/LogErrorBoundary.tsx";
import { store } from "./store";
import "./index.css";
import "./i18n.ts";

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <LogErrorBoundary>
            <Provider store={store}>
                <Router>
                    <App />
                </Router>
            </Provider>
        </LogErrorBoundary>
    </React.StrictMode>
);
