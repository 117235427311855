import React from "react";

import { cn } from "../../libs/style.ts";

import Aside from "./aside";
import Nav from "./Nav.tsx";

interface Props {
    children: React.ReactNode;
    className?: string;
}

const AppLayout = (props: Props) => {
    return (
        <>
            <Nav />

            <Aside />

            <div className="relative z-0 top-0 px-4 pt-16 min-h-screen bg-[#FAFBFC] left-[103px] md:left-[250px] w-[calc(100%-103px)] md:w-[calc(100%-250px)] h-full">
                <div className={cn("py-8", props.className)}>
                    {props.children}
                </div>
            </div>
        </>
    );
};

export default AppLayout;
