import React from "react";
import { Navigate } from "react-router-dom";

import config from "../config";
import { RESPONSE_STATUS } from "../constants";
import { auth, userHaveOneRoles, userHavePermissions } from "../helpers";

interface Props {
    children: React.ReactElement;
    permissions?: string[];
    roles?: string[];
}

export const Auth = ({ children, permissions = [], roles = [] }: Props) => {
    const navigateState = { previousUrl: window.location.pathname };

    return (
        <>
            {auth()?.isLogin ? (
                <>
                    {userHavePermissions(permissions) &&
                    userHaveOneRoles(roles) ? (
                        children
                    ) : (
                        <Navigate to={`/${RESPONSE_STATUS.UNAUTHORIZED}`} />
                    )}
                </>
            ) : (
                <Navigate
                    to={config.auth.redirectLogoutUrl}
                    state={navigateState}
                />
            )}
        </>
    );
};

export const Guest = ({ children }: Props) => {
    const navigateState = { previousUrl: window.location.pathname };

    return (
        <>
            {!auth()?.isLogin ? (
                children
            ) : (
                <Navigate
                    to={config.auth.redirectLoginUrl}
                    state={navigateState}
                />
            )}
        </>
    );
};
