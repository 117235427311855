import Button from "@ui/Button.tsx";
import Input from "@ui/Input.tsx";
import { FormEvent, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import * as yup from "yup";

import { ENDPOINTS, PAGES, RESPONSE_STATUS } from "../../constants";
import { getUrlAfterLogin, updateAxiosBaseUrl } from "../../helpers";
import useManageSchemaValidationError from "../../hooks/useManageSchemaValidationError.ts";
import useToast from "../../libs/useToast.tsx";
import rules from "../../schemas";
import { login } from "../../store/authSlice.ts";
import { ApiService, UserType } from "../../types";
import AuthLayout from "../auth/layouts/AuthLayout.tsx";

const LoginSchema = yup.object().shape({
    email: rules.email,
    password: rules.password
});

const INVALID_CREDENTIAL_KEY = "email_password";

const Login = () => {
    /* Hook state */
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        email: "",
        password: ""
    });
    const [invalidCredential, setInvalidCredential] = useState(false);

    /* Custom hooks */
    const { errors, setErrors, resetErrors, showErrors } =
        useManageSchemaValidationError();
    const { customSuccess: customSuccessToast, customError: customErrorToast } =
        useToast();

    /* Lib hooks */
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSubmit = useCallback(
        (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            LoginSchema.validate(formData, { abortEarly: false })
                .then(() => {
                    setLoading(true);
                    updateAxiosBaseUrl(ApiService.auth);
                    window.axios
                        .post(ENDPOINTS.LOGIN, formData)
                        .then(response => {
                            resetErrors();
                            customSuccessToast({
                                message: "Succès de la connexion"
                            });
                            const data = response.data.data;
                            const user = data.user as UserType;

                            dispatch(
                                login({
                                    user: user,
                                    tokenData: {
                                        ...data.token.token,
                                        accessToken: data.token.accessToken
                                    }
                                })
                            );

                            setTimeout(() => {
                                navigate(getUrlAfterLogin());
                            }, 20);
                        })
                        .catch(error => {
                            resetErrors();
                            const status = error.response.status;
                            const responseError = error.response.data.errors;
                            customErrorToast({ message: "Echec de connexion" });
                            if (
                                status === RESPONSE_STATUS.UNPROCESSABLE_ENTITY
                            ) {
                                if (
                                    Object.values(responseError).includes(
                                        INVALID_CREDENTIAL_KEY
                                    )
                                ) {
                                    setInvalidCredential(true);
                                } else {
                                    setErrors(responseError);
                                }
                            }
                        })
                        .finally(() => setLoading(false));
                })
                .catch(showErrors());
        },
        [
            customErrorToast,
            customSuccessToast,
            dispatch,
            formData,
            navigate,
            resetErrors,
            setErrors,
            showErrors
        ]
    );

    return (
        <AuthLayout title="Connectez-vous">
            {invalidCredential && (
                <p className="mb-4 text-xs text-center p-2.5 text-red-800 bg-red-100 rounded-md">
                    Les identifants(Email ou Mot de passe) ne sont pas correcte.
                </p>
            )}

            <form onSubmit={handleSubmit}>
                <Input
                    placeholder="Email"
                    type="email"
                    className="mb-3"
                    name="email"
                    value={formData.email}
                    onChange={e =>
                        setFormData({ ...formData, email: e.target.value })
                    }
                    error={errors?.email}
                />

                <div className="text-right mb-6">
                    <div className="text-left">
                        <Input
                            placeholder="Mot de passe"
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={e =>
                                setFormData({
                                    ...formData,
                                    password: e.target.value
                                })
                            }
                            error={errors?.password}
                        />
                    </div>

                    <NavLink
                        className="text-blue-600 hover:underline text-sm inline-block"
                        to={PAGES.FORGOT_PASSWORD}
                    >
                        Mot de passe oublier
                    </NavLink>
                </div>

                <Button loading={loading} type="submit">
                    Connexion
                </Button>
            </form>
        </AuthLayout>
    );
};

export default Login;
