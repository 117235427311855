import { useCallback } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../store";

const useCountries = () => {
    const { list: countries } = useSelector(
        (state: RootState) => state.countries
    );

    return {
        countries,
        getCountryByIndicative: useCallback(
            (indicative: string) => {
                return countries.find(item => item.phone_code === indicative);
            },
            [countries]
        ),
        getCountryByCodeCountry: useCallback(
            (codeCountry: string) => {
                return countries.find(item => item.phone_code === codeCountry);
            },
            [countries]
        ),
        getCountryByCountryId: useCallback(
            (countryId: number) => {
                return countries.find(item => item.id === countryId);
            },
            [countries]
        )
    };
};

export default useCountries;
