import { useModal } from "@ui/modal";
import { useCallback, useMemo } from "react";

import config from "../config";
import { auth } from "../helpers";

import useInactivity from "./useInactivity.ts";
import useLogout from "./useLogout.ts";

const useAutomaticDisconnect = () => {
    const { isOpen, closeModal, openModal } = useModal();
    const { logout: logoutUser } = useLogout();

    const showModal = useCallback(() => {
        window.setTimeout(() => {
            openModal();
        }, 200);
    }, [openModal]);

    const timeout = useMemo(() => {
        const sessionExpire = parseInt(config.auth.inactivityTime);

        return sessionExpire * 60 * 1000;
    }, []);

    useInactivity(timeout, () => {
        if (auth()?.isLogin) {
            logoutUser(undefined, showModal);
        }
    });

    return {
        isOpen,
        closeModal
    };
};

export default useAutomaticDisconnect;
