// import FigCopyIcon from "@icon/FigCopyIcon.tsx";
import FigRefreshIcon from "@icon/FigRefreshIcon.tsx";
import Button from "@ui/Button.tsx";
import Tooltip from "@ui/Tooltip.tsx";
import { ReactNode, useCallback, useMemo, useState } from "react";

// import CopyClipboardButton from "../../components/CopyClipboardButton.tsx";
import HeaderContainer from "../../components/HeaderContainer.tsx";
import { AppLayout } from "../../components/layouts";
import PhoneInput from "../../components/PhoneInput.tsx";
import {
    DEFAULT_COUNTRY_INDICATIVE,
    ENDPOINTS,
    RESPONSE_STATUS
} from "../../constants";
import {
    formatStatusAccount,
    formatStatusKyc,
    updateAxiosBaseUrl
} from "../../helpers";
import useCountries from "../../hooks/useCountries.tsx";
import { cn } from "../../libs/style.ts";
import useToast from "../../libs/useToast.tsx";
import {
    AccountStatusType,
    AccountType,
    ApiService,
    KycStatusType
} from "../../types";

const Title = ({ children }: { children: ReactNode }) => {
    return <h4 className="font-medium text-gray-400 mb-3">{children}</h4>;
};

// const ACCOUNT_ALREADY_VALIDATED = "account_already_validated";

interface KycStatusBadgeProps {
    children: ReactNode;
    status: string;
}

const KycStatusBadge = ({ children, status }: KycStatusBadgeProps) => {
    return (
        <p
            className={cn({
                "text-sm font-semibold": true,
                "text-orange-600": status === KycStatusType.to_review,
                "text-green-600": status === KycStatusType.validated,
                "text-gray-800": status === KycStatusType.to_validate,
                "text-red-600": status === KycStatusType.rejected
            })}
        >
            {children}
        </p>
    );
};

interface RelaunchButtonProps {
    handleRelaunch: () => void;
    disabled: boolean;
    loading: boolean;
}

const RelaunchButton = (props: RelaunchButtonProps) => {
    const { handleRelaunch, disabled, loading } = props;

    return (
        <Tooltip content="Relancer risk">
            <button
                onClick={handleRelaunch}
                disabled={disabled}
                className={cn({
                    "cursor-not-allowed": loading || disabled
                })}
            >
                <FigRefreshIcon className="text-gray-600" />
            </button>
        </Tooltip>
    );
};

const AccountSearch = () => {
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    // const [codeGenerating, setCodeGenerating] = useState(false);
    const [relaunching, setRelaunching] = useState(false);
    const [account, setAccount] = useState<AccountType | null>(null);
    const [indicative, setIndicative] = useState(DEFAULT_COUNTRY_INDICATIVE);
    const [searchEnd, setSearchEnd] = useState(false);

    const { getCountryByIndicative } = useCountries();

    const {
        customSuccess: customSuccessToast,
        customError: customErrorToast,
        loading: loadingToast
    } = useToast();

    const fetchAccountByPhoneNumber = useCallback(() => {
        setLoading(true);
        updateAxiosBaseUrl(ApiService.moc);
        window.axios
            .get(
                ENDPOINTS.SEARCH_USER_ACCOUNT.replace(
                    ":tel",
                    `${indicative}${search}`
                )
            )
            .then(response => {
                const data = response.data.data;
                setAccount(
                    Array.isArray(data) && data.length === 0 ? null : data
                );
            })
            .finally(() => {
                setLoading(false);
                setSearchEnd(true);
            });
    }, [indicative, search]);

    const handleSubmit = useCallback(() => {
        if (search.length > 0) {
            fetchAccountByPhoneNumber();
        }
    }, [fetchAccountByPhoneNumber, search.length]);

    const handleRelaunch = useCallback(
        (kycType: string) => {
            const toastId = loadingToast("Chargement...");
            setRelaunching(true);

            window.axios
                .get(
                    ENDPOINTS.SME_NOTIFY_RISK.replace(
                        ":tel",
                        `${indicative}${search}`
                    ).replace(":kycType", `${kycType}`)
                )
                .then(() => {
                    customSuccessToast(
                        {
                            message:
                                "Notification envoyée au risque avec succès"
                        },
                        { id: toastId }
                    );
                    fetchAccountByPhoneNumber();
                })
                .catch(error => {
                    const status = error.response?.status;
                    const message = error.response?.data?.message || "";

                    if (status === RESPONSE_STATUS.CONFLICT) {
                        customErrorToast(
                            { message },
                            { id: toastId, duration: 10000 }
                        );
                    } else {
                        customErrorToast(
                            { message: "Echec de notification des risque" },
                            { id: toastId }
                        );
                    }
                })
                .finally(() => setRelaunching(false));
        },
        [
            customErrorToast,
            customSuccessToast,
            fetchAccountByPhoneNumber,
            indicative,
            loadingToast,
            search
        ]
    );

    const country = useMemo(() => {
        const phoneIndicative =
            account?.user?.phone_with_indicative?.split(" ")[0] || "";

        return getCountryByIndicative(phoneIndicative) || null;
    }, [account?.user?.phone_with_indicative, getCountryByIndicative]);

    const comments = useMemo(() => {
        return (account?.comments ? JSON.parse(account?.comments) : {}) as {
            [key: string]: { value: string; comment: string };
        };
    }, [account?.comments]);

    const showRelaunchButton = useMemo(() => {
        return {
            transfer:
                account?.transfer_kyc_status === KycStatusType.to_validate ||
                account?.transfer_kyc_status === KycStatusType.to_review,
            card:
                account?.card_kyc_status === KycStatusType.to_validate ||
                account?.card_kyc_status === KycStatusType.to_review
        };
    }, [account?.card_kyc_status, account?.transfer_kyc_status]);

    const kycValidated = useMemo(
        () => account?.transfer_kyc_status === KycStatusType.validated,
        [account?.transfer_kyc_status]
    );

    const limitTransfer = useMemo(
        () => account?.user?.user_limit_transactionnel,
        [account?.user?.user_limit_transactionnel]
    );

    const rejectedReason = useMemo(() => {
        if (account?.transfer_kyc_rejected_reason)
            return account?.transfer_kyc_rejected_reason;

        if (account?.card_kyc_rejected_reason)
            return account?.card_kyc_rejected_reason;

        return "---";
    }, [
        account?.card_kyc_rejected_reason,
        account?.transfer_kyc_rejected_reason
    ]);

    return (
        <AppLayout className="space-y-5">
            <HeaderContainer>
                <h4 className="mb-4 font-medium">Filtres</h4>

                <div className="flex items-start space-x-4">
                    <PhoneInput
                        className="w-5/6"
                        indicative={indicative}
                        value={search}
                        onChange={e => {
                            searchEnd && setSearchEnd(false);
                            setSearch(e.target.value);
                        }}
                        onIndicativeChange={value => setIndicative(value)}
                    />

                    <Button
                        loading={loading}
                        onClick={handleSubmit}
                        withAuto
                        className="mt-2 flex-1"
                        disabled={search.length === 0}
                    >
                        Rechercher
                    </Button>
                </div>
            </HeaderContainer>

            <div
                className={cn({
                    "bg-white min-h-[450px] border rounded-md px-4 py-5": true,
                    "flex items-center justify-center": !account
                })}
            >
                {account ? (
                    <>
                        <h4 className="text-gray-800 font-medium text-lg mb-8">
                            Informations de l{"'"}utilisateur
                        </h4>

                        <div className="flex items-start justify-between">
                            <div className="space-y-6">
                                <div>
                                    <Title>Nom du user</Title>
                                    <p className="text-sm">
                                        {account?.user?.full_name ?? ""}
                                    </p>
                                </div>

                                <div>
                                    <Title>Numéro de téléphone</Title>
                                    <p className="text-sm">
                                        {account?.user?.phone_with_indicative}
                                    </p>
                                </div>
                            </div>

                            <div className="space-y-6">
                                {/*<div>
                                    <Title>Dernier code otp envoyé</Title>

                                    <div className="flex items-center justify-between">
                                        <p className="text-sm font-semibold text-yellow-600">
                                            {account?.user?.temporary_code ||
                                                "---"}
                                        </p>

                                        <div className="flex items-center space-x-6">
                                            {!account?.user?.validated_at && (
                                                <button
                                                    onClick={handleRefreshCode}
                                                    disabled={
                                                        codeGenerating ||
                                                        search.length === 0
                                                    }
                                                    className={cn({
                                                        "cursor-not-allowed":
                                                            codeGenerating
                                                    })}
                                                >
                                                    <FigRefreshIcon className="text-gray-600" />
                                                </button>
                                            )}

                                            {account?.user?.temporary_code && (
                                                <CopyClipboardButton
                                                    text={
                                                        account?.user
                                                            ?.temporary_code
                                                    }
                                                    showText={false}
                                                    copyIcon={
                                                        <FigCopyIcon className="text-gray-600" />
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>*/}

                                <div>
                                    <Title>Pays</Title>
                                    <p className="text-sm">
                                        {country?.name || "---"}
                                    </p>
                                </div>
                            </div>

                            <div className="space-y-6">
                                <div>
                                    <Title>Statut kyc carte</Title>

                                    <div className="flex items-center justify-between">
                                        <KycStatusBadge
                                            status={
                                                account?.card_kyc_status || ""
                                            }
                                        >
                                            {formatStatusKyc(
                                                account?.card_kyc_status || ""
                                            )}
                                        </KycStatusBadge>

                                        {showRelaunchButton.card &&
                                            account?.can_send_card_kyc_relaunch && (
                                                <RelaunchButton
                                                    handleRelaunch={() =>
                                                        handleRelaunch("card")
                                                    }
                                                    disabled={
                                                        relaunching ||
                                                        search.length === 0 ||
                                                        loading
                                                    }
                                                    loading={relaunching}
                                                />
                                            )}
                                    </div>
                                </div>

                                <div>
                                    <Title>Statut kyc transfert</Title>

                                    <div className="flex items-center justify-between">
                                        <KycStatusBadge
                                            status={
                                                account?.transfer_kyc_status ||
                                                ""
                                            }
                                        >
                                            {formatStatusKyc(
                                                account?.transfer_kyc_status ||
                                                    ""
                                            )}
                                        </KycStatusBadge>

                                        {showRelaunchButton.transfer &&
                                            account?.can_send_transfer_kyc_relaunch && (
                                                <RelaunchButton
                                                    handleRelaunch={() =>
                                                        handleRelaunch(
                                                            "transfer"
                                                        )
                                                    }
                                                    disabled={
                                                        relaunching ||
                                                        search.length === 0 ||
                                                        loading
                                                    }
                                                    loading={relaunching}
                                                />
                                            )}
                                    </div>
                                </div>
                            </div>

                            <div className="space-y-6">
                                <div>
                                    <Title>Statut compte</Title>

                                    <p
                                        className={cn({
                                            "text-sm font-semibold": true,
                                            "text-green-600":
                                                account?.status ===
                                                AccountStatusType.activated,
                                            "text-red-600": [
                                                AccountStatusType.deleted,
                                                AccountStatusType.suspended
                                            ].includes(
                                                account?.status as AccountStatusType
                                            )
                                        })}
                                    >
                                        {formatStatusAccount(account?.status)}
                                    </p>
                                </div>

                                <div>
                                    <Title>Statut du plafond du compte</Title>

                                    <p
                                        className={cn({
                                            "text-sm font-semibold": true,
                                            "text-red-600": limitTransfer,
                                            "text-green-600":
                                                !limitTransfer ||
                                                (limitTransfer && kycValidated)
                                        })}
                                    >
                                        {account?.user
                                            ?.user_limit_transactionnel ? (
                                            <>
                                                {account?.transfer_kyc_status ===
                                                KycStatusType.validated
                                                    ? "Déplafonné"
                                                    : "Atteint"}
                                            </>
                                        ) : (
                                            "Non atteint"
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <hr className="my-12" />

                        <div className="grid grid-cols-3 gap-4">
                            <div>
                                <Title>Commentaire Nom</Title>

                                <p className="text-sm">
                                    {comments?.full_name?.comment || "---"}
                                </p>
                            </div>

                            <div>
                                <Title>
                                    Commentaire photo pièce d{"'"}identité
                                </Title>

                                <p className="text-sm">
                                    {comments?.photo?.comment || "---"}
                                </p>
                            </div>

                            <div>
                                <Title>Commentaire selfie</Title>

                                <p className="text-sm">
                                    {comments?.selfie_photo?.comment || "---"}
                                </p>
                            </div>
                        </div>

                        <div className="grid grid-cols-3 gap-4">
                            <div>
                                <Title>Motif de suspension</Title>

                                <p className="text-sm">
                                    {account.suspension_reason || "---"}
                                </p>
                            </div>

                            <div>
                                <Title>Motif de rejet du KYC</Title>

                                <p className="text-sm">
                                    {rejectedReason || "---"}
                                </p>
                            </div>

                            <div>
                                <Title>Motif de la suppression du compte</Title>

                                <p className="text-sm">
                                    {account.user?.deleted_reason || "---"}
                                </p>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="flex items-center flex-col space-y-4">
                        <img
                            src="/images/empty-search.png"
                            alt=""
                            className="w-[208px] object-cover object-center"
                        />

                        <p
                            className={cn({
                                "font-medium text-gray-400 text-lg": true,
                                "text-red-600": search.length > 0 && searchEnd
                            })}
                        >
                            {search.length > 0 && searchEnd
                                ? "Le numéro recherché ne correspond à aucun compte. Merci de vérifier le numéro ou d'en essayer un autre."
                                : "Aucune donnée pour l’instant"}
                        </p>
                    </div>
                )}
            </div>
        </AppLayout>
    );
};

export default AccountSearch;
