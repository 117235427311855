import { ReactNode } from "react";

import { cn } from "../libs/style.ts";

interface Props {
    label: string;
    value: ReactNode;
    className?: string;
}

const ReceiptRow = (props: Props) => {
    const { label, value, className } = props;

    return (
        <div className={cn("flex items-center justify-between", className)}>
            <div>{label}</div>
            <div>{value}</div>
        </div>
    );
};

export default ReceiptRow;
