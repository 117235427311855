import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ENDPOINTS, PAGES } from "../../constants";
import { updateAxiosBaseUrl } from "../../helpers";
import { readNotification } from "../../store/notificationSlice.ts";
import { ApiService } from "../../types";

import { NotificationType } from "./types.ts";

const RISK_NOTIFICATION = "RiskNotification";
const DETAIL_ACTION = "details";
const SEPARATOR = "\\";

const useNotificationAction = (
    notification: NotificationType,
    onSuccess?: () => void
) => {
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getKyc = useCallback(() => {
        if (notification.read_at === null)
            dispatch(readNotification(notification.id));
        navigate(
            PAGES.RISK_DETAILS.replace(":id", notification.data.id.toString())
        );
    }, [
        dispatch,
        navigate,
        notification.data.id,
        notification.id,
        notification.read_at
    ]);

    const markNotificationRead = useCallback(() => {
        if (notification.read_at === null) {
            setLoading(true);
            updateAxiosBaseUrl(ApiService.moc);
            window.axios
                .put(
                    `${ENDPOINTS.READ_NOTIFICATION.replace(
                        ":id",
                        notification.id
                    )}`
                )
                .then(() => {
                    getKyc();
                    onSuccess && onSuccess();
                })
                .finally(() => setLoading(false));
        } else {
            getKyc();
        }
    }, [getKyc, notification.id, notification.read_at, onSuccess]);

    return {
        loading,
        action: useCallback(() => {
            switch (notification.type.split(SEPARATOR).pop()) {
                case RISK_NOTIFICATION:
                    if (notification.data.action === DETAIL_ACTION) {
                        markNotificationRead();
                    }
                    break;
                default:
                    break;
            }
        }, [markNotificationRead, notification.type, notification.data.action])
    };
};

export default useNotificationAction;
