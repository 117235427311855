import Button from "@ui/Button.tsx";
import Input from "@ui/Input.tsx";
import { Dispatch, SetStateAction } from "react";

import { currentDateForInput } from "../helpers";

import HeaderContainer from "./HeaderContainer.tsx";

interface Props {
    date: string;
    errors: { [key: string]: string };
    loading?: boolean;
    onSubmit: () => void;
    setDate: Dispatch<SetStateAction<string>>;
}

const HeaderAccountFile = (props: Props) => {
    const { date, setDate, onSubmit, errors, loading } = props;
    return (
        <HeaderContainer>
            <h4 className="mb-4 font-medium">Régénérer un fichier supprimer</h4>

            <div className="flex items-start space-x-4">
                <Input
                    type="date"
                    className="w-2/6"
                    value={date}
                    error={errors?.date}
                    onChange={e => setDate(e.target.value)}
                    max={currentDateForInput()}
                />

                <Button
                    loading={loading}
                    onClick={onSubmit}
                    withAuto
                    className="mt-2"
                >
                    Générer
                </Button>
            </div>
        </HeaderContainer>
    );
};

export default HeaderAccountFile;
