import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { NotificationMessageType } from "./types.ts";

const USER_CORRECT_KYC = "correct_kyc";
const VALIDATE_KYC = "validation_kyc";

const useNotificationMessage = (data: NotificationMessageType) => {
    const { t } = useTranslation();

    return useMemo(() => {
        const keysMatch = data.key_correspondance;
        switch (data.text) {
            case USER_CORRECT_KYC:
                return t(
                    "Corrections apportées suite au “A revoir” par l’utilisateur: :account_number, :full_name",
                    keysMatch
                );
            case VALIDATE_KYC:
                return t(
                    "Alerte KYC à valider: :account_number - :full_name - :phone",
                    keysMatch
                );
            default:
                return data.text;
        }
    }, [data, t]);
};

export default useNotificationMessage;
