import i18n from "i18next";
import * as yup from "yup";

import { FILE_EXTENSION, REGEX } from "../constants";

interface ParamsType {
    path?: string;
    min?: number;
    max?: number;
}

const stringEmailMessage = ({ path }: ParamsType) =>
    i18n.t("validation.string.email", { path });

const requiredMessage = ({ path }: ParamsType) =>
    i18n.t("validation.mixed.required", { path });

const stringMinMessage = ({ min, path }: ParamsType) =>
    i18n.t("validation.string.min", { min, path });

const stringMaxMessage = ({ max, path }: ParamsType) =>
    i18n.t("validation.string.max", { max, path });

/*const numberMinMessage = ({ min, path }: ParamsType) =>
    i18n.t("validation.number.min", { min, path });*/

const amountNumberMinMessage = ({ min, path }: ParamsType) =>
    `${i18n.t("validation.number.min", { min, path })} FCFA`;

const passwordMessage = () =>
    i18n.t(
        "Le mot de passe doit contenir au moins une lettre minuscule, une lettre majuscule, un chiffre et un caractère spécial"
    );

const IDENTITY_CARD_SIZE = 2 * 1000000;

const bigFileIdentityCardMessage = i18n.t("File size must not exceed 2 MB");
const fileExtensionIdentityCardMessage = `${i18n.t(
    "Le fichier doit être une image ou pdf (extensions autorisées: jpg, jpeg, png, pdf)"
)}`;

const pin = yup
    .string()
    .required(requiredMessage)
    .min(4, stringMinMessage)
    .max(4, stringMaxMessage)
    .matches(REGEX.PIN, {
        message: () => `${i18n.t("Must be exactly 4 digits")}`
    });

const password = yup
    .string()
    .required(requiredMessage)
    .min(8, stringMinMessage)
    .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=]).*$/,
        passwordMessage
    );

const rules = {
    pin: pin,
    confirmPin: (key: string) =>
        pin.oneOf([yup.ref(key)], () => `${i18n.t("Secret codes must match")}`),
    phone: yup
        .string()
        .required(requiredMessage)
        .matches(REGEX.NUMBER, {
            message: () => i18n.t("Value is not numeric") + ""
        })
        .min(8, stringMinMessage)
        .max(15, stringMaxMessage),
    fullName: yup
        .string()
        .required(requiredMessage)
        .min(3, stringMinMessage)
        .max(80, stringMaxMessage),
    email: yup.string().required(requiredMessage).email(stringEmailMessage),
    password: password,
    confirmPassword: (key: string) =>
        password.oneOf(
            [yup.ref(key)],
            () => `${i18n.t("Les mots de passe doivent correspondre")}`
        ),
    amount: yup
        .number()
        .required(requiredMessage)
        .min(200, amountNumberMinMessage),
    idNumber: yup.number().required(requiredMessage),
    file: yup.mixed().required(requiredMessage),
    identityCard: yup
        .mixed()
        .required(requiredMessage)
        .test("is-valid-type", fileExtensionIdentityCardMessage, value => {
            if (value && value instanceof File) {
                const fileExtension = value.name.split(".").pop();

                return (
                    FILE_EXTENSION.IMAGE.includes(fileExtension || "") ||
                    FILE_EXTENSION.PDF === fileExtension
                );
            }
            return false;
        })
        .test("is-valid-size", bigFileIdentityCardMessage, value => {
            if (value && value instanceof File) {
                return value.size <= IDENTITY_CARD_SIZE;
            }
            return false;
        })
};

export default rules;
