import { Toaster } from "react-hot-toast";
import { Route, Routes, Navigate } from "react-router-dom";

import SessionExpireModal from "./components/SessionExpireModal.tsx";
import { PAGES, PERMISSIONS, RESPONSE_STATUS, ROLES } from "./constants";
import { auth, userHaveOneRoles, userHaveRoles } from "./helpers";
import useAutomaticDisconnect from "./hooks/useAutomaticDisconnect.ts";
import useFetchCountries from "./hooks/useFetchCountries.ts";
import useFetchNotifications from "./hooks/useFetchNotifications.ts";
import useFetchPaymentMethod from "./hooks/useFetchPaymentMethod.ts";
import useSetupAxiosInterceptors from "./hooks/useSetupAxiosInterceptors.ts";
import useSubscribeChannels from "./hooks/useSubscribeChannels.ts";
import { Auth, Guest } from "./middlewares";
import AccountSearch from "./pages/account-search";
import Accounts from "./pages/accounts";
import ForgotPassword from "./pages/auth/ForgotPassword.tsx";
import Login from "./pages/auth/Login.tsx";
import ResetPassword from "./pages/auth/ResetPassword.tsx";
import ErrorPage from "./pages/error";
import KycDetail from "./pages/kyc-detail";
import Notifications from "./pages/notifications";
import PicturePreview from "./pages/picture-preview";
import Risk from "./pages/risk";
import SearchTransfer from "./pages/search-transfer";
import TransferDetails from "./pages/transfer-details";
import TransferFiles from "./pages/transfer-files";
import Transfers from "./pages/transfers";
import Wallets from "./pages/wallets";

const App = () => {
    useSetupAxiosInterceptors();
    useFetchPaymentMethod();
    useFetchCountries();
    useFetchNotifications();
    const { isOpen, closeModal } = useAutomaticDisconnect();
    useSubscribeChannels();

    return (
        <>
            <Routes>
                {Object.values(RESPONSE_STATUS).map(item => (
                    <Route
                        key={item}
                        path={`/${item}`}
                        element={<ErrorPage code={item} />}
                    />
                ))}

                {/* Start Guest rout */}
                <Route
                    path={PAGES.LOGIN}
                    element={
                        <Guest>
                            <Login />
                        </Guest>
                    }
                />

                <Route
                    path={PAGES.FORGOT_PASSWORD}
                    element={
                        <Guest>
                            <ForgotPassword />
                        </Guest>
                    }
                />

                <Route
                    path={PAGES.RESET_PASSWORD}
                    element={
                        <Guest>
                            <ResetPassword />
                        </Guest>
                    }
                />
                {/* End Guest rout */}

                {/* Start Auth rout */}
                {!auth() && (
                    <Route
                        path={PAGES.HOME}
                        element={<Navigate to={PAGES.LOGIN} />}
                    />
                )}

                {userHaveRoles([ROLES.FIN_OPS]) && (
                    <Route
                        path={PAGES.HOME}
                        element={
                            <Auth permissions={[PERMISSIONS.LIST_TRANSFER]}>
                                <Transfers />
                            </Auth>
                        }
                    />
                )}

                {userHaveOneRoles([
                    ROLES.SME,
                    ROLES.SUPER_ADMIN,
                    ROLES.RISK
                ]) && (
                    <Route
                        path={PAGES.HOME}
                        element={
                            <Auth permissions={[PERMISSIONS.LIST_TRANSFER]}>
                                <SearchTransfer />
                            </Auth>
                        }
                    />
                )}

                {userHaveRoles([ROLES.RISK]) && (
                    <Route
                        path={PAGES.HOME}
                        element={
                            <Auth permissions={[PERMISSIONS.LIST_KYC]}>
                                <Risk />
                            </Auth>
                        }
                    />
                )}

                {userHaveRoles([ROLES.SUPER_ADMIN]) && (
                    <Route
                        path={PAGES.HOME}
                        element={
                            <Auth permissions={[PERMISSIONS.LIST_ACCOUNT_FILE]}>
                                <Accounts />
                            </Auth>
                        }
                    />
                )}

                <Route
                    path={PAGES.TRANSFERS}
                    element={
                        <Auth
                            permissions={[PERMISSIONS.LIST_TRANSFER]}
                            roles={[ROLES.FIN_OPS, ROLES.SUPER_ADMIN]}
                        >
                            <Transfers />
                        </Auth>
                    }
                />

                <Route
                    path={PAGES.TRANSFER_DETAILS}
                    element={
                        <Auth
                            permissions={[PERMISSIONS.LIST_TRANSFER]}
                            roles={[ROLES.FIN_OPS, ROLES.SUPER_ADMIN]}
                        >
                            <TransferDetails />
                        </Auth>
                    }
                />

                <Route
                    path={PAGES.TRANSFER_SEARCH}
                    element={
                        <Auth
                            permissions={[PERMISSIONS.LIST_TRANSFER]}
                            roles={[ROLES.SME, ROLES.SUPER_ADMIN, ROLES.RISK]}
                        >
                            <SearchTransfer />
                        </Auth>
                    }
                />

                <Route
                    path={PAGES.TRANSFERS_FILES}
                    element={
                        <Auth permissions={[PERMISSIONS.LIST_TRANSFER_FILE]}>
                            <TransferFiles />
                        </Auth>
                    }
                />

                <Route
                    path={PAGES.ACCOUNTS}
                    element={
                        <Auth permissions={[PERMISSIONS.LIST_ACCOUNT_FILE]}>
                            <Accounts />
                        </Auth>
                    }
                />

                <Route
                    path={PAGES.RISK}
                    element={
                        <Auth permissions={[PERMISSIONS.LIST_KYC]}>
                            <Risk />
                        </Auth>
                    }
                />

                <Route
                    path={PAGES.RISK_DETAILS}
                    element={
                        <Auth permissions={[PERMISSIONS.SHOW_KYC]}>
                            <KycDetail />
                        </Auth>
                    }
                />

                <Route
                    path={PAGES.ACCOUNT_PICTURE}
                    element={
                        <Auth permissions={[PERMISSIONS.SHOW_KYC]}>
                            <PicturePreview />
                        </Auth>
                    }
                />

                <Route
                    path={PAGES.NOTIFICATION}
                    element={
                        <Auth>
                            <Notifications />
                        </Auth>
                    }
                />

                <Route
                    path={PAGES.WALLETS}
                    element={
                        <Auth
                            permissions={[PERMISSIONS.ENABLE_DISABLE_CHANNELS]}
                        >
                            <Wallets />
                        </Auth>
                    }
                />

                <Route
                    path={PAGES.ACCOUNT_SEARCH}
                    element={
                        <Auth permissions={[PERMISSIONS.SEARCH_USER_ACCOUNT]}>
                            <AccountSearch />
                        </Auth>
                    }
                />
                {/* End Auth rout */}

                <Route path="*" element={<ErrorPage code={404} />} />
            </Routes>

            <Toaster />

            <SessionExpireModal isOpen={isOpen} closeModal={closeModal} />
        </>
    );
};

export default App;
