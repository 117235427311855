import Button from "@ui/Button.tsx";
import Input from "@ui/Input.tsx";
import { Dispatch, SetStateAction } from "react";

import { INPUT_DATE_FORMAT } from "../constants";
import { currentDateForInput } from "../helpers";
import { formatDate } from "../libs/date.ts";

import HeaderContainer from "./HeaderContainer.tsx";

export interface Period {
    start: string;
    end: string;
}

interface Props {
    date: Period;
    errors: { [key: string]: string };
    loading?: boolean;
    onSubmit: () => void;
    setDate: Dispatch<SetStateAction<Period>>;
}

const HeaderTransactionFile = (props: Props) => {
    const { date, setDate, onSubmit, errors, loading } = props;

    return (
        <HeaderContainer>
            <h4 className="mb-6 font-medium">Régénérer un fichier supprimer</h4>

            <div className="flex items-start space-x-4">
                <div className="w-2/6 relative">
                    <label
                        htmlFor="startDate"
                        className="absolute -top-3 text-xs"
                    >
                        Date de début
                    </label>

                    <Input
                        id="startDate"
                        type="date"
                        value={date.start}
                        error={errors?.start}
                        onChange={e =>
                            setDate({ ...date, start: e.target.value })
                        }
                        max={currentDateForInput()}
                    />
                </div>

                <div className="w-2/6 relative">
                    <label
                        htmlFor="startDate"
                        className="absolute -top-3 text-xs"
                    >
                        Date de fin
                    </label>

                    <Input
                        id="endDate"
                        type="date"
                        value={date.end}
                        error={errors?.end}
                        onChange={e =>
                            setDate({ ...date, end: e.target.value })
                        }
                        min={
                            date.start
                                ? formatDate(date.start, INPUT_DATE_FORMAT)
                                : undefined
                        }
                        max={currentDateForInput()}
                    />
                </div>

                <Button
                    loading={loading}
                    onClick={onSubmit}
                    withAuto
                    className="mt-2"
                >
                    Générer
                </Button>
            </div>
        </HeaderContainer>
    );
};

export default HeaderTransactionFile;
