import Input from "@ui/Input.tsx";
import Select, { SelectValueType } from "@ui/select";
import { useCallback, useMemo } from "react";

import HeaderContainer from "../../../components/HeaderContainer.tsx";
import {
    DATATABLE,
    INPUT_DATE_FORMAT,
    STATUS_ACCOUNT,
    STATUS_KYC
} from "../../../constants";
import { currentDateForInput } from "../../../helpers";
import useCountries from "../../../hooks/useCountries.tsx";
import { formatDate } from "../../../libs/date.ts";
import { FilterKycParamsType } from "../../../types";

interface Props {
    filterKycParams: FilterKycParamsType;
    updateKycFilter: (value: FilterKycParamsType) => void;
}

const CARD_FILTER = [
    {
        value: "card_kyc",
        label: "Carte"
    },
    {
        value: "transfer_kyc",
        label: "Sans carte"
    },
    {
        value: "transfer_and_card_kyc",
        label: "Tout"
    }
];

const CEILING = [
    {
        label: "Tout",
        value: "all"
    },
    {
        label: "Presque",
        value: "almost"
    },
    {
        label: "Atteint",
        value: "achieved"
    },
    {
        label: "Non Atteint",
        value: "not-achieved"
    },
    {
        label: "Déplafonné",
        value: "uncapped"
    }
];

const HeaderRisk = (props: Props) => {
    const { filterKycParams, updateKycFilter } = props;
    const { countries } = useCountries();

    const countriesOption = useMemo(() => {
        return countries.map(item => ({
            ...item,
            value: item.phone_code,
            label: item.name
        }));
    }, [countries]);

    const listStatusAccounts = useMemo(() => {
        return Object.entries(STATUS_ACCOUNT).map(([key, value]) => ({
            value: key,
            label: value
        }));
    }, []);

    const listStatusKyc = useMemo(() => {
        return Object.entries(STATUS_KYC).map(([key, value]) => ({
            value: key,
            label: value
        }));
    }, []);

    const handleFilterChange = useCallback(
        (
            value: SelectValueType | string,
            key: keyof typeof filterKycParams
        ) => {
            updateKycFilter({
                [key]: value,
                pageIndex: 0,
                pageSize: DATATABLE.PAGE_SIZE
            });
        },
        [updateKycFilter]
    );

    const handleSelectFilterChange = useCallback(
        (key: keyof typeof filterKycParams) => {
            return (value: SelectValueType) => {
                handleFilterChange(value, key);
            };
        },
        [handleFilterChange]
    );

    const handleDateChange = useCallback(
        (value: string, key: "start" | "end") => {
            const newDate = { ...filterKycParams.createdDate };

            if (newDate) {
                newDate[key] = value;

                updateKycFilter({
                    createdDate: {
                        start: newDate.start || "",
                        end: newDate.end || ""
                    },
                    pageIndex: 0,
                    pageSize: DATATABLE.PAGE_SIZE
                });
            }
        },
        [filterKycParams.createdDate, updateKycFilter]
    );

    const handleTransferValidateDateChange = useCallback(
        (value: string, key: "start" | "end") => {
            const newValidatedTransferDate = {
                ...filterKycParams.transferKycValidationDate
            };

            if (newValidatedTransferDate) {
                newValidatedTransferDate[key] = value;

                updateKycFilter({
                    transferKycValidationDate: {
                        start: newValidatedTransferDate.start || "",
                        end: newValidatedTransferDate.end || ""
                    },
                    pageIndex: 0,
                    pageSize: DATATABLE.PAGE_SIZE
                });
            }
        },
        [filterKycParams.transferKycValidationDate, updateKycFilter]
    );

    const handleValidateCardDateChange = useCallback(
        (value: string, key: "start" | "end") => {
            const newValidatedCardDate = {
                ...filterKycParams.cardKycValidationDate
            };

            if (newValidatedCardDate) {
                newValidatedCardDate[key] = value;

                updateKycFilter({
                    cardKycValidationDate: {
                        start: newValidatedCardDate.start || "",
                        end: newValidatedCardDate.end || ""
                    },
                    pageIndex: 0,
                    pageSize: DATATABLE.PAGE_SIZE
                });
            }
        },
        [filterKycParams.cardKycValidationDate, updateKycFilter]
    );

    const createdStart = useMemo(() => {
        if (!filterKycParams.createdDate) return undefined;

        if (!filterKycParams.createdDate.start) return undefined;

        return formatDate(filterKycParams.createdDate.start, INPUT_DATE_FORMAT);
    }, [filterKycParams.createdDate]);

    const validateTransferStart = useMemo(() => {
        if (!filterKycParams.transferKycValidationDate) return undefined;

        if (!filterKycParams.transferKycValidationDate.start) return undefined;

        return formatDate(
            filterKycParams.transferKycValidationDate.start,
            INPUT_DATE_FORMAT
        );
    }, [filterKycParams.transferKycValidationDate]);

    const validateCardStart = useMemo(() => {
        if (!filterKycParams.cardKycValidationDate) return undefined;

        if (!filterKycParams.cardKycValidationDate.start) return undefined;

        return formatDate(
            filterKycParams.cardKycValidationDate.start,
            INPUT_DATE_FORMAT
        );
    }, [filterKycParams.cardKycValidationDate]);

    return (
        <HeaderContainer>
            <h4 className="mb-4 font-medium">Filtres</h4>

            <div className="grid grid-cols-6 gap-5">
                <Select
                    isSearchable={false}
                    placeholder="Statut compte"
                    options={listStatusAccounts}
                    value={filterKycParams.statusAccount || null}
                    onChange={handleSelectFilterChange("statusAccount")}
                />

                <Select
                    isSearchable={false}
                    placeholder="Pays"
                    options={countriesOption}
                    value={filterKycParams.country || null}
                    onChange={handleSelectFilterChange("country")}
                />

                <Select
                    isSearchable={false}
                    placeholder="Carte,Sans carte, Tout"
                    options={CARD_FILTER}
                    value={filterKycParams.cardFilter || null}
                    onChange={handleSelectFilterChange("cardFilter")}
                />

                {/*<Select
                    isSearchable={false}
                    placeholder="Statut KYC"
                    options={listStatusKyc}
                    value={filterKycParams.statusKyc || null}
                    onChange={handleSelectFilterChange("statusKyc")}
                />*/}

                <Select
                    isSearchable={false}
                    placeholder="Statut KYC Transfert"
                    options={listStatusKyc}
                    value={filterKycParams.statusKycTransfer || null}
                    onChange={handleSelectFilterChange("statusKycTransfer")}
                />

                <Select
                    isSearchable={false}
                    placeholder="Statut KYC Carte"
                    options={listStatusKyc}
                    value={filterKycParams.statusKycCard || null}
                    onChange={handleSelectFilterChange("statusKycCard")}
                />

                <Select
                    isSearchable={false}
                    placeholder="Plafond"
                    options={CEILING}
                    value={filterKycParams.ceiling || null}
                    onChange={handleSelectFilterChange("ceiling")}
                />
            </div>

            <div className="grid grid-cols-3 gap-5 mt-7">
                <div className="relative">
                    <label
                        htmlFor="startDate"
                        className="absolute text-xs -mt-4"
                    >
                        Date de création(Début)
                    </label>

                    <Input
                        id="startDate"
                        type="date"
                        inputClassName="placeholder-gray-400 text-gray-600"
                        placeholder="Date de début"
                        value={filterKycParams.createdDate?.start || ""}
                        onChange={e =>
                            handleDateChange(e.target.value, "start")
                        }
                        max={currentDateForInput()}
                    />
                </div>

                <div className="relative">
                    <label htmlFor="EndDate" className="absolute text-xs -mt-4">
                        Date de création(Fin)
                    </label>

                    <Input
                        id="EndDate"
                        type="date"
                        inputClassName="placeholder-gray-400 text-gray-600"
                        placeholder="Date de fin"
                        value={filterKycParams.createdDate?.end || ""}
                        onChange={e => handleDateChange(e.target.value, "end")}
                        min={createdStart}
                        max={currentDateForInput()}
                    />
                </div>

                <div className="relative">
                    <label
                        htmlFor="validationKycDate"
                        className="absolute text-xs -mt-4"
                    >
                        Date validation KYC Transfert(Début)
                    </label>

                    <Input
                        id="validationTransferKyc"
                        type="date"
                        inputClassName="placeholder-gray-400 text-gray-600"
                        placeholder="Date de validation du KYC Transfert"
                        value={
                            filterKycParams.transferKycValidationDate?.start ||
                            ""
                        }
                        onChange={e => {
                            handleTransferValidateDateChange(
                                e.target.value,
                                "start"
                            );
                        }}
                        max={currentDateForInput()}
                    />
                </div>

                <div className="relative">
                    <label
                        htmlFor="validationKycDate"
                        className="absolute text-xs -mt-4"
                    >
                        Date validation KYC Transfert(Fin)
                    </label>

                    <Input
                        id="validationTransferKyc"
                        type="date"
                        inputClassName="placeholder-gray-400 text-gray-600"
                        placeholder="Date de validation du KYC Transfert"
                        value={
                            filterKycParams.transferKycValidationDate?.end || ""
                        }
                        onChange={e => {
                            handleTransferValidateDateChange(
                                e.target.value,
                                "end"
                            );
                        }}
                        min={validateTransferStart}
                        max={currentDateForInput()}
                    />
                </div>

                <div className="relative">
                    <label
                        htmlFor="validationKycDate"
                        className="absolute text-xs -mt-4"
                    >
                        Date validation KYC Carte(Début)
                    </label>
                    <Input
                        id="validationTransferKyc"
                        type="date"
                        inputClassName="placeholder-gray-400 text-gray-600"
                        placeholder="Date validation KYC Carte"
                        value={
                            filterKycParams.cardKycValidationDate?.start || ""
                        }
                        onChange={e => {
                            handleValidateCardDateChange(
                                e.target.value,
                                "start"
                            );
                        }}
                        max={currentDateForInput()}
                    />
                </div>

                <div className="relative">
                    <label
                        htmlFor="validationKycDate"
                        className="absolute text-xs -mt-4"
                    >
                        Date validation KYC Carte(Fin)
                    </label>
                    <Input
                        id="validationTransferKyc"
                        type="date"
                        inputClassName="placeholder-gray-400 text-gray-600"
                        placeholder="Date validation KYC Carte"
                        value={filterKycParams.cardKycValidationDate?.end || ""}
                        onChange={e => {
                            handleValidateCardDateChange(e.target.value, "end");
                        }}
                        min={validateCardStart}
                        max={currentDateForInput()}
                    />
                </div>
            </div>
        </HeaderContainer>
    );
};

export default HeaderRisk;
